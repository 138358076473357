import { IFetchSalepointInfoListData } from '../types';

export const salepointInfoListDataShort: IFetchSalepointInfoListData = {
  next_cursor: 2,
  salepoint_info_list: [
    {
      id: 96,
      address: 'Калининград ул. Литовский Вал, 45',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 2,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 0,
        time: '15 мин',
        timer_state: 'orange',
      },
      orders_amount_in_delivery: 24,
      delivery_queue_awaiting_time: {
        time: '15 мин',
        timer_state: 'orange',
      },
    },
    {
      id: 7,
      address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 47,
      assembled_orders: {
        amount: 1,
        time: '25 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '25 мин',
        timer_state: 'red',
      },
    },
    {
      id: 59,
      address: 'Черняховск ул. Спортивная 25',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_delivery: 0,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 24,
      address: 'Калининград ул. Советский проспект 234',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 30,
      address: 'Калининград ул. Ленинский проспект 2',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 3,
        time: '5 мин',
        timer_state: 'orange',
      },
      orders_amount_in_assembly: 2,
      assembled_orders: {
        amount: 0,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '8 мин',
        timer_state: 'red',
      },
    },
  ],
};

export const salepointInfoListDataLong: IFetchSalepointInfoListData = {
  next_cursor: 2,
  salepoint_info_list: [
    {
      id: 96,
      address: 'Калининград ул. Литовский Вал, 45',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 2,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 0,
        time: '15 мин',
        timer_state: 'orange',
      },
      orders_amount_in_delivery: 24,
      delivery_queue_awaiting_time: {
        time: '15 мин',
        timer_state: 'orange',
      },
    },
    {
      id: 7,
      address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 47,
      assembled_orders: {
        amount: 1,
        time: '25 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '25 мин',
        timer_state: 'red',
      },
    },
    {
      id: 59,
      address: 'Черняховск ул. Спортивная 25',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_delivery: 0,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 24,
      address: 'Калининград ул. Советский проспект 234',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 30,
      address: 'Калининград ул. Ленинский проспект 2',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 3,
        time: '5 мин',
        timer_state: 'orange',
      },
      orders_amount_in_assembly: 2,
      assembled_orders: {
        amount: 0,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '8 мин',
        timer_state: 'red',
      },
    },
    {
      id: 101,
      address: 'Калининград ул. Литовский Вал, 45',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 2,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 0,
        time: '15 мин',
        timer_state: 'orange',
      },
      orders_amount_in_delivery: 24,
      delivery_queue_awaiting_time: {
        time: '15 мин',
        timer_state: 'orange',
      },
    },
    {
      id: 105,
      address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 47,
      assembled_orders: {
        amount: 1,
        time: '25 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '25 мин',
        timer_state: 'red',
      },
    },
    {
      id: 103,
      address: 'Черняховск ул. Спортивная 25',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_delivery: 0,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 107,
      address: 'Калининград ул. Советский проспект 234',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 110,
      address: 'Калининград ул. Ленинский проспект 2',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 3,
        time: '5 мин',
        timer_state: 'orange',
      },
      orders_amount_in_assembly: 2,
      assembled_orders: {
        amount: 0,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '8 мин',
        timer_state: 'red',
      },
    },
    {
      id: 112,
      address: 'Калининград ул. Литовский Вал, 45',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 2,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 0,
        time: '15 мин',
        timer_state: 'orange',
      },
      orders_amount_in_delivery: 24,
      delivery_queue_awaiting_time: {
        time: '15 мин',
        timer_state: 'orange',
      },
    },
    {
      id: 115,
      address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 47,
      assembled_orders: {
        amount: 1,
        time: '25 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '25 мин',
        timer_state: 'red',
      },
    },
    {
      id: 114,
      address: 'Черняховск ул. Спортивная 25',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_delivery: 0,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 116,
      address: 'Калининград ул. Советский проспект 234',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 117,
      address: 'Калининград ул. Ленинский проспект 2',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 3,
        time: '5 мин',
        timer_state: 'orange',
      },
      orders_amount_in_assembly: 2,
      assembled_orders: {
        amount: 0,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '8 мин',
        timer_state: 'red',
      },
    },
    {
      id: 121,
      address: 'Калининград ул. Литовский Вал, 45',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 2,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 0,
        time: '15 мин',
        timer_state: 'orange',
      },
      orders_amount_in_delivery: 24,
      delivery_queue_awaiting_time: {
        time: '15 мин',
        timer_state: 'orange',
      },
    },
    {
      id: 124,
      address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 47,
      assembled_orders: {
        amount: 1,
        time: '25 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '25 мин',
        timer_state: 'red',
      },
    },
    {
      id: 122,
      address: 'Черняховск ул. Спортивная 25',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_delivery: 0,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 128,
      address: 'Калининград ул. Советский проспект 234',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 129,
      address: 'Калининград ул. Ленинский проспект 2',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 3,
        time: '5 мин',
        timer_state: 'orange',
      },
      orders_amount_in_assembly: 2,
      assembled_orders: {
        amount: 0,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '8 мин',
        timer_state: 'red',
      },
    },
    {
      id: 133,
      address: 'Калининград ул. Литовский Вал, 45',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 2,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 0,
        time: '15 мин',
        timer_state: 'orange',
      },
      orders_amount_in_delivery: 24,
      delivery_queue_awaiting_time: {
        time: '15 мин',
        timer_state: 'orange',
      },
    },
    {
      id: 132,
      address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 47,
      assembled_orders: {
        amount: 1,
        time: '25 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '25 мин',
        timer_state: 'red',
      },
    },
    {
      id: 136,
      address: 'Черняховск ул. Спортивная 25',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 0,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '0 мин',
        timer_state: 'neutral',
      },
      orders_amount_in_delivery: 0,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 137,
      address: 'Калининград ул. Советский проспект 234',
      order_acceptance_state: 'enabled',
      order_collectors: {
        amount: 1,
        shift_state: 'active',
      },
      preorders_amount: 1,
      awaiting_assembly_orders: {
        amount: 1,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_assembly: 0,
      assembled_orders: {
        amount: 1,
        time: '1 мин',
        timer_state: 'green',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '0 мин',
        timer_state: 'neutral',
      },
    },
    {
      id: 140,
      address: 'Калининград ул. Ленинский проспект 2',
      order_acceptance_state: 'disabled',
      order_collectors: {
        amount: 0,
        shift_state: 'inactive',
      },
      preorders_amount: 0,
      awaiting_assembly_orders: {
        amount: 3,
        time: '5 мин',
        timer_state: 'orange',
      },
      orders_amount_in_assembly: 2,
      assembled_orders: {
        amount: 0,
        time: '8 мин',
        timer_state: 'red',
      },
      orders_amount_in_delivery: 2,
      delivery_queue_awaiting_time: {
        time: '8 мин',
        timer_state: 'red',
      },
    },
  ],
};
