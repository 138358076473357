import { TTimerState } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';
import styled from 'styled-components';

export const Time = styled.div<{ $timerState: TTimerState }>`
  color: ${({ theme, $timerState }) => {
    const color =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.time.content[$timerState];

    return color;
  }};
  font-size: 16px;
  white-space: nowrap;
`;
