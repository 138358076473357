import styled from 'styled-components';

export const Title = styled.div<{ $color: string }>`
  text-wrap: wrap;
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  color: ${({ $color }) => $color};
  line-height: 1.3;
`;
