import { FormControl, FormLabel, TextField } from '@mui/material';
import { ChangeEvent, FC, FocusEvent } from 'react';

import { StyledTextField } from './elements';

interface ICustomTextareaProps {
  label?: string;
  ariaLabel: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (event?: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  rows: number;
  className?: string;
  sx?: Record<string, string | number>;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const CustomTextarea: FC<ICustomTextareaProps> = ({
  label,
  ariaLabel,
  placeholder = '',
  defaultValue,
  value = '',
  onChange,
  onBlur,
  rows = 3,
  className,
  sx,
  disabled,
  error,
  helperText,
}) => {
  return (
    <TextField
      className={className}
      label={label}
      aria-label={ariaLabel}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        rows,
        multiline: true,
        inputComponent: 'textarea',
      }}
      sx={{ ...sx }}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
};
