import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { TRootState } from '@/store';
import { EClientSettingsFieldKeys } from '../../types';

export const FilePath = () => {
  const clientSettings = useSelector((state: TRootState) => state.clientSettings.clientSettings);

  const filePathField = useMemo(() => {
    const filePathFieldFound = clientSettings?.fields.find((field) => {
      return field.key === EClientSettingsFieldKeys.filePath;
    });

    return filePathFieldFound;
  }, [clientSettings]);

  return (
    <TextFieldGroup marginBottom="10px" title={filePathField.name} disabled>
      {filePathField.fields.map((field, index) => {
        return (
          <TextField
            key={field.key}
            sx={{ width: '100%', mb: index !== filePathField.fields.length - 1 ? 2 : 0 }}
            label={field.name}
            type="text"
            size="small"
            inputProps={{ readOnly: true }}
            value={field.value}
            disabled
          />
        );
      })}
    </TextFieldGroup>
  );
};
