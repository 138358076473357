import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ISlide } from '@/features/stories/types';
import { Slide as SlideContainer } from './elements';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@/shared/lib/const';
import { TRootState } from '@/store';
import { isValidSlide } from '@/features/stories/utils';

export interface ISlideOwnProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isShowFormErrors: boolean;
}

export type TSlideProps = ISlideOwnProps & ISlide;

export const Slide: FC<TSlideProps> = ({
  external_slide_id,
  name,
  isNewSlide,
  onClick,
  isShowFormErrors,
}) => {
  const navigate = useNavigate();
  const { scenario, story: storyId } = useParams();
  const story = useSelector((state: TRootState) => state.stories.story);

  const isValidSlideConst = useMemo(() => {
    const slide = story.slides.find((currSlide) => {
      return currSlide.external_slide_id === external_slide_id;
    });

    if (slide) {
      return isValidSlide(slide, story.isNewStory);
    }
    return true;
  }, [story]);

  const handleSlideContainerClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick(event);
    navigate(
      ROUTES.slide
        .replace(':scenario', scenario)
        .replace(':story', storyId)
        .replace(':slide', isNewSlide ? 'new_slide' : external_slide_id),
      {
        state: { isShowStoryFormErrors: isShowFormErrors, from: location.pathname },
      }
    );
  };
  return (
    <SlideContainer
      onClick={handleSlideContainerClick}
      $isShowError={!isValidSlideConst && isShowFormErrors}
    >
      {isNewSlide ? 'Новый слайд' : name}
    </SlideContainer>
  );
};
