export const transformPromotionIdsBeforeMassUpdate = (promotionIds: number[]) => {
  const promotionIdItems = promotionIds.map((promotionId) => {
    return { id: promotionId };
  });

  return {
    data: {
      items: promotionIdItems,
    },
  };
};
