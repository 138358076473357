import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AddBannerWrapper, BannerPlusIcon } from './elements';
import { TRootState } from '@/store';
import { ERequestStatus } from '@/shared/lib/types';
import { ROUTES } from '@/shared/lib/const';

export const AddBanner: FC = () => {
  const { scenario } = useParams();
  const navigate = useNavigate();
  const saveBannerListStatus = useSelector(
    (state: TRootState) => state.bannerScenarios.saveBannerListStatus
  );
  const isBlockAddingBanner = useMemo(() => {
    return saveBannerListStatus === ERequestStatus.pending;
  }, [saveBannerListStatus]);

  const handleAddBannerClick = () => {
    navigate(ROUTES.banner.replace(':scenario', scenario).replace(':banner', 'new-banner'));
  };

  return (
    <AddBannerWrapper
      $isAddBannerDisabled={false}
      $isBlockAddingBanner={isBlockAddingBanner}
      onClick={handleAddBannerClick}
    >
      <BannerPlusIcon>add</BannerPlusIcon>
    </AddBannerWrapper>
  );
};
