import { styled } from 'styled-components';

export const PromotionCollectionItemImg = styled.div<{ $img: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 68px;
  min-width: 68px;
  background-image: ${({ $img }) => `url(${$img})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;
