import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';
import { ISaveClientSettingsReponse, IFetchClientSettingsReponse } from '../types';
import { TRootState } from '@/store';
import { transformClientSettingsBeforeSave } from '../utils';

export const fetchClientSettings = createAsyncThunk<
  IFetchClientSettingsReponse['data'],
  void,
  unknown
>('clientSettings/getClientSettings', async () => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchClientSettingsReponse>(
    QUERY_ENDPOINTS.clientSettings.replace('[userId]', userId)
  );
  return response.data.data;
});

export const saveClientSettings = createAsyncThunk<
  ISaveClientSettingsReponse['data'],
  void,
  unknown
>('clientSettings/saveClientSettings', async (_, { getState }) => {
  const state = getState() as TRootState;
  const clientSettings = state.clientSettings.clientSettings;
  const staticCodesItems = state.clientSettings.staticCodesItems;
  const version = state.clientSettings.clientSettings?.version || 0;
  const data = transformClientSettingsBeforeSave({
    clientSettings,
    staticCodesItems,
  });
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.post<ISaveClientSettingsReponse>(
    MUTATION_ENDPOINTS.saveClientSettings
      .replace('[userId]', userId)
      .replace('[version]', version.toString()),
    data
  );
  return response.data.data;
});
