export interface IScenario {
  external_scenario_id: string;
}

export interface IStorySequenceItem {
  isNewStory?: boolean;
  isSavedStory?: boolean;
  id?: number;
  name: string;
  is_active: boolean;
  is_required: boolean;
  external_story_id?: string;
}

export interface INewStory {
  id: number;
  storyItem: IStory;
}
export interface ISlide {
  isNewSlide?: boolean;
  id?: number;
  external_slide_id: string;
  name: string;
  number: number;
  version: number;
  story_id?: number;
  type: string;
  duration: number;
  slides: ISlidesParams;
}

export interface ISlidesParams {
  params: TSlideParam[];
}

export interface ISlideFooter {
  name: 'footer';
  type: 'button';
  value: string;
  on_click: {
    action_type: ESlideActionType;
    action_value: string;
  };
  text_color: string;
  background_color: string;
}

export interface ISlideBackground {
  name: 'background';
  type: 'image_url';
  value: string;
}

export interface ISlideDescription {
  name: 'description';
  type: ESlideTextType;
  value: string;
  text_color: string;
}

export interface ISlideTitle {
  name: 'title';
  type: ESlideTextType;
  value: string;
  text_color: string;
}

export interface ISlideProgress {
  name: 'progress';
  type: 'bar';
  progress_color: string;
  background_color: string;
}

export interface ISlideClose {
  name: 'close';
  type: 'cross_button';
  on_clink: {
    action_type: string;
  };
  background_color: string;
}

export type TSlideParam =
  | ISlideFooter
  | ISlideBackground
  | ISlideDescription
  | ISlideTitle
  | ISlideProgress
  | ISlideClose;

export type IViewDimensions = {
  width: number;
  height: number;
};

export interface ITooltip {
  imageUrl: string;
  textValue: string;
  textColor: string;
}

export enum ESlideTextType {
  header = 'header',
  text = 'text',
}

export enum ECarouselIndicators {
  dark = 'dark',
  light = 'light',
}

export enum ESlideActionType {
  deepLink = 'deep_link',
  externalLink = 'external_link',
  share = 'share',
  webview = 'webview',
}

export interface IScenariosReponse {
  data: {
    scenarios: string[];
  };
}

export interface ISaveStorySequenceResponse {
  data: {
    status: 'ok';
  };
}

export interface IUploadFileResponse {
  data: {
    url: string;
  };
}

export interface IFetchStorySequenceResponse {
  data: IStorySequenceData;
}

export interface IStorySequenceData {
  story_sequence: IStorySequenceItem[];
  version: number;
}

export interface IFetchStoryResponse {
  data: {
    story: IStory;
  };
}

export interface ISaveStoryResponse {
  data: {
    story: {
      id: number;
    };
  };
}
export interface IStory {
  isNewStory?: boolean;
  isSavedStory?: boolean;
  id?: number;
  external_story_id: string;
  name: string;
  version?: number;
  type: string;
  preview: IStoryPreview;
  slides: ISlide[];
}

export interface IStoryPreview {
  params: (IStoryPreviewParamsTitle | IStoryPreviewParamsBackground)[];
}
export interface IStoryPreviewParamsTitle {
  name: 'title';
  type: ESlideTextType;
  value: string;
  text_color: string;
}

export interface IStoryPreviewParamsBackground {
  name: 'background';
  type: 'image_url';
  value: string;
}

export interface ISaveStorySequenceData {
  data: {
    story_scenario: string;
    version?: number;
    story_sequence: {
      params: IStorySequenceItem[];
    };
  };
}

export enum EUploadFileType {
  image = 'image',
  video = 'video',
  file = 'file',
}
