import { styled } from '@mui/system';
import Button from '@mui/material/Button';

import { styledTheme } from '@/app/themes/styled-theme';

export const LoginBtn = styled(Button)(({ theme }) => ({
  display: 'block !important',
  margin: '0 auto !important',
  color: `${styledTheme.colors.login.button.text} !important`,
  borderColor: `${styledTheme.colors.login.button.border} !important`,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
