import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  fetchPromotionItem,
  fetchPromotionItems,
} from '@/features/promotions/redux/promotions.actions';
import { setPromotionAtivePage } from '@/features/promotions/redux/promotions.slice';
import { EPromotionTabPages } from '@/features/promotions/types';
import { TRootState, useAppDispatch } from '@/store';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Название', width: 300 },
  { field: 'type', headerName: 'Тип акции', width: 170 },
  {
    field: 'status',
    headerName: 'Статус',
    width: 170,
  },
];

interface IPromotionTableProps {
  search: string;
  onSelectedPromotionIdsChange?: (ids: number[]) => void;
}

export const PromotionTable: FC<IPromotionTableProps> = ({
  search = '',
  onSelectedPromotionIdsChange: onSelectedPromotionIdsChange,
}) => {
  const dispatch = useAppDispatch();
  const promotionItems = useSelector((state: TRootState) => state.promotions.promotionItems);
  const [selectedPromotionIds, setSelectedPromotionIds] = useState([]);

  const rows = useMemo(() => {
    const filteredPromotionItems = promotionItems?.filter((promotionItem) => {
      return promotionItem.name?.toLowerCase()?.includes(search.toLowerCase());
    });

    if (filteredPromotionItems) {
      return filteredPromotionItems.map((promotionItem) => {
        return {
          id: promotionItem.id,
          name: promotionItem.name,
          type: promotionItem.type?.name,
          status: promotionItem.status?.name,
        };
      });
    } else {
      return [];
    }
  }, [search, promotionItems]);

  const handleRowClick = (rowData: GridRowParams<any>) => {
    const promotionId = rowData.id as number;
    dispatch(fetchPromotionItem(promotionId));
    dispatch(setPromotionAtivePage(EPromotionTabPages.existingPromotion));
  };

  const handleRowSelectionModelChange = (selectedItemIdsProps: GridRowSelectionModel) => {
    setSelectedPromotionIds(selectedItemIdsProps);
    onSelectedPromotionIdsChange(selectedItemIdsProps as number[]);
  };

  useEffect(() => {
    dispatch(fetchPromotionItems());
  }, []);

  return (
    <div
      style={{
        minHeight: '160px',
        height: 'calc(100vh - 275px)',
        maxHeight: '360px',
        width: '100%',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        onRowClick={handleRowClick}
        checkboxSelection
        rowSelectionModel={selectedPromotionIds}
        onRowSelectionModelChange={handleRowSelectionModelChange}
      />
    </div>
  );
};
