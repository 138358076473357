import cloneDeep from 'lodash.clonedeep';

import { EClientSettingsFieldKeys, IClientSettings, INewStaticCodesItem } from '../types';

export interface ITransformClientSettingsBeforeSave {
  clientSettings: IClientSettings;
  staticCodesItems: INewStaticCodesItem[];
}

export const transformClientSettingsBeforeSave = ({
  clientSettings,
  staticCodesItems,
}: ITransformClientSettingsBeforeSave) => {
  let clientSettingsFieldsClone = cloneDeep(clientSettings?.fields);

  if (clientSettingsFieldsClone) {
    clientSettingsFieldsClone = clientSettingsFieldsClone.map((clientSettingsField) => {
      if (clientSettingsField.key === EClientSettingsFieldKeys.staticCodes) {
        delete clientSettingsField.name;
        clientSettingsField.value = {};
        for (let i = 0; i < staticCodesItems.length; i++) {
          const currKey = staticCodesItems[i].staticCodeName;
          const currValue = staticCodesItems[i].staticCodeValue;
          clientSettingsField.value[currKey] = currValue;
        }
      } else {
        delete clientSettingsField.name;
        if (clientSettingsField.fields) {
          clientSettingsField.fields.forEach((field) => {
            delete field.name;
          });
        }
      }
      return clientSettingsField;
    });
  } else if (staticCodesItems) {
    clientSettingsFieldsClone = [
      {
        key: 'static_codes',
        value: {},
      },
    ];

    for (let i = 0; i < staticCodesItems.length; i++) {
      const currKey = staticCodesItems[i].staticCodeName;
      const currValue = staticCodesItems[i].staticCodeValue;
      clientSettingsFieldsClone[0].value[currKey] = currValue;
    }
  }

  return {
    data: {
      fields: clientSettingsFieldsClone,
    },
  };
};
