import { FC } from 'react';

import { SearchInput, TTextFieldProps } from '@/shared/components/SearchInput';

export const SearchInputDwp: FC<TTextFieldProps> = ({ sx, ...props }) => {
  return (
    <SearchInput
      size="medium"
      {...props}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& .MuiSvgIcon-root': {
            color: 'rgba(0, 0, 0, 0.56)',
          },
          '& .material-symbols-rounded': {
            color: 'rgba(0, 0, 0, 0.56)',
          },
          '&.Mui-focused .material-symbols-rounded': {
            color: '#556cd6',
          },
          '& .MuiInputBase-input': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        ...sx,
      }}
    />
  );
};
