import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const TooltipTextField = styled(TextField)<{ $marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '10px' + ' !important'};

  &:last-child {
    margin-bottom: 0 !important;
  }
`;
