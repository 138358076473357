import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ERequestStatus, IClientListRecord } from '@/shared/lib/types';
import { checkIsAuth, login } from './authentication.actions';
import { ILogin404Error, ILoginResponse } from '../types';

type TConfigGroupsState = {
  loginStatus: ERequestStatus;
  clients: IClientListRecord[];
};

const initialState: TConfigGroupsState = {
  loginStatus: ERequestStatus.idle,
  clients: [],
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = ERequestStatus.pending;
      })
      .addCase(login.fulfilled, (state, action) => {
        const client = (action.payload as ILoginResponse).data.client;
        const clients = (action.payload as ILoginResponse).data.clients;
        if (client) {
          state.clients = [client];
        } else if (clients) {
          state.clients = clients;
        }
        state.loginStatus = ERequestStatus.success;
      })
      .addCase(login.rejected, (state, action) => {
        const error = action.payload as AxiosError<ILogin404Error>;
        if (error.response.status === 404) {
          state.clients = error.response.data.data.clients;
        }
        state.loginStatus = ERequestStatus.error;
      })
      .addCase(checkIsAuth.pending, (state) => {
        state.loginStatus = ERequestStatus.pending;
      })
      .addCase(checkIsAuth.fulfilled, (state) => {
        state.loginStatus = ERequestStatus.success;
      })
      .addCase(checkIsAuth.rejected, (state) => {
        state.loginStatus = ERequestStatus.error;
      });
  },
});

export const { setLoginStatus, setClients } = authenticationSlice.actions;

export default authenticationSlice.reducer;
