import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { TextField } from '@mui/material';

import { TRootState, useAppDispatch } from '@/store';
import { EClientSettingsFieldKeys } from '../../types';
import { setPurchaseHistoryFilterFieldValue } from '../../redux/clientSettings.slice';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';

export const PurchaseHistoryFilters = () => {
  const dispatch = useAppDispatch();
  const clientSettings = useSelector((state: TRootState) => state.clientSettings.clientSettings);

  const purchaseHistoryFilters = useMemo(() => {
    const purchaseHistoryFiltersFound = clientSettings?.fields.find((field) => {
      return field.key === EClientSettingsFieldKeys.purchaseHistoryFilters;
    });

    return purchaseHistoryFiltersFound;
  }, [clientSettings]);

  const handlePurchaseHistoryFilterFieldValueChange = ({
    event,
    key,
  }: {
    event: React.ChangeEvent<HTMLInputElement>;
    key: string;
  }) => {
    dispatch(setPurchaseHistoryFilterFieldValue({ value: event.target.value, key }));
  };

  return (
    <TextFieldGroup marginBottom="16px" title={purchaseHistoryFilters.name}>
      {purchaseHistoryFilters.fields.map((field, index) => {
        return (
          <TextField
            key={field.key}
            sx={{ width: '100%', mb: index !== purchaseHistoryFilters.fields.length - 1 ? 2 : 0 }}
            label={field.name}
            type="text"
            size="small"
            value={field.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handlePurchaseHistoryFilterFieldValueChange({ event, key: field.key })
            }
          />
        );
      })}
    </TextFieldGroup>
  );
};
