import { combineReducers } from '@reduxjs/toolkit';

import dispatchersWorkplaceSidebar from '../sidebar/redux/dispatchersWorkplaceSidebar.slice';
import salepointInfoListDashboard from '../salepoint-info-list-dashboard/redux/salepointInfoListDashboard.slice';

const reducers = combineReducers({
  sidebar: dispatchersWorkplaceSidebar,
  salepointInfoListDashboard: salepointInfoListDashboard,
});

export default reducers;
