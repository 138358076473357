import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/store';
import { Close, DimensionsContainer, DimensionsTextField } from './elements';
import { TRootState } from '@/store';
import { setStoryViewHeight, setStoryViewWidth } from '@/features/stories/redux/stories.slice';

export interface IStoryViewDimensionsProps {}

const SlideViewDimensions: FC<IStoryViewDimensionsProps> = () => {
  const storyViewDimensions = useSelector((state: TRootState) => state.stories.storyViewDimensions);
  const [width, setWidth] = useState(storyViewDimensions.width);
  const [height, setHeight] = useState(storyViewDimensions.height);

  const dispatch = useAppDispatch();

  const handleWidthInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextWidth = parseInt(event.target.value);
    if (!isNaN(nextWidth)) {
      setWidth(nextWidth);
    }
  };

  const handleWidthInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(setStoryViewWidth(width));
    }
  };

  const handleWidthInputBlur = () => {
    dispatch(setStoryViewWidth(width));
  };

  const handleHeightInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextHeight = parseInt(event.target.value);
    if (!isNaN(nextHeight)) {
      setHeight(nextHeight);
    }
  };

  const handleHeightInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(setStoryViewHeight(height));
    }
  };

  const handleHeightInputBlur = () => {
    dispatch(setStoryViewHeight(height));
  };

  return (
    <DimensionsContainer>
      <DimensionsTextField
        label="Ширина"
        type="number"
        size="small"
        value={width}
        onChange={handleWidthInputChange}
        onKeyUp={handleWidthInputKeyUp}
        onBlur={handleWidthInputBlur}
      />
      <Close>close</Close>
      <DimensionsTextField
        label="Высота"
        type="number"
        size="small"
        value={height}
        onChange={handleHeightInputChange}
        onKeyUp={handleHeightInputKeyUp}
        onBlur={handleHeightInputBlur}
      />
    </DimensionsContainer>
  );
};

export default SlideViewDimensions;
