import { IScenario } from '../types';

export const tranformFetchScenariosData = (scenarios: string[]): IScenario[] => {
  if (!scenarios) {
    return [];
  }

  return scenarios.map((scenario) => {
    return {
      external_scenario_id: scenario,
    };
  });
};
