import styled from 'styled-components';
import smartPlatformLogo from '@/assets/img/smart-platform-logo-white.png';

export const SmartPlatformLogo = styled.div`
  position: relative;
  display: block;
  width: 160px;
  height: 56px;
  background-image: url(${smartPlatformLogo});
  background-size: contain;
  background-repeat: no-repeat;
  user-select: none;
`;
