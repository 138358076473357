import Box from '@mui/material/Box';
import styled from 'styled-components';

export const LoaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
`;
