import styled from 'styled-components';

import { TTimerState } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';

export const TimeWrapper = styled.div<{ $timerState: TTimerState }>`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme, $timerState }) => {
    const color =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.time.background[$timerState];

    return color;
  }};
  border-radius: 8px;
  padding: 2px 8px 2px 8px;
`;
