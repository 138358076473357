import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div<{ $withGradient?: boolean }>`
  position: relative;
  height: 100vh;
  width: 260px;
  background-color: ${({ theme }) => theme.colors.background.main};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.borders.main};
    width: 1px;
    height: 100%;
    z-index: 4;
  }

  ${({ $withGradient }) =>
    $withGradient &&
    css`
      &::after {
        display: block;
        content: '';
        position: absolute;
        background: linear-gradient(180deg, #7fa5ff22 120px, transparent 302px);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }
    `}
`;
