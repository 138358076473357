import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CarouselIndicatorsContainer } from './elements';
import { TRootState } from '@/store';
import { useAppDispatch } from '@/store';
import { setActiveSlideIdInStoryPage } from '@/features/stories/redux/stories.slice';
import CarouselIndicator from './components/CarouselIndicator';
import { ISlide, ISlideProgress } from '@/features/stories/types';
import { useIntervalForSlideIndicators } from '@/features/stories/story/components/StoryView/components/CarouselIndicators/hooks/useIntervalForSlideIndicators';

export interface ICarouselIndicatorsProps {}

const CarouselIndicators: FC<ICarouselIndicatorsProps> = () => {
  const dispatch = useAppDispatch();
  const slides = useSelector((state: TRootState) => state.stories.story.slides);
  const [keyForSlideProgress, activeSlideIndex, startInterval, clearIntervalForSlides] =
    useIntervalForSlideIndicators();

  useEffect(() => {
    if (!!slides && slides.length > 0) {
      const durations = slides.map((slide: ISlide) => {
        return slide.duration;
      });
      startInterval({
        durations,
      });
    }
    return () => clearIntervalForSlides();
  }, [slides]);

  useEffect(() => {
    if (!!slides && slides.length > 0) {
      dispatch(setActiveSlideIdInStoryPage(slides[0]?.external_slide_id));
    }
  }, [slides]);

  useEffect(() => {
    if (!!slides && slides.length > 0) {
      dispatch(setActiveSlideIdInStoryPage(slides[activeSlideIndex]?.external_slide_id));
    }
  }, [slides, activeSlideIndex]);

  return (
    <CarouselIndicatorsContainer key={keyForSlideProgress}>
      {!!slides &&
        slides.map((slide, index) => {
          const progress = slide?.slides?.params?.find((param) => {
            return param.name === 'progress';
          });
          const progressColor = (progress as ISlideProgress)?.progress_color || '';
          const progressBackgroundColor = (progress as ISlideProgress)?.background_color || '';

          return (
            <CarouselIndicator
              key={slide.external_slide_id}
              progressColor={progressColor}
              progressBackgroundColor={progressBackgroundColor}
              isShown={index < activeSlideIndex}
              isActive={activeSlideIndex === index}
              slidesNumber={slides.length}
              duration={slide.duration}
            ></CarouselIndicator>
          );
        })}
    </CarouselIndicatorsContainer>
  );
};

export default CarouselIndicators;
