import styled, { css } from 'styled-components';

export const TableHeadCellText = styled.div<{ $isOneLineText: boolean }>`
  flex: 0 1;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => {
    const tableHeadingColor =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.table.heading.text;
    return tableHeadingColor;
  }};
  line-height: 14px;
  text-align: left;
  ${({ $isOneLineText }) =>
    $isOneLineText &&
    css`
      white-space: nowrap;
    `}
`;
