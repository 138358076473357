import styled from 'styled-components';

export const BackBtnIcon = styled.span.attrs(() => ({
  className: 'material-symbols-rounded',
}))`
  position: relative;
  left: -6px;
  cursor: pointer;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.text.main};
`;
