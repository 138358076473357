import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import isEmpty from 'lodash.isempty';

import { TRootState, useAppDispatch } from '@/store';
import { BannerContainer, BannerControlsContainer, BannerTextField, Header } from './elements';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ControlRow } from '@/shared/components/ControlRow';
import { ColorPickerWithTextField } from '@/shared/components/ColorPickerWithTextField';
import { MainButton } from '@/shared/components/MainButton';
import { EBannerActionType, EBannerFooterType } from '@/features/banners/types';
import {
  deleteNewBanner,
  setBannerActionType,
  setBannerActionValue,
  setBannerFooterBackgroundColor,
  setBannerFooterTextColor,
  setBannerFooterValue,
  setBannerNameValue,
  setBannerRightImageUrl,
  setBannerTextColor,
  setBannerTextValue,
  setBannerTitleTextColor,
  setBannerTitleTextValue,
  setNewBannerActionType,
  setNewBannerActionValue,
  setNewBannerFooterBackgroundColor,
  setNewBannerFooterTextColor,
  setNewBannerFooterValue,
  setNewBannerNameValue,
  setNewBannerRightImageUrl,
  setNewBannerTextColor,
  setNewBannerTextValue,
  setNewBannerTitleTextColor,
  setNewBannerTitleTextValue,
} from '@/features/banners/redux/scenarios.slice';
import { createBanner, saveBanner } from '@/features/banners/redux/scenarios.actions';
import { isBannerTouched, isValidBanner } from '@/features/banners/utils';
import { ERequestStatus } from '@/shared/lib/types';
import { ROUTES, actionButtonOptionTexts, actionButtonOptionValues } from '@/shared/lib/const';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import { CustomSelect } from '@/shared/components/CustomSelect';
import Yup from '@/shared/validations';

const validationSchema = Yup.object().shape({
  name: Yup.string().itemNameInputValidation(),
  titleTextValue: Yup.string().bannerTitleValueValidation(),
  titleTextColor: Yup.string().colorInputValidation(),
  textValue: Yup.string().bannerTextValueValidation(),
  textColor: Yup.string().colorInputValidation(),
  footerValue: Yup.string().itemButtonTextValueValidation(),
  footerTextColor: Yup.string().colorInputValidation(),
  footerBackgroundColor: Yup.string().colorInputValidation(),
  rightImageUrl: Yup.string().regularTextInputValidation(),
  actionType: Yup.string().regularSelectComponentValidation(),
  actionValue: Yup.string().externalLinkInputValidation(),
});

export const BannerContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const newBanner = useSelector((state: TRootState) => state.bannerScenarios.newBanner);
  const banner = useSelector((state: TRootState) => state.bannerScenarios.banner);
  const bannerInitial = useSelector((state: TRootState) => state.bannerScenarios.bannerInitial);
  const fetchBannerStatus = useSelector(
    (state: TRootState) => state.bannerScenarios.fetchBannerStatus
  );
  const saveBannerStatus = useSelector(
    (state: TRootState) => state.bannerScenarios.saveBannerStatus
  );
  const { service, module, scenario: scenarioId, banner: bannerId } = useParams();

  const formik = useFormik({
    initialValues: {
      name: '',
      titleTextValue: '',
      titleTextColor: '',
      textValue: '',
      textColor: '',
      footerValue: '',
      footerTextColor: '',
      footerBackgroundColor: '',
      rightImageUrl: '',
      actionValue: '',
      actionType: '',
    },
    onSubmit: (values, actions) => {},
    validationSchema,
  });

  useLayoutEffect(() => {
    formik.resetForm();
  }, [bannerId]);

  useEffect(() => {
    let bannerData;
    if (bannerId === 'new-banner') {
      bannerData = newBanner;
    } else if (banner) {
      bannerData = banner;
    }

    if (!isEmpty(bannerData)) {
      formik.setFieldValue('name', bannerData.name);
      formik.setFieldValue('titleTextValue', bannerData.title?.value);
      formik.setFieldValue('titleTextColor', bannerData.title?.text_color);
      formik.setFieldValue('textValue', bannerData.text?.value);
      formik.setFieldValue('textColor', bannerData.text?.text_color);
      formik.setFieldValue('footerValue', bannerData.footer?.value);
      formik.setFieldValue('footerTextColor', bannerData.footer?.text_color);
      formik.setFieldValue('footerBackgroundColor', bannerData.footer?.background_color);
      formik.setFieldValue('rightImageUrl', bannerData.right_image_url);
      formik.setFieldValue('actionType', bannerData.deep_link?.type);
      formik.setFieldValue('actionValue', bannerData.deep_link?.value);
    }
  }, [banner, newBanner, bannerId]);

  const isSaveBtnDisabled = useMemo(() => {
    return (
      fetchBannerStatus === ERequestStatus.pending || saveBannerStatus === ERequestStatus.pending
    );
  }, [fetchBannerStatus, saveBannerStatus]);

  const handleSaveBtnClick = () => {
    formik.setTouched({
      name: true,
      titleTextValue: true,
      titleTextColor: true,
      textValue: true,
      textColor: true,
      footerValue: true,
      footerTextColor: true,
      footerBackgroundColor: true,
      rightImageUrl: true,
      actionType: true,
      actionValue: true,
    });
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        if (bannerId === 'new-banner') {
          dispatch(createBanner(scenarioId))
            .unwrap()
            .then((data) => {
              dispatch(deleteNewBanner());
              enqueueSnackbar(<div>Баннер был успешно сохранен!</div>, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              });
              navigate(
                ROUTES.banner
                  .replace(':service', service)
                  .replace(':module', module)
                  .replace(':scenario', scenarioId)
                  .replace(':banner', data.id.toString())
              );
            });
        } else {
          dispatch(saveBanner({ scenarioId, bannerId }))
            .unwrap()
            .then(() => {
              enqueueSnackbar(<div>Баннер был успешно сохранен!</div>, {
                variant: 'success',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              });
            });
        }
      }
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerNameValue(event.target.value));
    } else {
      dispatch(setBannerNameValue(event.target.value));
    }
    formik.setFieldValue('name', event.target.value);
  };

  const handleTitleTextValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerTitleTextValue(event.target.value));
    } else {
      dispatch(setBannerTitleTextValue(event.target.value));
    }
    formik.setFieldValue('titleTextValue', event.target.value);
  };

  const handleTitleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerTitleTextColor(event.target.value));
    } else {
      dispatch(setBannerTitleTextColor(event.target.value));
    }
    formik.setFieldValue('titleTextColor', event.target.value);
  };
  const handleTitleTextColorFromColorPickerChange = (color: string) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerTitleTextColor(color));
    } else {
      dispatch(setBannerTitleTextColor(color));
    }
    formik.setFieldValue('titleTextColor', color);
  };

  const handleTextValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerTextValue(event.target.value));
    } else {
      dispatch(setBannerTextValue(event.target.value));
    }
    formik.setFieldValue('textValue', event.target.value);
  };

  const handleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerTextColor(event.target.value));
    } else {
      dispatch(setBannerTextColor(event.target.value));
    }
    formik.setFieldValue('textColor', event.target.value);
  };
  const handleTextColorFromColorPickerChange = (color: string) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerTextColor(color));
    } else {
      dispatch(setBannerTextColor(color));
    }
    formik.setFieldValue('textColor', color);
  };

  const handleFooterValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerFooterValue(event.target.value));
    } else {
      dispatch(setBannerFooterValue(event.target.value));
    }
    formik.setFieldValue('footerValue', event.target.value);
  };

  const handleFooterTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerFooterTextColor(event.target.value));
    } else {
      dispatch(setBannerFooterTextColor(event.target.value));
    }
    formik.setFieldValue('footerTextColor', event.target.value);
  };
  const handleFooterTextColorFromColorPickerChange = (color: string) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerFooterTextColor(color));
    } else {
      dispatch(setBannerFooterTextColor(color));
    }
    formik.setFieldValue('footerTextColor', color);
  };

  const handleFooterBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerFooterBackgroundColor(event.target.value));
    } else {
      dispatch(setBannerFooterBackgroundColor(event.target.value));
    }
    formik.setFieldValue('footerBackgroundColor', event.target.value);
  };
  const handleFooterBackgroundColorFromColorPickerChange = (color: string) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerFooterBackgroundColor(color));
    } else {
      dispatch(setBannerFooterBackgroundColor(color));
    }
    formik.setFieldValue('footerBackgroundColor', color);
  };

  const handleRightImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerRightImageUrl(event.target.value));
    } else {
      dispatch(setBannerRightImageUrl(event.target.value));
    }
    formik.setFieldValue('rightImageUrl', event.target.value);
  };
  const handleRightImageUpload = (url: string) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerRightImageUrl(url));
    } else {
      dispatch(setBannerRightImageUrl(url));
    }
    formik.setFieldValue('rightImageUrl', url);
  };

  const handleActionTypeChange = (value: string) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerActionType(value));
    } else {
      dispatch(setBannerActionType(value));
    }
    formik.setFieldValue('actionType', value);
  };

  const handleActionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerId === 'new-banner') {
      dispatch(setNewBannerActionValue(event.target.value));
    } else {
      dispatch(setBannerActionValue(event.target.value));
    }
    formik.setFieldValue('actionValue', event.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <BannerContainer>
          <Header>
            <MainButton onClick={handleSaveBtnClick} disabled={isSaveBtnDisabled}>
              Сохранить
            </MainButton>
          </Header>
          <BannerControlsContainer>
            <BannerTextField
              label="Имя"
              type="text"
              size="small"
              value={formik.values.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleNameChange}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextFieldGroup marginBottom="10px" title="Заглавие">
              <ControlRow>
                <BannerTextField
                  label="Значение"
                  type="text"
                  size="small"
                  value={formik.values.titleTextValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleTitleTextValueChange}
                  sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                  error={formik.touched.titleTextValue && !!formik.errors.titleTextValue}
                  helperText={formik.touched.titleTextValue && formik.errors.titleTextValue}
                />
                <ColorPickerWithTextField
                  label="Цвет текста"
                  type="text"
                  size="small"
                  value={formik.values.titleTextColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={handleTitleTextColorChange}
                  handleColorChange={handleTitleTextColorFromColorPickerChange}
                  sx={{ marginBottom: '0 !important' }}
                  error={formik.touched.titleTextColor && !!formik.errors.titleTextColor}
                  helperText={formik.touched.titleTextColor && formik.errors.titleTextColor}
                />
              </ControlRow>
            </TextFieldGroup>
            <TextFieldGroup marginBottom="10px" title="Текст">
              <ControlRow>
                <BannerTextField
                  label="Значение"
                  type="text"
                  size="small"
                  value={formik.values.textValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleTextValueChange}
                  sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                  error={formik.touched.textValue && !!formik.errors.textValue}
                  helperText={formik.touched.textValue && formik.errors.textValue}
                />
                <ColorPickerWithTextField
                  label="Цвет текста"
                  type="text"
                  size="small"
                  value={formik.values.textColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={handleTextColorChange}
                  handleColorChange={handleTextColorFromColorPickerChange}
                  sx={{ marginBottom: '0 !important' }}
                  error={formik.touched.textColor && !!formik.errors.textColor}
                  helperText={formik.touched.textColor && formik.errors.textColor}
                />
              </ControlRow>
            </TextFieldGroup>
            <TextFieldGroup marginBottom="18px" title="Подвал">
              <ControlRow sx={{ mb: '7px' }}>
                <BannerTextField
                  label="Тип"
                  type="text"
                  size="small"
                  value={EBannerFooterType.button}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  sx={{ marginRight: '10px' }}
                />
                <BannerTextField
                  label="Значение"
                  type="text"
                  size="small"
                  value={formik.values.footerValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFooterValueChange}
                  error={formik.touched.footerValue && !!formik.errors.footerValue}
                  helperText={formik.touched.footerValue && formik.errors.footerValue}
                />
              </ControlRow>
              <ControlRow>
                <ColorPickerWithTextField
                  label="Цвет текста"
                  type="text"
                  size="small"
                  value={formik.values.footerTextColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={handleFooterTextColorChange}
                  handleColorChange={handleFooterTextColorFromColorPickerChange}
                  sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                  error={formik.touched.footerTextColor && !!formik.errors.footerTextColor}
                  helperText={formik.touched.footerTextColor && formik.errors.footerTextColor}
                />
                <ColorPickerWithTextField
                  label="Цвет фона"
                  type="text"
                  size="small"
                  value={formik.values.footerBackgroundColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={handleFooterBackgroundColorChange}
                  handleColorChange={handleFooterBackgroundColorFromColorPickerChange}
                  sx={{ marginBottom: '0 !important' }}
                  error={
                    formik.touched.footerBackgroundColor && !!formik.errors.footerBackgroundColor
                  }
                  helperText={
                    formik.touched.footerBackgroundColor && formik.errors.footerBackgroundColor
                  }
                />
              </ControlRow>
            </TextFieldGroup>
            <UploadFileWithTextField
              label="Ссылка на картинку справа"
              type="text"
              size="small"
              value={formik.values.rightImageUrl}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleRightImageUrlChange}
              handleFileUpload={handleRightImageUpload}
              error={formik.touched.rightImageUrl && !!formik.errors.rightImageUrl}
              helperText={formik.touched.rightImageUrl && formik.errors.rightImageUrl}
            />
            <TextFieldGroup title="Диплинк">
              <ControlRow>
                <CustomSelect
                  label="Тип действия"
                  value={formik.values.actionType}
                  optionValues={actionButtonOptionValues}
                  optionTexts={actionButtonOptionTexts}
                  sx={{ marginBottom: '0 !important', marginRight: '10px' }}
                  onChange={handleActionTypeChange}
                  error={formik.touched.actionType && !!formik.errors.actionType}
                  helperText={formik.touched.actionType && formik.errors.actionType}
                />
                <BannerTextField
                  label="Значение"
                  type="text"
                  size="small"
                  value={formik.values.actionValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleActionValueChange}
                  sx={{ marginBottom: '0 !important' }}
                  error={formik.touched.actionValue && !!formik.errors.actionValue}
                  helperText={formik.touched.actionValue && formik.errors.actionValue}
                />
              </ControlRow>
            </TextFieldGroup>
          </BannerControlsContainer>
        </BannerContainer>
      </Form>
    </FormikProvider>
  );
};
