import styled, { css } from 'styled-components';

export const MenuButtonWrapper = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 20px 40px 20px 40px;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      background-color: ${theme.colors.dispatchersWorkplace.sidebarMenu.active} !important;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.dispatchersWorkplace.sidebarMenu.hover};
`;
