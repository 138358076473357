import logoSpar from '@/assets/img/dispatchers-workplace-spar-logo.png';
import { IDispatchersWorkplaceBasicInfo } from '../types';

export const dispatchersWorkplaceBasicInfo: IDispatchersWorkplaceBasicInfo = {
  logo_url: logoSpar,
  retail_network_title: 'SPAR Online',
  dispatcher: {
    name: 'Костиченко Виктор',
    job_title: 'Диспетчер',
  },
};
