import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TRootState } from '@/store';

export const usePromotionTypesSelectData = () => {
  const promotionTypes = useSelector((state: TRootState) => state.promotions.promotionTypes);

  const promotionTypeOptionTexts = useMemo(() => {
    return (
      promotionTypes?.map((typeItem) => {
        return typeItem.name;
      }) || []
    );
  }, [promotionTypes]);
  const promotionTypeOptionValues = useMemo(() => {
    return (
      promotionTypes?.map((typeItem) => {
        return typeItem.id;
      }) || []
    );
  }, [promotionTypes]);

  return { promotionTypeOptionTexts, promotionTypeOptionValues };
};
