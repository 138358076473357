import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { styledTheme } from '@/app/themes/styled-theme';
import store from '@/store';
import { AxiosErrorInterceptorProvider } from '@/shared/providers/AxiosErrorInterceptorProvider';
import { AxiosRequestInterceptorProvider } from '@/shared/providers/AxiosRequestInterceptorProvider';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SnackbarProvider maxSnack={5}>
          <AxiosRequestInterceptorProvider>
            <AxiosErrorInterceptorProvider>
              <StyledThemeProvider theme={styledTheme}>
                <CssBaseline />
                <App />
              </StyledThemeProvider>
            </AxiosErrorInterceptorProvider>
          </AxiosRequestInterceptorProvider>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
