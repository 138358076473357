import { Route } from 'react-router-dom';

import { ROUTES } from '@/shared/lib/const';
import { DispatchersWorkplace } from '@/features/dispatchers-workplace/main';
import { bottomMenuItems, topMenuItems } from '../sidebar/const';

export const dispatchersWorkplaceRoutes = () => {
  return (
    <Route path={ROUTES.dispatchersWorkplace} element={<DispatchersWorkplace />}>
      {topMenuItems.map((menuItem, index) => {
        const Component = menuItem.component;
        return <Route key={index} path={menuItem.link} element={<Component />} />;
      })}
      {bottomMenuItems.map((menuItem, index) => {
        const Component = menuItem.component;
        return <Route key={index} path={menuItem.link} element={<Component />} />;
      })}
    </Route>
  );
};
