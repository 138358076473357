import { FC } from 'react';

import {
  TableColumnArrowWrapper,
  TableHeadCellText,
  TableHeadCellContainer,
  TableHeadCellWrapper,
} from './elements';
import { ESlalepointInfoListTableColumnArrow } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';
import TableColumnArrowDownSvg from '@/assets/icons/dispatchers-workplace/table-arrow-down.svg?react';
import TableColumnArrowUpSvg from '@/assets/icons/dispatchers-workplace/table-arrow-up.svg?react';
import { SxProps } from '@mui/material';

interface ITableHeadCellProps {
  sx?: SxProps;
  title?: string;
  isOneLineText?: boolean;
  arrowDirection?: ESlalepointInfoListTableColumnArrow;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const TableHeadCell: FC<ITableHeadCellProps> = ({
  sx,
  title,
  isOneLineText,
  arrowDirection,
  onClick,
}) => {
  return (
    <TableHeadCellContainer sx={sx} onClick={onClick}>
      <TableHeadCellWrapper>
        <TableHeadCellText $isOneLineText={isOneLineText}>{title}</TableHeadCellText>
        <TableColumnArrowWrapper>
          {arrowDirection === ESlalepointInfoListTableColumnArrow.down && (
            <TableColumnArrowDownSvg />
          )}
          {arrowDirection === ESlalepointInfoListTableColumnArrow.up && <TableColumnArrowUpSvg />}
        </TableColumnArrowWrapper>
      </TableHeadCellWrapper>
    </TableHeadCellContainer>
  );
};
