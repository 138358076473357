import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TRootState, useAppDispatch } from '@/store';
import { EPromotionTabPages } from '../types';
import { PromotionList } from './PromotionList';
import { Promotion } from './Promotion';
import { fetchPromotionCategories, fetchPromotionTypes } from '../redux/promotions.actions';
import { Box } from '@mui/material';

const PromotionsTabWrapContent: FC<PropsWithChildren> = ({ children }) => {
  return <Box sx={{ p: 3, minWidth: '740px' }}>{children}</Box>;
};

export const PromotionsTab: FC = () => {
  const dispatch = useAppDispatch();
  const activePage = useSelector((state: TRootState) => state.promotions.promotionsTab.activePage);

  useEffect(() => {
    dispatch(fetchPromotionTypes());
    dispatch(fetchPromotionCategories());
  }, []);

  if (activePage === EPromotionTabPages.promotionList) {
    return (
      <PromotionsTabWrapContent>
        <PromotionList />
      </PromotionsTabWrapContent>
    );
  } else if (
    activePage === EPromotionTabPages.newPromotion ||
    activePage === EPromotionTabPages.existingPromotion
  ) {
    return (
      <PromotionsTabWrapContent>
        <Promotion />
      </PromotionsTabWrapContent>
    );
  }

  return null;
};
