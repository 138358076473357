import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { QUERY_ENDPOINTS } from '@/shared/lib/const';
import {
  IDispatchersWorkplaceBasicInfo,
  IFetchDispatchersWorkplaceBasicInfoResponse,
} from '../types';

export const fetchDispatchersWorkplaceBasicInfo = createAsyncThunk<
  IDispatchersWorkplaceBasicInfo,
  void,
  unknown
>('dispatchersWorkplaceSidebar/fetchBasicInfo', async () => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchDispatchersWorkplaceBasicInfoResponse>(
    QUERY_ENDPOINTS.dispatchersWorkplace.basicInfo.replace('[userId]', userId)
  );
  return response?.data?.data;
});
