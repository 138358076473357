import styled from 'styled-components';

export const RetailNetworkLogo = styled.div<{ $img: string }>`
  position: relative;
  display: block;
  width: 160px;
  height: 56px;
  background-image: ${({ $img }) => `url(${$img})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  user-select: none;
`;
