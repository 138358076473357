import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import sortBy from 'lodash.sortby';

import { INotificationTask, INotificationTaskListItem } from '../types';
import {
  fetchNotificationTask,
  fetchNotificationTaskList,
  saveNotificationTask,
} from './notificationTasks.actions';
import { ERequestStatus } from '@/shared/lib/types';
import { notificationListData } from '../stub/notificationListData';
import { emptyNotification } from '../const';

interface INotificationTasksState {
  tasks: INotificationTaskListItem[];
  fetchNotificationTaskListStatus: ERequestStatus;
  task: INotificationTask;
  taskInitial: INotificationTask;
  fetchNotificationTaskStatus: ERequestStatus;
  newTask: INotificationTask;
  saveNotificationTaskStatus: ERequestStatus;
}

const initialState: INotificationTasksState = {
  tasks: [], // notificationListData,
  fetchNotificationTaskListStatus: ERequestStatus.idle,
  task: {} as INotificationTask,
  taskInitial: {} as INotificationTask,
  fetchNotificationTaskStatus: ERequestStatus.idle,
  newTask: null,
  saveNotificationTaskStatus: ERequestStatus.idle,
};

const notificationTasks = createSlice({
  name: 'notificationTasks',
  initialState,
  reducers: {
    setNewNotificationTaskTitleValue: (state, action) => {
      state.newTask.title = action.payload;
    },
    setNewNotificationTaskDescriptionValue: (state, action) => {
      state.newTask.description = action.payload;
    },
    setNewNotificationTaskSendPushValue: (state, action) => {
      state.newTask.send_push = action.payload;
    },
    setNewNotificationTaskDetailsTitleValue: (state, action) => {
      state.newTask.details_title = action.payload;
    },
    setNewNotificationTaskDetailsDescriptionValue: (state, action) => {
      state.newTask.details_description = action.payload;
    },
    setNewNotificationTaskButtonTitleValue: (state, action) => {
      state.newTask.button.title = action.payload;
    },
    setNewNotificationTaskButtonColor: (state, action) => {
      state.newTask.button.color = action.payload;
    },
    setNewNotificationTaskButtonType: (state, action) => {
      state.newTask.button.value.type = action.payload;
    },
    setNewNotificationTaskButtonValue: (state, action) => {
      state.newTask.button.value.value = action.payload;
    },
    setNewNotificationTaskСontactsDataUrl: (state, action) => {
      state.newTask.contacts_data_url = action.payload;
    },
    setNewNotificationTaskNotificationProcessType: (state, action) => {
      state.newTask.notification_process.type = action.payload;
    },
    setNewNotificationTaskNotificationProcessScheduleDatetime: (state, action) => {
      state.newTask.notification_process.schedule_datetime = action.payload;
    },
    setNotificationTaskTitleValue: (state, action) => {
      state.task.title = action.payload;
    },
    setNotificationTaskDescriptionValue: (state, action) => {
      state.task.description = action.payload;
    },
    setNotificationTaskSendPushValue: (state, action) => {
      state.task.send_push = action.payload;
    },
    setNotificationTaskDetailsTitleValue: (state, action) => {
      state.task.details_title = action.payload;
    },
    setNotificationTaskDetailsDescriptionValue: (state, action) => {
      state.task.details_description = action.payload;
    },
    setNotificationTaskButtonTitleValue: (state, action) => {
      state.task.button.title = action.payload;
    },
    setNotificationTaskButtonColor: (state, action) => {
      state.task.button.color = action.payload;
    },
    setNotificationTaskButtonType: (state, action) => {
      state.task.button.value.type = action.payload;
    },
    setNotificationTaskButtonValue: (state, action) => {
      state.task.button.value.value = action.payload;
    },
    setNotificationTaskСontactsDataUrl: (state, action) => {
      state.task.contacts_data_url = action.payload;
    },
    setNotificationTaskNotificationProcessType: (state, action) => {
      state.task.notification_process.type = action.payload;
    },
    setNotificationTaskNotificationProcessScheduleDatetime: (state, action) => {
      state.task.notification_process.schedule_datetime = action.payload;
    },
    deleteNewNotification: (state) => {
      state.newTask = null;
    },
    createNewNotificationTask: (state) => {
      state.newTask = cloneDeep(emptyNotification);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotificationTaskList.pending, (state) => {
        state.fetchNotificationTaskListStatus = ERequestStatus.pending;
      })
      .addCase(fetchNotificationTaskList.fulfilled, (state, action) => {
        state.fetchNotificationTaskListStatus = ERequestStatus.success;
        state.tasks = sortBy(action.payload, ['id']);
      })
      .addCase(fetchNotificationTaskList.rejected, (state) => {
        state.fetchNotificationTaskListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchNotificationTask.pending, (state) => {
        state.fetchNotificationTaskStatus = ERequestStatus.pending;
      })
      .addCase(fetchNotificationTask.fulfilled, (state, action) => {
        state.fetchNotificationTaskStatus = ERequestStatus.success;
        state.task = action.payload;
        state.taskInitial = cloneDeep(action.payload);
      })
      .addCase(fetchNotificationTask.rejected, (state) => {
        state.fetchNotificationTaskStatus = ERequestStatus.error;
      });

    builder
      .addCase(saveNotificationTask.pending, (state) => {
        state.saveNotificationTaskStatus = ERequestStatus.pending;
      })
      .addCase(saveNotificationTask.fulfilled, (state) => {
        state.saveNotificationTaskStatus = ERequestStatus.success;
        state.taskInitial = cloneDeep(state.task);
      })
      .addCase(saveNotificationTask.rejected, (state) => {
        state.saveNotificationTaskStatus = ERequestStatus.error;
      });
  },
});

export const {
  setNewNotificationTaskTitleValue,
  setNewNotificationTaskDescriptionValue,
  setNewNotificationTaskSendPushValue,
  setNewNotificationTaskDetailsTitleValue,
  setNewNotificationTaskDetailsDescriptionValue,
  setNewNotificationTaskButtonTitleValue,
  setNewNotificationTaskButtonColor,
  setNewNotificationTaskButtonType,
  setNewNotificationTaskButtonValue,
  setNewNotificationTaskСontactsDataUrl,
  setNewNotificationTaskNotificationProcessType,
  setNewNotificationTaskNotificationProcessScheduleDatetime,
  setNotificationTaskTitleValue,
  setNotificationTaskDescriptionValue,
  setNotificationTaskSendPushValue,
  setNotificationTaskDetailsTitleValue,
  setNotificationTaskDetailsDescriptionValue,
  setNotificationTaskButtonTitleValue,
  setNotificationTaskButtonColor,
  setNotificationTaskButtonType,
  setNotificationTaskButtonValue,
  setNotificationTaskСontactsDataUrl,
  setNotificationTaskNotificationProcessType,
  setNotificationTaskNotificationProcessScheduleDatetime,
  deleteNewNotification,
  createNewNotificationTask,
} = notificationTasks.actions;

export default notificationTasks.reducer;
