import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import cloneDeep from 'lodash.clonedeep';

import { styledTheme } from './styled-theme';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

const muiBasicThemeData: Record<string, any> = {
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#7b9efc',
    },
    error: {
      main: red.A400,
    },
    lightGrey: createColor('rgba(255, 255, 255, 0.23)'),
    buttonMain: createColor('#4b7cfc'),
    textMain: createColor(styledTheme.colors.text.main),
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
};

const muiLightThemeData = cloneDeep(muiBasicThemeData);
muiLightThemeData.palette.mode = 'light';

const muiDarkThemeData = cloneDeep(muiBasicThemeData);
muiDarkThemeData.palette.mode = 'dark';

// Create a theme instance.
export const muiLightTheme = createTheme(muiLightThemeData);

export const muiDarkTheme = createTheme(muiDarkThemeData);
