import { FC } from 'react';
import { CarouselIndicatorShown, CarouselIndicatorWrapper } from './elements';

export interface ICarouselIndicatorProps {
  isShown: boolean;
  isActive: boolean;
  slidesNumber: number;
  duration: number;
  progressColor: string;
  progressBackgroundColor: string;
}

const CarouselIndicator: FC<ICarouselIndicatorProps> = ({
  isShown,
  isActive,
  slidesNumber,
  duration,
  progressColor,
  progressBackgroundColor,
}) => {
  return (
    <CarouselIndicatorWrapper
      $progressBackgroundColor={progressBackgroundColor}
      $progressColor={progressColor}
      $slidesNumber={slidesNumber}
      $isShown={isShown}
    >
      <CarouselIndicatorShown
        $progressColor={progressColor}
        $duration={duration}
        className={isActive ? 'active' : null}
      />
    </CarouselIndicatorWrapper>
  );
};

export default CarouselIndicator;
