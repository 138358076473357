import styled from 'styled-components';

import ServiceNotAvailableImg from '@/assets/img/service-not-available.png';

export const ServiceNotAvailableEmoji = styled.div`
  background-image: url(${ServiceNotAvailableImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100px;
  height: 100px;
`;
