import { IBanner } from '../types';

export const trimBannerColorFields = (banner: IBanner) => {
  if (banner?.title?.text_color) {
    banner.title.text_color = banner.title.text_color.trim();
  }
  if (banner?.text?.text_color) {
    banner.text.text_color = banner.text.text_color.trim();
  }
  if (banner.footer.text_color) {
    banner.footer.text_color = banner.footer.text_color.trim();
  }
  if (banner.footer.background_color) {
    banner.footer.background_color = banner.footer.background_color.trim();
  }
  return banner;
};
