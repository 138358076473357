export const collectorListData = [
  'Гусаров Аман',
  'Стамилов Николай',
  'Кудрявцев Валерий',
  'Гусаров Аман выаыв',
  'Стамилов Николай ываыва',
  'Кудрявцев Валерий ываыав',
  'Гусаров Аман Длинная фамилия',
  'Стамилов Николай Длинная фамилия',
  'Кудрявцев Валерий Длинная-длинная фамилия',
];
