import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import { ERequestStatus } from '@/shared/lib/types';
import {
  fetchBanner,
  fetchBannerList,
  fetchBannerScenarios,
  saveBanner,
  saveBannerList,
} from './scenarios.actions';
import { IBanner, IBannerListItem, IBannerScenarioItem } from '../types';
import { emptyBannerData } from '../const';
import { trimBannerColorFields } from '../utils';
// import { bannerScenariosData } from '../stub/bannerScenariosData';
// import { bannerListData } from '../stub/bannerScenarioData';

interface IScenariosState {
  bannerScenarios: IBannerScenarioItem[];
  fetchBannerScenariosStatus: ERequestStatus;
  bannerListInitial: IBannerListItem[];
  bannerList: IBannerListItem[];
  fetchBannerListStatus: ERequestStatus;
  saveBannerListStatus: ERequestStatus;
  banner: IBanner;
  bannerInitial: IBanner;
  fetchBannerStatus: ERequestStatus;
  saveBannerStatus: ERequestStatus;
  newBanner: IBanner;
}

const initialState: IScenariosState = {
  bannerScenarios: [], // bannerScenariosData,
  fetchBannerScenariosStatus: ERequestStatus.idle,
  bannerListInitial: [],
  bannerList: [], // bannerListData,
  fetchBannerListStatus: ERequestStatus.idle,
  saveBannerListStatus: ERequestStatus.idle,
  banner: {} as IBanner,
  bannerInitial: {} as IBanner,
  fetchBannerStatus: ERequestStatus.idle,
  saveBannerStatus: ERequestStatus.idle,
  newBanner: null,
};

const bannerScenariosSlice = createSlice({
  name: 'bannerScenarios',
  initialState,
  reducers: {
    setBannerIsActive: (state, action) => {
      const { bannerId, checked } = action.payload;
      state.bannerList = state.bannerList.map((banner) => {
        if (banner.id === Number(bannerId)) {
          banner.is_active = checked;
        }
        return banner;
      });
    },
    setBannerList: (state, action) => {
      state.bannerList = action.payload;
    },
    setBannerNameValue: (state, action) => {
      state.banner.name = action.payload;
    },
    setBannerTitleTextValue: (state, action) => {
      state.banner.title.value = action.payload;
    },
    setBannerTitleTextColor: (state, action) => {
      state.banner.title.text_color = action.payload;
    },
    setBannerTextValue: (state, action) => {
      state.banner.text.value = action.payload;
    },
    setBannerTextColor: (state, action) => {
      state.banner.text.text_color = action.payload;
    },
    setBannerFooterValue: (state, action) => {
      state.banner.footer.value = action.payload;
    },
    setBannerFooterTextColor: (state, action) => {
      state.banner.footer.text_color = action.payload;
    },
    setBannerFooterBackgroundColor: (state, action) => {
      state.banner.footer.background_color = action.payload;
    },
    setBannerRightImageUrl: (state, action) => {
      state.banner.right_image_url = action.payload;
    },
    setBannerActionType: (state, action) => {
      state.banner.deep_link.type = action.payload;
    },
    setBannerActionValue: (state, action) => {
      state.banner.deep_link.value = action.payload;
    },
    setNewBannerNameValue: (state, action) => {
      state.newBanner.name = action.payload;
    },
    setNewBannerTitleTextValue: (state, action) => {
      state.newBanner.title.value = action.payload;
    },
    setNewBannerTitleTextColor: (state, action) => {
      state.newBanner.title.text_color = action.payload;
    },
    setNewBannerTextValue: (state, action) => {
      state.newBanner.text.value = action.payload;
    },
    setNewBannerTextColor: (state, action) => {
      state.newBanner.text.text_color = action.payload;
    },
    setNewBannerFooterValue: (state, action) => {
      state.newBanner.footer.value = action.payload;
    },
    setNewBannerFooterTextColor: (state, action) => {
      state.newBanner.footer.text_color = action.payload;
    },
    setNewBannerFooterBackgroundColor: (state, action) => {
      state.newBanner.footer.background_color = action.payload;
    },
    setNewBannerRightImageUrl: (state, action) => {
      state.newBanner.right_image_url = action.payload;
    },
    setNewBannerActionType: (state, action) => {
      state.newBanner.deep_link.type = action.payload;
    },
    setNewBannerActionValue: (state, action) => {
      state.newBanner.deep_link.value = action.payload;
    },
    createNewBanner: (state) => {
      state.newBanner = cloneDeep(emptyBannerData);
    },
    deleteNewBanner: (state) => {
      state.newBanner = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBannerScenarios.pending, (state) => {
        state.fetchBannerScenariosStatus = ERequestStatus.pending;
      })
      .addCase(fetchBannerScenarios.fulfilled, (state, action) => {
        state.fetchBannerScenariosStatus = ERequestStatus.success;
        state.bannerScenarios = action.payload;
      })
      .addCase(fetchBannerScenarios.rejected, (state) => {
        state.fetchBannerScenariosStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchBannerList.pending, (state) => {
        state.fetchBannerListStatus = ERequestStatus.pending;
      })
      .addCase(fetchBannerList.fulfilled, (state, action) => {
        state.fetchBannerListStatus = ERequestStatus.success;
        state.bannerList = action.payload;
        state.bannerListInitial = cloneDeep(action.payload);
      })
      .addCase(fetchBannerList.rejected, (state) => {
        state.fetchBannerListStatus = ERequestStatus.error;
      });

    builder
      .addCase(saveBannerList.pending, (state) => {
        state.saveBannerListStatus = ERequestStatus.pending;
      })
      .addCase(saveBannerList.fulfilled, (state) => {
        state.saveBannerListStatus = ERequestStatus.success;
      })
      .addCase(saveBannerList.rejected, (state) => {
        state.saveBannerListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchBanner.pending, (state) => {
        state.fetchBannerStatus = ERequestStatus.pending;
      })
      .addCase(fetchBanner.fulfilled, (state, action) => {
        state.fetchBannerStatus = ERequestStatus.success;
        state.bannerInitial = trimBannerColorFields(cloneDeep(action.payload));
        state.banner = action.payload;
      })
      .addCase(fetchBanner.rejected, (state) => {
        state.fetchBannerStatus = ERequestStatus.error;
      });

    builder
      .addCase(saveBanner.pending, (state) => {
        state.saveBannerStatus = ERequestStatus.pending;
      })
      .addCase(saveBanner.fulfilled, (state, action) => {
        state.saveBannerStatus = ERequestStatus.success;
        state.banner.version = action.payload.version;
        state.bannerInitial = cloneDeep(state.banner);
      })
      .addCase(saveBanner.rejected, (state) => {
        state.saveBannerStatus = ERequestStatus.error;
      });
  },
});

export const {
  setBannerIsActive,
  setBannerList,
  setBannerNameValue,
  setBannerTitleTextValue,
  setBannerTitleTextColor,
  setBannerTextValue,
  setBannerTextColor,
  setBannerFooterValue,
  setBannerFooterTextColor,
  setBannerFooterBackgroundColor,
  setBannerRightImageUrl,
  setBannerActionType,
  setBannerActionValue,
  setNewBannerNameValue,
  setNewBannerTitleTextValue,
  setNewBannerTitleTextColor,
  setNewBannerTextValue,
  setNewBannerTextColor,
  setNewBannerFooterValue,
  setNewBannerFooterTextColor,
  setNewBannerFooterBackgroundColor,
  setNewBannerRightImageUrl,
  setNewBannerActionType,
  setNewBannerActionValue,
  createNewBanner,
  deleteNewBanner,
} = bannerScenariosSlice.actions;

export default bannerScenariosSlice.reducer;
