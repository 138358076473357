import * as React from 'react';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

import { styledTheme } from '@/app/themes/styled-theme';
import { PasswordTextFieldControl } from '../PasswordTextFieldControl';

export const StyledOutlinedPasswordInput = styled(PasswordTextFieldControl)<OutlinedInputProps>(
  ({ theme }) => ({
    width: '100%',
    '& input.MuiOutlinedInput-input': {
      color: styledTheme.colors.login.input.textColor,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: styledTheme.colors.login.input.border,
    },
    '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: styledTheme.colors.error,
    },
    '&:not(.Mui-disabled):not(.Mui-error):hover fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: styledTheme.colors.login.input.borderHover,
    },
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': styledTheme.colors.login.input.textColor,
        transition: 'background-color 5000s ease-in-out 0s',
        boxShadow: `inset 0 0 200px 200px ${styledTheme.colors.login.input.autocompleteBackground}`,
      },
  })
);
