import { FC, SyntheticEvent, useMemo, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams } from 'react-router-dom';

import {
  Story,
  StotyNameWrapper,
  StoryName,
  StyledLink,
  TooltipImg,
  TooltipText,
  StoryCheckboxWrapper,
} from '@/features/stories/scenario/components/Card/elements';
import { setStoryIsRequired, setStoryIsActive } from '../../../redux/stories.slice';
import { TRootState, useAppDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { ROUTES } from '@/shared/lib/const';
import {
  IStoryPreviewParamsBackground,
  IStoryPreviewParamsTitle,
  IStorySequenceItem,
} from '@/features/stories/types';

interface ICardOwnProps {}

type TCardOwnProps = ICardOwnProps & IStorySequenceItem;

export const Card: FC<TCardOwnProps> = ({
  isNewStory,
  external_story_id,
  name,
  is_active,
  is_required,
}) => {
  const dispatch = useAppDispatch();
  const storyPreviews = useSelector((state: TRootState) => state.stories.storyPreviews);
  const [tooltipImageUrl, setTooltipImageUrl] = useState('');
  const [tooltipTextValue, setTooltipTextValue] = useState('');
  const [tooltipTextColor, setTooltipTextColor] = useState('');
  const { scenario } = useParams();
  const preview = useMemo(() => {
    return storyPreviews[external_story_id];
  }, [storyPreviews, external_story_id]);

  useEffect(() => {
    if (preview) {
      const titleParamIndex = preview?.params?.findIndex((param) => param.name === 'title');
      const backgroundParamIndex = preview?.params?.findIndex(
        (param) => param.name === 'background'
      );
      const title = preview?.params?.[titleParamIndex];
      const background = preview?.params?.[backgroundParamIndex];

      if (!!background && background?.value) {
        setTooltipImageUrl((background as IStoryPreviewParamsBackground)?.value);
      }
      if (title) {
        if ((title as IStoryPreviewParamsTitle)?.text_color) {
          setTooltipTextColor((title as IStoryPreviewParamsTitle)?.text_color);
        }
        if ((title as IStoryPreviewParamsTitle).value) {
          setTooltipTextValue((title as IStoryPreviewParamsTitle)?.value);
        }
      }
    }
  }, [preview]);

  const handleIsRequiredCheckboxChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    dispatch(
      setStoryIsRequired({
        scenario,
        isNewStory: isNewStory,
        externalStoryId: external_story_id,
        checked,
      })
    );
    event.preventDefault();
  };

  const handleIsActiveCheckboxChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    dispatch(
      setStoryIsActive({
        scenario,
        isNewStory: isNewStory,
        externalStoryId: external_story_id,
        checked,
      })
    );
    event.preventDefault();
  };

  const handleCheckboxClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <StyledLink
      to={ROUTES.story
        .replace(':scenario', encodeURIComponent(scenario))
        .replace(':story', encodeURIComponent(isNewStory ? 'new_story' : external_story_id))}
    >
      <Story>
        <TooltipImg $url={tooltipImageUrl}>
          <TooltipText $color={tooltipTextColor}>{tooltipTextValue}</TooltipText>
        </TooltipImg>
        <StotyNameWrapper>
          <StoryName>{isNewStory ? 'Новая стори' : name}</StoryName>
        </StotyNameWrapper>
        <StoryCheckboxWrapper>
          <FormControlLabel
            control={<Checkbox />}
            checked={is_required}
            onChange={handleIsRequiredCheckboxChange}
            onClick={handleCheckboxClick}
            label="Всплывающая"
            sx={{ marginRight: 0 }}
          />
        </StoryCheckboxWrapper>
        <StoryCheckboxWrapper>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            checked={is_active}
            onChange={handleIsActiveCheckboxChange}
            onClick={handleCheckboxClick}
            label="Отображать"
            sx={{ marginRight: 0 }}
          />
        </StoryCheckboxWrapper>
      </Story>
    </StyledLink>
  );
};
