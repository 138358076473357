import styled from 'styled-components';

export const Text = styled.div<{ $isDisabled?: boolean }>`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.text.disabled : theme.colors.text.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
