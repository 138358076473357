export enum EClientSettingsFieldKeys {
  filePath = 'file_path',
  purchaseHistoryFilters = 'purchase_history_filters',
  partnerId = 'partner_id',
  staticCodes = 'static_codes',
  supportPhone = 'support_phone',
  supportEmail = 'support_email',
}

export interface IClientSettingsFieldItem {
  key: EClientSettingsFieldKeys | EPurchaseHistoryFilterKey | string;
  name?: string;
  value?: any;
  fields?: IClientSettingsFieldItem[];
}

export interface INewStaticCodesItem {
  staticCodeName: string;
  staticCodeValue: string;
}

export interface IClientSettings {
  version?: number;
  fields: IClientSettingsFieldItem[];
}

export interface ISaveClientSettingsReponse {
  data: {
    status: 'ok';
    version: number;
  };
}

export interface IFetchClientSettingsReponse {
  data: {
    version: number;
    fields: IClientSettingsFieldItem[];
  };
}

export enum EPurchaseHistoryFilterKey {
  thisWeek = 'this_week',
  lastWeek = 'last_week',
  thisMonth = 'this_month',
  lastMonth = 'last_month',
}
