import { FC, MouseEvent } from 'react';
import { OrderCollectorsAmount, OrderCollectorsWrapper } from './elements';
import { IOrderCollectors } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';

interface IOrderCollectorsProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  orderCollectors: IOrderCollectors;
}

export const OrderCollectors: FC<IOrderCollectorsProps> = ({ orderCollectors, onClick }) => {
  return (
    <OrderCollectorsWrapper onClick={onClick}>
      <OrderCollectorsAmount $shiftState={orderCollectors.shift_state}>
        {orderCollectors.amount}
      </OrderCollectorsAmount>
    </OrderCollectorsWrapper>
  );
};
