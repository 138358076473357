import { Flags } from 'yup';
import { AnyObject, Maybe } from 'yup';
import * as Yup from 'yup';

export interface IClientListRecord {
  id: number;
  client_url: string;
  name: string;
  logo_url: string;
  dwp_only?: boolean;
}

export enum ERequestStatus {
  idle = 'idle',
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export type TJSONEntity<V = unknown> = {
  status: string;
  data?: TJSONEntityData<V>;
};

export type TJSONEntityData<V = unknown> = Record<string, V>;

export interface IApiErrorRespose extends IError {
  data?: {
    user: {
      id: number;
    };
    clients: IClientListRecord[];
  };
}

export interface IError {
  error: {
    code: string;
    description: string;
    user_message: string;
  };
}

export enum EActionButtonType {
  deepLink = 'deep_link',
  browser = 'browser',
  webview = 'webview',
  share = 'share',
  close = 'close',
}

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext = AnyObject,
    TDefault = undefined,
    TFlags extends Flags = '',
  > extends Yup.Schema<TType, TContext, TDefault, TFlags> {
    regularTextInputValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    colorInputValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    regularSelectComponentValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    storyExternalIdValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    itemNameInputValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    storyTooltipTextInputValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    externalLinkInputValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    slideExternalIdValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    slideTitleValueValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    slideDescriptionValueValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    itemButtonTextValueValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    itemActionTypeValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionNameValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionTypeValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionDescriptionValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionConditionValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionRequirementsValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionStartDatetimeValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionFinishDatetimeValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionPublicationDatetimeValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    promotionCollectionNameValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    bannerTitleValueValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    bannerTextValueValidation(): StringSchema<TType, TContext, TDefault, TFlags>;
    notificationTitleAndDetailsTitleTextValueValidation(): StringSchema<
      TType,
      TContext,
      TDefault,
      TFlags
    >;
    notificationDescriptionAndDetailsDescriptionValueValidation(): StringSchema<
      TType,
      TContext,
      TDefault,
      TFlags
    >;
    notificationProcessScheduleDatetimeValidation(): StringSchema<
      TType,
      TContext,
      TDefault,
      TFlags
    >;
  }

  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends Flags = '',
  > extends Yup.Schema<TIn, TContext, TDefault, TFlags> {
    promotionCategoryIdsValidation(): ArraySchema<TIn, TContext, TDefault, TFlags>;
    promotionCollectionPromotionListValidation(): ArraySchema<TIn, TContext, TDefault, TFlags>;
  }
}
