import { IPromotionItem } from '../types';

export const trimPromotionColorFields = (promotion: IPromotionItem) => {
  if (promotion?.button?.params?.text_color) {
    promotion.button.params.text_color = promotion?.button?.params?.text_color.trim();
  }
  if (promotion?.button?.params?.background_color) {
    promotion.button.params.background_color = promotion.button.params.background_color.trim();
  }
  return promotion;
};
