import { FC } from 'react';
import { BasicConfirmationModal } from '@/shared/components/BasicConfirmationModal';

interface IConfirmOrderChangeModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const heading = 'Подверждение перемещения подборок';
const content = 'Вы уверены, что хотите поменять порядок отображения для этих подборок?';

export const ConfirmPromotionCollectionOrderChangeModal: FC<IConfirmOrderChangeModalProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  return (
    <BasicConfirmationModal
      open={open}
      heading={heading}
      content={content}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
