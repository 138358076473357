import styled from 'styled-components';

export const SidebarContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  z-index: 3;
`;
