import { IViewDimensions } from '@/features/stories/types';
import styled from 'styled-components';

import { SLIDE_VIEWPORT_DIMENSIONS_COEFF } from '@/features/stories/const ';

export const SlideViewContainer = styled.div<{ $img: string; $dimensions: IViewDimensions }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  overflow: hidden;
  width: ${({ $dimensions }) => `${$dimensions.width * SLIDE_VIEWPORT_DIMENSIONS_COEFF}px`};
  height: ${({ $dimensions }) => `${$dimensions.height * SLIDE_VIEWPORT_DIMENSIONS_COEFF}px`};
  background-image: ${({ $img }) => `url(${$img})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 40px 10px 25px 10px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
`;
