import { styled } from 'styled-components';

export const PromotionCollectionItemWrapper = styled.li`
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.colors.promotionCollectionItem.border}`};
  width: 360px;
  margin-bottom: 10px;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }
`;
