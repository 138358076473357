import { Button } from '@mui/material';
import { css, styled } from 'styled-components';

export const ColorButton = styled(Button)<{ $bgColor: string; $isWithOpacity: boolean }>`
  position: relative;
  padding: 5px !important;
  min-width: 30px !important;
  width: 30px !important;
  min-height: 30px !important;
  height: 30px !important;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey} !important;
  background-color: ${({ $bgColor }) => $bgColor} !important;
  overflow: hidden;
  z-index: 2;

  &:hover {
    border-color: #fff !important;
  }
`;
