import { FC, useEffect, useMemo, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormikErrors } from 'formik';
import { FormHelperText } from '@mui/material';

const ITEM_HEIGHT = 54;
const ITEM_PADDING_TOP = 8;

interface IMultipleSelectCheckmarksProps {
  optionValues: (number | string)[];
  optionTexts: string[];
  label: string;
  value?: (string | number)[];
  onChange?: (checkedValues: (number | string)[]) => void;
  disabled?: boolean;
  sx?: Record<string, string | number>;
  optionMenuStyles?: Record<string, string | number>;
  error?: boolean;
  helperText?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

export const MultipleSelectCheckmarks: FC<IMultipleSelectCheckmarksProps> = ({
  optionValues,
  optionTexts,
  label,
  value,
  onChange,
  disabled,
  sx,
  optionMenuStyles,
  error,
  helperText,
}) => {
  const [checkedValues, setCheckedValues] = useState<(number | string)[]>([]);

  const MenuProps = useMemo(() => {
    return {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
          width: 250,
          ...optionMenuStyles,
        },
      },
    };
  }, [optionMenuStyles]);

  useEffect(() => {
    if (value) {
      const valueCopy = [...value];
      setCheckedValues(valueCopy);
    }
  }, [value]);

  const handleChange = (event: SelectChangeEvent<typeof checkedValues>) => {
    const value = event.target.value;
    // При автозаполнении value, мы получаем значение value в виде строки
    const nextValues = typeof value === 'string' ? value.split(',') : value;
    setCheckedValues(nextValues);
    onChange?.(nextValues);
  };

  return (
    <FormControl sx={{ flexGrow: 1, flexBasis: 0, ...sx }}>
      <InputLabel id="multiple-checkbox-label" size="small" error={error}>
        {label}
      </InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={checkedValues}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(checkedValues) => {
          const checkedOptionTexts = checkedValues.reduce((accumulator, currentCheckedValue) => {
            const index = optionValues.indexOf(currentCheckedValue);
            accumulator.push(optionTexts[index]);
            return accumulator;
          }, []);

          return checkedOptionTexts.join(', ');
        }}
        MenuProps={MenuProps}
        size="small"
        label={label}
        disabled={disabled}
        error={error}
      >
        {optionValues.map((optionValue, index) => {
          const isChecked = checkedValues.indexOf(optionValue) > -1;
          return (
            <MenuItem key={index} value={optionValue}>
              <Checkbox checked={isChecked} />
              <ListItemText primary={optionTexts[index]} />
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText error={error}>{helperText as string}</FormHelperText>}
    </FormControl>
  );
};
