import { ReactNode, useState, FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface ICustomTabsProps {
  tabTitles: string[];
  tabsContent: FC[];
  sx?: Record<string, string | number>;
}

export const CustomTabs: FC<ICustomTabsProps> = ({ tabTitles, tabsContent }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {tabTitles.map((tabTitle, index) => {
            return <Tab key={index} label={tabTitle} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      <Box
        sx={{ display: 'flex', width: '100%', minHeight: 'calc(100% - 48px)', overflow: 'auto' }}
      >
        {tabsContent.map((TabComponent, index) => {
          return (
            <TabPanel key={index} value={value} index={index}>
              <TabComponent />
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};
