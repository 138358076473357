import { FC, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { PasswordTextField } from '../PasswordTextField';
import { StyledInputLabel } from '../StyledInputLabel';

export const PasswordTextFieldControl: FC<OutlinedInputProps> = (props) => {
  const { label, error } = props;

  return (
    <FormControl fullWidth variant="outlined">
      <StyledInputLabel color="secondary" error={error}>
        {label}
      </StyledInputLabel>
      <PasswordTextField
        {...props}
        error={error}
        label={label}
        aria-describedby="base-name-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
      />
    </FormControl>
  );
};
