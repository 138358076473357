import { colorRegExp, externalIdRegExp } from '@/shared/validations';
import {
  ESlideTextType,
  ISlide,
  ISlideBackground,
  ISlideClose,
  ISlideDescription,
  ISlideFooter,
  ISlideProgress,
  ISlideTitle,
  IStory,
  IStoryPreviewParamsBackground,
  IStoryPreviewParamsTitle,
  TSlideParam,
} from '../types';

export const isValidSlide = (slide: ISlide, isNewStory: boolean) => {
  if (isNewStory) {
    if (
      !slide.external_slide_id ||
      !slide.name ||
      !slide.number ||
      !slide.version ||
      !slide.type ||
      !slide.duration
    ) {
      return false;
    }
  } else {
    if (slide.isNewSlide) {
      if (
        !slide.external_slide_id ||
        !slide.name ||
        !slide.number ||
        !slide.version ||
        !slide.type ||
        !slide.duration
      ) {
        return false;
      }
    } else {
      if (
        !slide.id ||
        !slide.external_slide_id ||
        !slide.name ||
        !slide.number ||
        !slide.version ||
        !slide.story_id ||
        !slide.type ||
        !slide.duration
      ) {
        return false;
      }
    }
  }

  if (!externalIdRegExp.test(slide.external_slide_id)) {
    return false;
  }

  const slideParamTitleIndex = slide.slides.params.findIndex((slideParam: TSlideParam) => {
    return slideParam.name === 'title';
  });
  const slideParamDescriptionIndex = slide.slides.params.findIndex((slideParam: TSlideParam) => {
    return slideParam.name === 'description';
  });
  const slideParamFooterIndex = slide.slides.params.findIndex((slideParam: TSlideParam) => {
    return slideParam.name === 'footer';
  });
  const slideParamBackgroundIndex = slide.slides.params.findIndex((slideParam: TSlideParam) => {
    return slideParam.name === 'background';
  });
  const slideParamProgressIndex = slide.slides.params.findIndex((slideParam: TSlideParam) => {
    return slideParam.name === 'progress';
  });
  const slideParamCloseIndex = slide.slides.params.findIndex((slideParam: TSlideParam) => {
    return slideParam.name === 'close';
  });

  if (
    slideParamTitleIndex === -1 ||
    slideParamDescriptionIndex === -1 ||
    slideParamFooterIndex === -1 ||
    slideParamBackgroundIndex === -1 ||
    slideParamProgressIndex === -1 ||
    slideParamCloseIndex === -1
  ) {
    return false;
  }

  const slideTitle = slide.slides.params[slideParamTitleIndex];
  const slideDescription = slide.slides.params[slideParamDescriptionIndex];
  const slideFooter = slide.slides.params[slideParamFooterIndex];
  const slideBackground = slide.slides.params[slideParamBackgroundIndex];
  const slideProgress = slide.slides.params[slideParamProgressIndex];
  const slideClose = slide.slides.params[slideParamCloseIndex];

  if (
    ((slideTitle as ISlideTitle).type !== ESlideTextType.text &&
      (slideTitle as ISlideTitle).type !== ESlideTextType.header) ||
    !(slideTitle as ISlideTitle).text_color ||
    !colorRegExp.test((slideTitle as ISlideTitle).text_color) ||
    !(slideTitle as ISlideTitle).value
  ) {
    return false;
  }

  if (
    ((slideDescription as ISlideDescription).type !== ESlideTextType.text &&
      (slideDescription as ISlideDescription).type !== ESlideTextType.header) ||
    !(slideDescription as ISlideDescription).text_color ||
    !colorRegExp.test((slideDescription as ISlideDescription).text_color) ||
    !(slideDescription as ISlideDescription).value
  ) {
    return false;
  }

  if (
    (slideFooter as ISlideFooter).type !== 'button' ||
    !(slideFooter as ISlideFooter).value ||
    !(slideFooter as ISlideFooter).text_color ||
    !colorRegExp.test((slideFooter as ISlideFooter).text_color) ||
    !(slideFooter as ISlideFooter).background_color ||
    !colorRegExp.test((slideFooter as ISlideFooter).background_color) ||
    !(slideFooter as ISlideFooter).on_click.action_type ||
    !(slideFooter as ISlideFooter).on_click.action_value
  ) {
    return false;
  }

  if (
    (slideBackground as ISlideBackground).type !== 'image_url' ||
    !(slideBackground as ISlideBackground).value
  ) {
    return false;
  }

  if (
    (slideProgress as ISlideProgress).type !== 'bar' ||
    !(slideProgress as ISlideProgress).background_color ||
    !colorRegExp.test((slideProgress as ISlideProgress).background_color)
  ) {
    return false;
  }

  if (
    (slideClose as ISlideClose).type !== 'cross_button' ||
    !(slideClose as ISlideClose).background_color ||
    !colorRegExp.test((slideClose as ISlideClose).background_color)
  ) {
    return false;
  }

  return true;
};

export const isValidStory = (story: IStory) => {
  if (story.isNewStory) {
    if (!story.external_story_id || !story.name || !story.type) {
      return false;
    }
  } else {
    if (!story.external_story_id || !story.name || !story.type || !story.id || !story.version) {
      return false;
    }
  }

  if (!externalIdRegExp.test(story.external_story_id)) {
    return false;
  }

  const previewParamsTitleIndex = story.preview.params.findIndex(
    (preveiwParam: IStoryPreviewParamsBackground | IStoryPreviewParamsTitle) => {
      return preveiwParam.name === 'title';
    }
  );

  const previewParamsBackgroundIndex = story.preview.params.findIndex(
    (preveiwParam: IStoryPreviewParamsBackground | IStoryPreviewParamsTitle) => {
      return preveiwParam.name === 'background';
    }
  );

  if (previewParamsTitleIndex === -1 || previewParamsBackgroundIndex === -1) {
    return false;
  }

  const previewParamsTitle = story.preview.params[previewParamsTitleIndex];
  const previewParamsBackground = story.preview.params[previewParamsBackgroundIndex];

  if (
    !previewParamsTitle.value ||
    !(previewParamsTitle as IStoryPreviewParamsTitle).text_color ||
    !colorRegExp.test((previewParamsTitle as IStoryPreviewParamsTitle).text_color) ||
    ((previewParamsTitle as IStoryPreviewParamsTitle).type !== ESlideTextType.text &&
      (previewParamsTitle as IStoryPreviewParamsTitle).type !== ESlideTextType.header) ||
    !previewParamsBackground.value ||
    previewParamsBackground.type !== 'image_url'
  ) {
    return false;
  }

  if (story.slides.length === 0) {
    return false;
  }

  for (let i = 0; i < story.slides.length; i++) {
    const slide = story.slides[i];
    if (!isValidSlide(slide, story.isNewStory)) {
      return false;
    }
  }

  return true;
};
