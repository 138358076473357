import { styled } from '@mui/system';

export const MainWrapper = styled('div')(({ theme }) => ({
  'min-width': '100vw',
  'min-height': '100vh',
  'background-color': '#32373f',
  [theme.breakpoints.up('sm')]: {
    'padding-top': '100px',
    'padding-bottom': '100px',
  },
}));
