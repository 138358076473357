import { IPromotionCollectionListItem } from '../types';

export const transformPromotionCollectionIdsBeforeSave = (
  promotionCollections: IPromotionCollectionListItem[]
) => {
  const collectionList = promotionCollections.map((promotionCollection) => {
    return {
      id: promotionCollection.id,
    };
  });

  return {
    data: {
      collection_list: collectionList,
    },
  };
};
