import isEqual from 'lodash.isequal';

import { IClientSettings, INewStaticCodesItem } from '../types';

export interface IIsClientSettingsTouchedProps {
  clientSettingsInitial: IClientSettings;
  clientSettings: IClientSettings;
  staticCodesItemsInitial: INewStaticCodesItem[];
  staticCodesItems: INewStaticCodesItem[];
}

export const isClientSettingsTouched = ({
  clientSettings,
  clientSettingsInitial,
  staticCodesItemsInitial,
  staticCodesItems,
}: IIsClientSettingsTouchedProps) => {
  return (
    !isEqual(clientSettings, clientSettingsInitial) ||
    !isEqual(staticCodesItems, staticCodesItemsInitial)
  );
};
