import styled from 'styled-components';

export const AddStoryWrapper = styled.div<{
  $isAddStoryDisabled: boolean;
  $isBlockAddingStory: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 45px;
  height: 45px;
  max-width: 45px;
  max-height: 45px;
  cursor: ${({ $isAddStoryDisabled }) => ($isAddStoryDisabled ? 'default' : 'pointer')};
  background-color: ${({ theme, $isAddStoryDisabled }) =>
    $isAddStoryDisabled
      ? theme.colors.background.module.disabled
      : theme.colors.background.module.main};
  padding: 10px;
  border-radius: 50%;
  transition: ${({ $isAddStoryDisabled }) =>
    $isAddStoryDisabled ? '' : 'background-color 0.2s linear'};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px 0px;
  user-select: none;
  pointer-events: ${({ $isBlockAddingStory }) => ($isBlockAddingStory ? 'none' : '')}

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.module.hover};
  }
`;
