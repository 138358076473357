import { FC } from 'react';

import { DelimiterWrapper, HorizontalLine } from './elements';

export const Delimiter: FC = () => {
  return (
    <DelimiterWrapper>
      <HorizontalLine />
    </DelimiterWrapper>
  );
};
