import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StoriesContainer } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { Sortable } from '@/shared/components/Sortable';
import { setScenarios } from '../redux/stories.slice';
import { Card } from './components/Card';
import { IScenario } from '../types';

import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebars/redux/services.slice';
import { fetchScenarios } from '../redux/stories.actions';
import { IModuleRecord } from '@/features/sidebars/types';

const Scenarios = () => {
  const dispatch = useAppDispatch();
  const modules = useSelector((state: TRootState) => state.services.modules);
  const scenarios = useSelector((state: TRootState) => state.stories.scenarios);
  const middleMenuOptions = useSelector((state: TRootState) => state.services.middleMenuOptions);

  const updateScenarios = (nextScenarios: IScenario[]) => {
    if (nextScenarios.length !== 0) {
      dispatch(setScenarios(nextScenarios));
    }
  };

  useEffect(() => {
    dispatch(fetchScenarios());
  }, []);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(modules));
  }, [modules]);

  useEffect(() => {
    if (!!middleMenuOptions && middleMenuOptions.length > 0) {
      dispatch(setMiddleMenuActiveBtn((middleMenuOptions[0] as IModuleRecord).external_module_id));
    }
  }, [middleMenuOptions]);

  return (
    <StoriesContainer>
      <Sortable
        idField="external_scenario_id"
        updateCards={updateScenarios}
        cards={scenarios}
        cardComponent={Card}
      ></Sortable>
    </StoriesContainer>
  );
};

export default Scenarios;
