import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import { AppContainer } from '@/app/elements';
import { Router } from '@/app/Router';
import { muiLightTheme, muiDarkTheme } from '@/app/themes/mui-theme';
import { TRootState, useAppDispatch } from './store';
import { EMuiThemeMode } from './app/types';
import { getServices } from './features/sidebars/redux/services.actions';
import { setDwpOnly } from './app/redux/appGeneral.slice';

function App() {
  const dispatch = useAppDispatch();
  const muiThemeMode = useSelector((state: TRootState) => state.appGeneral.muiThemeMode);

  useEffect(() => {
    dispatch(getServices())
      .unwrap()
      .then((payload) => {
        const services = payload?.data?.services;
        const serviceWithDwpOnlyTruthyFound = services?.find((service) => {
          return service.dwp_only;
        });

        dispatch(setDwpOnly(!!serviceWithDwpOnlyTruthyFound));
      });
  }, []);

  return (
    <>
      <ThemeProvider theme={muiThemeMode === EMuiThemeMode.dark ? muiDarkTheme : muiLightTheme}>
        <AppContainer>
          <Router />
        </AppContainer>
      </ThemeProvider>
    </>
  );
}

export default App;
