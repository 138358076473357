import React, { FC } from 'react';
import { BackButtonWrapper, BackBtnIcon } from './elements';

export interface IBackButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const BackButton: FC<IBackButtonProps> = ({ onClick }) => {
  return (
    <BackButtonWrapper onClick={onClick}>
      <BackBtnIcon>arrow_back</BackBtnIcon>
    </BackButtonWrapper>
  );
};
