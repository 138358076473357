import { FC, PropsWithChildren } from 'react';
import { Text, Wrapper } from './elements';
import { Title } from './elements';
import { SxProps } from '@mui/material';

export interface ITextFieldGroupProps {
  marginBottom?: string;
  marginRight?: string;
  width?: string;
  title?: string;
  sx?: SxProps;
  disabled?: boolean;
}

export const TextFieldGroup: FC<PropsWithChildren<ITextFieldGroupProps>> = ({
  marginBottom,
  marginRight,
  width,
  title,
  sx,
  children,
  disabled,
}) => {
  return (
    <Wrapper
      aria-hidden="true"
      $marginBottom={marginBottom}
      $marginRight={marginRight}
      $width={width}
      sx={{ ...sx }}
      disabled={disabled}
    >
      {title && (
        <Title>
          <Text $disabled={disabled}>{title}</Text>
        </Title>
      )}
      {children}
    </Wrapper>
  );
};
