/// <reference types="vite-plugin-svgr/client" />

import monitoringIcon from '@/assets/icons/dispatchers-workplace/monitoring.svg?react';
import ordersIcon from '@/assets/icons/dispatchers-workplace/orders.svg?react';
import polygonIcon from '@/assets/icons/dispatchers-workplace/polygon.svg?react';
import cartIcon from '@/assets/icons/dispatchers-workplace/cart.svg?react';
import deliveryIcon from '@/assets/icons/dispatchers-workplace/delivery.svg?react';
import exitIcon from '@/assets/icons/dispatchers-workplace/exit.svg?react';

import SalepointInfoListDashboard from '../../salepoint-info-list-dashboard/SalepointInfoListDashboard';
import { EmptyComponent } from '../components/EmptyComponent';

export const topMenuItems = [
  {
    title: 'Мониторинг',
    icon: monitoringIcon,
    link: 'page/monitoring',
    page: 'monitoring',
    component: SalepointInfoListDashboard,
  },
  {
    title: 'Заказы',
    icon: ordersIcon,
    link: 'page/orders',
    page: 'orders',
    component: EmptyComponent,
  },
  {
    title: 'Полигоны',
    icon: polygonIcon,
    link: 'page/polygons',
    page: 'polygons',
    component: EmptyComponent,
  },
];

export const bottomMenuItems = [
  {
    title: 'Сборщики',
    icon: cartIcon,
    link: 'page/collectors',
    page: 'collectors',
    component: EmptyComponent,
  },
  {
    title: 'Курьеры',
    icon: deliveryIcon,
    link: 'page/couriers',
    page: 'couriers',
    component: EmptyComponent,
  },
];

export const exitMenuItem = {
  title: 'Выход',
  icon: exitIcon,
};
