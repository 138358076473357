import cloneDeep from 'lodash.clonedeep';

import { INewStory, ISlide, IStory } from '../types';

export const tranformStoryBeforeSave = (activeStory: IStory) => {
  let resultStory;
  if (activeStory.isNewStory) {
    resultStory = cloneDeep(activeStory);
    if (resultStory.isNewStory) {
      const { id, isNewStory, isSavedStory, ...storyRest } = resultStory;
      resultStory = storyRest;
    }
    resultStory.slides = resultStory.slides.map((slide: ISlide) => {
      if (activeStory.isNewStory && slide.isNewSlide) {
        const { id, story_id, isNewSlide, ...slideRest } = slide;
        return { version: 1, ...slideRest };
      } else if (!activeStory.isNewStory && slide.isNewSlide) {
        const { id, isNewSlide, ...slideRest } = slide;
        return { version: 1, story_id: activeStory.id, ...slideRest };
      } else {
        return slide;
      }
    });
  } else {
    resultStory = activeStory;
  }

  return { data: { story: resultStory } };
};
