import cloneDeep from 'lodash.clonedeep';

import { IPromotionCollectionListItem } from '../types';

export interface IGetNewPromotionCollectionListProps {
  originalPromotionCollections: IPromotionCollectionListItem[];
  newPromotionCollectionListFiltered: IPromotionCollectionListItem[];
}

export const getNewPromotionCollectionList = ({
  originalPromotionCollections,
  newPromotionCollectionListFiltered,
}: IGetNewPromotionCollectionListProps) => {
  if (!originalPromotionCollections || !newPromotionCollectionListFiltered) {
    return [];
  }

  if (newPromotionCollectionListFiltered.length === 0) {
    return originalPromotionCollections;
  }

  const listItemIndexesChangedOrder: number[] = [];
  originalPromotionCollections.forEach(
    (originalPromotionCollectionItem, originalPromotionCollectionItemIndex) => {
      newPromotionCollectionListFiltered.find((newPromotionCollectionItem) => {
        if (originalPromotionCollectionItem.id === newPromotionCollectionItem.id) {
          listItemIndexesChangedOrder.push(originalPromotionCollectionItemIndex);
          return true;
        }
        return false;
      });
    }
  );

  if (listItemIndexesChangedOrder.length > 0) {
    const originalPromotionCollectionsClone = cloneDeep(originalPromotionCollections);
    const newPromotionCollectionListFilteredClone = cloneDeep(newPromotionCollectionListFiltered);
    listItemIndexesChangedOrder.forEach((originalPromotionCollectionItemIndex, index) => {
      originalPromotionCollectionsClone[originalPromotionCollectionItemIndex] =
        newPromotionCollectionListFilteredClone[index];
    });

    return originalPromotionCollectionsClone;
  }

  return originalPromotionCollections;
};
