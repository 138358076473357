export const getPrevUri = (uri: string) => {
  const regExp = /\/([%:_\-\w]+)/g;
  const urlSections = uri.match(regExp);

  if (urlSections.length > 2) {
    urlSections.splice(urlSections.length - 2, 2);
    return urlSections.join('');
  } else {
    return '/';
  }
};
