import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';
import {
  ENotificationActionType,
  IFetchNotificationTaskListResponse,
  IFetchNotificationTaskResponse,
  INotificationTask,
  INotificationTaskListItem,
  ISaveNotificationTaskResponse,
} from '../types';
import { TRootState } from '@/store';
import { transformNotificationDataBeforeSave } from '../utils';

export const fetchNotificationTaskList = createAsyncThunk<
  INotificationTaskListItem[],
  void,
  unknown
>('notificationTasks/fetchNotificationTaskList', async () => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchNotificationTaskListResponse>(
    QUERY_ENDPOINTS.notificationTasks.notificationTaskList.replace('[userId]', userId)
  );
  return response?.data?.data?.tasks;
});

export const fetchNotificationTask = createAsyncThunk<INotificationTask, string, unknown>(
  'notificationTasks/fetchNotificationTask',
  async (taskId) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchNotificationTaskResponse>(
      QUERY_ENDPOINTS.notificationTasks.notificationTask
        .replace('[userId]', userId)
        .replace('[taskId]', taskId)
    );
    return response?.data?.data?.task;
  }
);

export interface ISaveNotificationTaskProps {
  actionType: ENotificationActionType;
  isNewNotification: boolean;
  taskId?: string;
}

export const saveNotificationTask = createAsyncThunk<
  ISaveNotificationTaskResponse['data'],
  ISaveNotificationTaskProps,
  unknown
>(
  'notificationTasks/saveNotificationTask',
  async ({ actionType, isNewNotification, taskId }, { getState }) => {
    const userId = Cookies.get('userId') || '-1';
    const xIdempotencyId = uuidv4();
    const state = getState() as TRootState;
    let task;
    let endpointUrl;
    if (isNewNotification) {
      endpointUrl = MUTATION_ENDPOINTS.notificationTasks.saveNewNotification
        .replace('[userId]', userId)
        .replace('[action]', actionType);
      task = state.notificationTasks.newTask;
    } else {
      const taskListItem = state.notificationTasks.tasks.find((task) => {
        return task.id === Number(taskId);
      });
      endpointUrl = MUTATION_ENDPOINTS.notificationTasks.saveExistingNotification
        .replace('[userId]', userId)
        .replace('[action]', actionType)
        .replace('[taskId]', taskId)
        .replace('[version]', taskListItem?.version.toString());

      task = state.notificationTasks.task;
    }
    const data = transformNotificationDataBeforeSave(task);
    const response = await axiosInstance.post<ISaveNotificationTaskResponse>(endpointUrl, data, {
      headers: {
        'X-Idempotency-Id': xIdempotencyId,
      },
    });
    return response?.data?.data;
  }
);
