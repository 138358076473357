import { IPromotionItem } from '../types';

interface IIsValidPromotionForPublicationProps {
  promotion: IPromotionItem;
  isNew: boolean;
}

export const isValidPromotionForPublication = ({
  promotion,
  isNew,
}: IIsValidPromotionForPublicationProps) => {
  if (!promotion || isNew === undefined) {
    return false;
  }

  if (!isNew) {
    if (!promotion.id || !promotion.version) {
      return false;
    }
  }

  if (!promotion.name) {
    return false;
  }

  if (!promotion.type || !promotion.type.id) {
    return false;
  }

  if (!promotion.categories || promotion.categories.length === 0) {
    return false;
  }

  let categoriesValid = true;
  promotion.categories.find((category) => {
    if (!category.id) {
      categoriesValid = false;
      return true;
    }
  });

  if (!categoriesValid) {
    return false;
  }

  if (!promotion.list_image) {
    return false;
  }

  if (!promotion.full_image) {
    return false;
  }

  if (!promotion.description) {
    return false;
  }

  if (!promotion.condition) {
    return false;
  }

  if (!promotion.requirements) {
    return false;
  }

  if (
    !promotion.button ||
    !promotion.button.params ||
    !promotion.button.params.background_color ||
    !promotion.button.params.name ||
    !promotion.button.params.text_color ||
    !promotion.button.params.type ||
    !promotion.button.params.value ||
    !promotion.button.params.on_click.action_type ||
    !promotion.button.params.on_click.action_value
  ) {
    return false;
  }

  if (!promotion.start_datetime) {
    return false;
  }

  if (!promotion.finish_datetime) {
    return false;
  }

  if (!promotion.public_datetime) {
    return false;
  }

  return true;
};
