export interface IBannerScenarioItem {
  id: number;
  version: number;
  key: string;
  name: string;
}

export interface IFetchBannerScenariosResponse {
  data: {
    scenarios: IBannerScenarioItem[];
  };
}

export interface IBannerListItem {
  id: number;
  name: string;
  title: string;
  image_link: string;
  is_active: boolean;
  version: number;
}

export interface IFetchBannerListResponse {
  data: {
    banners: IBannerListItem[];
  };
}

export enum EBannerType {
  banner = 'banner',
}

export interface ISaveBannerResponse {
  data: {
    id: number;
    version: number;
    type: EBannerType;
  };
  status: 'ok';
}

export enum EBannerDeepLinkType {
  deepLink = 'deep_link',
  browser = 'browser',
  webview = 'webview',
  share = 'share',
  close = 'close',
}

export interface IBannerTitle {
  value: string;
  text_color: string;
}

export interface IBannerText {
  value: string;
  text_color: string;
}

export enum EBannerFooterType {
  button = 'button',
}

export interface IBannerFooter {
  type: EBannerFooterType;
  value: string;
  text_color: string;
  background_color: string;
}

export interface IBannerDeepLink {
  type: EBannerDeepLinkType | '';
  value: string;
}

export interface IBanner {
  id?: number;
  version?: number;
  external_id: string;
  name: string;
  title: IBannerTitle;
  text: IBannerText;
  footer: IBannerFooter;
  right_image_url: string;
  deep_link: IBannerDeepLink;
}

export interface ISaveBannerListResponse {
  status: 'ok';
}

export interface IFetchBannerResponse {
  data: {
    banner: IBanner;
  };
}

export enum EBannerActionType {
  deepLink = 'deep_link',
  browser = 'browser',
  webview = 'webview',
  share = 'share',
  close = 'close',
}
