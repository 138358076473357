import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { QUERY_ENDPOINTS } from '@/shared/lib/const';
import { IServicesReponse } from '../types';

export const getServices = createAsyncThunk<IServicesReponse, void, unknown>(
  'sidebar/getServices',
  async () => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IServicesReponse>(
      QUERY_ENDPOINTS.services.replace('[userId]', userId)
    );
    return response.data;
  }
);
