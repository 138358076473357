import {
  EClientSettingsFieldKeys,
  IClientSettings,
  INewStaticCodesItem,
  IClientSettingsFieldItem,
} from '../types';

export interface IIsClientSettingsValidProps {
  clientSettings: IClientSettings;
  staticCodesItems: INewStaticCodesItem[];
}

export const isClientSettingsValid = ({
  clientSettings,
  staticCodesItems,
}: IIsClientSettingsValidProps) => {
  const clientSettingsFieldWithEmptyValue = clientSettings?.fields.find((field) => {
    if (field.key === EClientSettingsFieldKeys.purchaseHistoryFilters) {
      const purchaseHistoryFiltersEntryWithEmptyValue = field.fields.find(
        (purchaseHistoryFiltersField: IClientSettingsFieldItem) => {
          return purchaseHistoryFiltersField.value === '';
        }
      );

      if (purchaseHistoryFiltersEntryWithEmptyValue) {
        return true;
      }
    }

    if (
      field.key === EClientSettingsFieldKeys.partnerId ||
      field.key === EClientSettingsFieldKeys.supportEmail ||
      field.key === EClientSettingsFieldKeys.supportPhone
    ) {
      if (field.value === '') {
        return true;
      }
    }

    return false;
  });

  if (clientSettingsFieldWithEmptyValue) {
    return false;
  }

  if (!!staticCodesItems && staticCodesItems.length > 0) {
    const staticCodesEntryWithEmptyField = staticCodesItems.find((staticCodesEntry) => {
      return staticCodesEntry.staticCodeName === '' || staticCodesEntry.staticCodeValue === '';
    });

    if (staticCodesEntryWithEmptyField) {
      return false;
    }
  }

  return true;
};
