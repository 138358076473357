import isEqual from 'lodash.isequal';

import { IBannerListItem } from '../types';

export interface IIBannerListTouchedProps {
  bannerList: IBannerListItem[];
  bannerListInitial: IBannerListItem[];
}

export const isBannerListTouched = ({
  bannerList,
  bannerListInitial,
}: IIBannerListTouchedProps) => {
  return !isEqual(bannerList, bannerListInitial);
};
