import styled from 'styled-components';

export const Close = styled.div.attrs(() => ({
  className: 'material-symbols-rounded',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 21px;
  color: #fff;
`;
