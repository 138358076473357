import cloneDeep from 'lodash.clonedeep';

import { IPromotionItem } from '../types';
import { convertDateStringToAPIFormat } from './convertDateStringToAPIFormat';

export const transformPromotionDataBeforeSave = (promotion: IPromotionItem) => {
  const resultPromotion = cloneDeep(promotion);
  if (!!resultPromotion.start_datetime) {
    resultPromotion.start_datetime = convertDateStringToAPIFormat(resultPromotion.start_datetime);
  }
  if (!!resultPromotion.finish_datetime) {
    resultPromotion.finish_datetime = convertDateStringToAPIFormat(resultPromotion.finish_datetime);
  }
  if (!!resultPromotion.public_datetime) {
    resultPromotion.public_datetime = convertDateStringToAPIFormat(resultPromotion.public_datetime);
  }

  return {
    data: {
      promotion: resultPromotion,
    },
  };
};
