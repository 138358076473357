import { FC } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { ruRU } from '@mui/x-date-pickers/locales';
import { styled } from 'styled-components';

interface CustomDatePickerProps {
  label: string;
  sx?: Record<string, string | number>;
  value?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (value: string) => void;
}

const CustomDatePickerWrapper = styled.div`
  position: relative;
  top: -8px;
`;

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  label,
  sx,
  value,
  error,
  helperText,
  onChange,
}) => {
  const handleChangeWrapper = (date: Dayjs) => {
    if (onChange) {
      let dateString;
      if (!date || date.toString() === 'Invalid date') {
        dateString = null;
      } else {
        dateString = date.format();
      }
      onChange(dateString);
    }
  };
  return (
    <CustomDatePickerWrapper>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="ru"
        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DemoContainer sx={{ width: '100%', ...sx }} components={['DatePicker']}>
          <DatePicker
            sx={{ width: '100%' }}
            label={label}
            value={!value ? null : dayjs(value)}
            onChange={handleChangeWrapper}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                error,
                helperText,
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </CustomDatePickerWrapper>
  );
};
