import styled from 'styled-components';

export const Description = styled.div<{ $color: string }>`
  text-wrap: wrap;
  white-space: pre-wrap;
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 24px;
  color: ${({ $color }) => $color};
`;
