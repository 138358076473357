import { TTimerState } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';
import styled from 'styled-components';

export const Time = styled.div<{ $timerState: TTimerState }>`
  background-color: ${({ theme, $timerState }) => {
    const color =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.time.background[$timerState];

    return color;
  }};
  color: ${({ theme, $timerState }) => {
    const color =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.time.content[$timerState];

    return color;
  }};
  font-size: 12px;
  border-radius: 8px;
  padding: 2px 8px 2px 8px;
  margin-left: 12px;
  white-space: nowrap;
`;
