import { ChangeEvent, KeyboardEvent, useState, useEffect, FC, FocusEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';

import { TooltipContainer, TooltipImage, TooltipText, TooltipTextField } from './elements';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { TRootState, useAppDispatch } from '@/store';
import { IStoryPreviewParamsBackground, IStoryPreviewParamsTitle } from '@/features/stories/types';
import {
  setActiveStoryTooltipBackgroundImageValue,
  setActiveStoryTooltipTextColor,
  setActiveStoryTooltipTextValue,
} from '@/features/stories/redux/stories.slice';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import { ColorPickerWithTextField } from '@/shared/components/ColorPickerWithTextField';
import { IStoryFormikValues } from '../..';
import { getStoryTooltipData } from '@/features/stories/utils';

interface ITooltip {
  formik: FormikProps<IStoryFormikValues>;
}

export const Tooltip: FC<ITooltip> = ({ formik }) => {
  const dispatch = useAppDispatch();
  const { scenario, story: externalStoryId } = useParams();
  const storyPreview = useSelector((state: TRootState) => state.stories.story?.preview);
  const [tooltipImageUrl, setTooltipImageUrl] = useState('');
  const [tooltipTextValue, setTooltipTextValue] = useState('');
  const [tooltipTextColor, setTooltipTextColor] = useState('');

  useEffect(() => {
    if (storyPreview) {
      const { backgroundValue, titleValue, titleTextColor } = getStoryTooltipData(storyPreview);

      formik.setFieldValue('tooltipImageUrl', backgroundValue);
      formik.setFieldValue('tooltipTitleTextValue', titleValue);
      formik.setFieldValue('tooltipTitleTextColor', titleTextColor);
    }
  }, [storyPreview]);

  useEffect(() => {
    if (storyPreview && !tooltipImageUrl && !tooltipTextValue && !tooltipTextColor) {
      const { backgroundValue, titleValue, titleTextColor } = getStoryTooltipData(storyPreview);

      setTooltipImageUrl(backgroundValue);
      setTooltipTextValue(titleValue);
      setTooltipTextColor(titleTextColor);
    }
  }, [storyPreview]);

  const handleTooltipImageUrlInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('tooltipImageUrl', event.target.value);
    dispatch(
      setActiveStoryTooltipBackgroundImageValue({
        value: event.target.value,
        scenario,
        externalStoryId,
      })
    );
  };
  const handleTooltipImageUrlInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    dispatch(
      setActiveStoryTooltipBackgroundImageValue({
        value: formik.values.tooltipImageUrl,
        scenario,
        externalStoryId,
      })
    );
    setTooltipImageUrl(formik.values.tooltipImageUrl);
  };
  const handleTooltipImageUrlInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveStoryTooltipBackgroundImageValue({
          value: formik.values.tooltipImageUrl,
          scenario,
          externalStoryId,
        })
      );
      setTooltipImageUrl(formik.values.tooltipImageUrl);
    }
  };
  const handleTooltipImageUpload = (url: string) => {
    dispatch(
      setActiveStoryTooltipBackgroundImageValue({
        value: url,
        scenario,
        externalStoryId,
      })
    );
    setTooltipImageUrl(url);
  };

  const handleTooltipTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('tooltipTitleTextValue', event.target.value);
    dispatch(
      setActiveStoryTooltipTextValue({
        value: event.target.value,
        scenario,
        externalStoryId,
      })
    );
  };
  const handleTooltipTextInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    dispatch(
      setActiveStoryTooltipTextValue({
        value: formik.values.tooltipTitleTextValue,
        scenario,
        externalStoryId,
      })
    );
    setTooltipTextValue(formik.values.tooltipTitleTextValue);
  };
  const handleTooltipTextInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveStoryTooltipTextValue({
          value: formik.values.tooltipTitleTextValue,
          scenario,
          externalStoryId,
        })
      );
      setTooltipTextValue(formik.values.tooltipTitleTextValue);
    }
  };
  const handleTooltipTextColorInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('tooltipTitleTextColor', event.target.value);
    dispatch(
      setActiveStoryTooltipTextColor({
        value: event.target.value,
        scenario,
        externalStoryId,
      })
    );
  };

  const handleTooltipTextColorInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    dispatch(
      setActiveStoryTooltipTextColor({
        value: formik.values.tooltipTitleTextColor,
        scenario,
        externalStoryId,
      })
    );
    setTooltipTextColor(formik.values.tooltipTitleTextColor);
  };
  const handleTooltipTextColorInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveStoryTooltipTextColor({
          value: formik.values.tooltipTitleTextColor,
          scenario,
          externalStoryId,
        })
      );
      setTooltipTextColor(formik.values.tooltipTitleTextColor);
    }
  };
  const handleColorChange = (color: string) => {
    dispatch(setActiveStoryTooltipTextColor({ value: color, scenario, externalStoryId }));
    setTooltipTextColor(color);
  };

  return (
    <TooltipContainer>
      <TextFieldGroup marginRight="10px" width="217px" title="Тултип">
        <UploadFileWithTextField
          label="Ссылка на картинку"
          type="text"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.tooltipImageUrl}
          name="tooltipImageUrl"
          onChange={handleTooltipImageUrlInputChange}
          onBlur={handleTooltipImageUrlInputBlur}
          onKeyUp={handleTooltipImageUrlInputKeyUp}
          handleFileUpload={handleTooltipImageUpload}
          error={!!formik.touched.tooltipImageUrl && !!formik.errors.tooltipImageUrl}
          helperText={!!formik.touched.tooltipImageUrl && formik.errors.tooltipImageUrl}
        />
        <TooltipTextField
          label="Значение текста"
          type="text"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.tooltipTitleTextValue}
          name="tooltipTitleTextValue"
          onChange={handleTooltipTextInputChange}
          onBlur={handleTooltipTextInputBlur}
          onKeyUp={handleTooltipTextInputKeyUp}
          error={!!formik.touched.tooltipTitleTextValue && !!formik.errors.tooltipTitleTextValue}
          helperText={!!formik.touched.tooltipTitleTextValue && formik.errors.tooltipTitleTextValue}
        />
        <ColorPickerWithTextField
          label="Цвет текста"
          type="text"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mb: 0 }}
          value={formik.values.tooltipTitleTextColor}
          name="tooltipTitleTextColor"
          onInput={handleTooltipTextColorInputChange}
          onBlur={handleTooltipTextColorInputBlur}
          onKeyUp={handleTooltipTextColorInputKeyUp}
          handleColorChange={handleColorChange}
          error={!!formik.touched.tooltipTitleTextColor && !!formik.errors.tooltipTitleTextColor}
          helperText={!!formik.touched.tooltipTitleTextColor && formik.errors.tooltipTitleTextColor}
        />
      </TextFieldGroup>
      <TooltipImage $url={tooltipImageUrl}>
        <TooltipText $color={tooltipTextColor}>{tooltipTextValue}</TooltipText>
      </TooltipImage>
    </TooltipContainer>
  );
};
