import { Box } from '@mui/material';
import styled from 'styled-components';

export const TableHeadCellContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 10px 12px;
  background-color: white;
  border-bottom: none !important;
  user-select: none;
`;
