import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import { ERequestStatus } from '@/shared/lib/types';
import { fetchClientSettings, saveClientSettings } from './clientSettings.actions';
import { EClientSettingsFieldKeys, IClientSettings, INewStaticCodesItem } from '../types';
import { clientSettingsData } from '../stub/clientSettingsData';
import { transformClientSettingsBeforeSetToRedux } from '../utils';

interface IClientSettingsState {
  clientSettingsInitial: IClientSettings;
  clientSettings: IClientSettings;
  clientSettingsStatus: ERequestStatus;
  staticCodesItemsInitial: INewStaticCodesItem[];
  staticCodesItems: INewStaticCodesItem[];
}

// const { clientSettingsModified, staticCodesTransformed } =
//   transformClientSettingsBeforeSetToRedux(clientSettingsData);

const initialState: IClientSettingsState = {
  clientSettingsInitial: null,
  clientSettings: null,
  clientSettingsStatus: ERequestStatus.idle,
  staticCodesItemsInitial: [],
  staticCodesItems: [],
};

const clientSettingsSlice = createSlice({
  name: 'clientSettings',
  initialState,
  reducers: {
    setPurchaseHistoryFilterFieldValue: (state, action) => {
      const { key, value } = action.payload;

      const purchaseHistoryFiltersFieldFound = state.clientSettings?.fields.find((field) => {
        if (field.key === EClientSettingsFieldKeys.purchaseHistoryFilters) {
          return true;
        }
        return false;
      });

      if (purchaseHistoryFiltersFieldFound) {
        const fieldFound = purchaseHistoryFiltersFieldFound.fields.find((field) => {
          if (field.key === key) {
            return true;
          }

          return false;
        });

        fieldFound.value = value;
      }
    },
    setFieldValueBasic: (state, action) => {
      const { key, value } = action.payload;

      if (
        key !== EClientSettingsFieldKeys.filePath &&
        key !== EClientSettingsFieldKeys.purchaseHistoryFilters &&
        key !== EClientSettingsFieldKeys.staticCodes
      ) {
        const fieldFound = state.clientSettings?.fields.find((field) => {
          if (field.key === key) {
            return true;
          }
          return false;
        });

        if (fieldFound) {
          fieldFound.value = value;
        }
      }
    },
    setStaticCodesEntryKey: (state, action) => {
      const { value, index: indexToUpdate } = action.payload;
      state.staticCodesItems = state.staticCodesItems.map((staticCodeItem, index) => {
        if (indexToUpdate === index) {
          staticCodeItem.staticCodeName = value;
        }
        return staticCodeItem;
      });
    },
    setStaticCodesEntryValue: (state, action) => {
      const { value, index: indexToUpdate } = action.payload;
      state.staticCodesItems = state.staticCodesItems.map((staticCodeItem, index) => {
        if (indexToUpdate === index) {
          staticCodeItem.staticCodeValue = value;
        }
        return staticCodeItem;
      });
    },
    removeStaticCodesEntryByIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.staticCodesItems = state.staticCodesItems.filter((_, index) => {
        if (indexToRemove === index) {
          return false;
        }
        return true;
      });
    },
    addStaticCodesItem: (state) => {
      state.staticCodesItems.push({ staticCodeName: '', staticCodeValue: '' });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClientSettings.pending, (state) => {
        state.clientSettingsStatus = ERequestStatus.pending;
      })
      .addCase(fetchClientSettings.fulfilled, (state, action) => {
        const clientSettings = action.payload;
        const { clientSettingsModified, staticCodesTransformed } =
          transformClientSettingsBeforeSetToRedux(clientSettings);
        state.clientSettingsInitial = clientSettingsModified;
        state.clientSettings = cloneDeep(clientSettingsModified);
        state.staticCodesItemsInitial = staticCodesTransformed;
        state.staticCodesItems = cloneDeep(staticCodesTransformed);
        state.clientSettingsStatus = ERequestStatus.success;
      })
      .addCase(fetchClientSettings.rejected, (state) => {
        state.clientSettingsStatus = ERequestStatus.error;
      });

    builder.addCase(saveClientSettings.fulfilled, (state, action) => {
      const response = action.payload;
      state.clientSettings.version = response.version;
      state.clientSettingsInitial = cloneDeep(state.clientSettings);
    });
  },
});

export const {
  setPurchaseHistoryFilterFieldValue,
  setFieldValueBasic,
  setStaticCodesEntryKey,
  setStaticCodesEntryValue,
  removeStaticCodesEntryByIndex,
  addStaticCodesItem,
} = clientSettingsSlice.actions;

export default clientSettingsSlice.reducer;
