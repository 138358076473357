import { createSlice } from '@reduxjs/toolkit';

import { servicesData } from '@/features/sidebars/stub/servicesData';
import { IModuleRecord } from '@/features/sidebars/types';

interface IServicesState {
  modules: IModuleRecord[];
  disabled: boolean;
}

const initialState: IServicesState = {
  modules: [],
  disabled: false,
};

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setCurrentModules: (state, action) => {
      state.modules = action.payload;
      state.disabled = false;
    },
    setIsModuleDisabled: (state) => {
      state.disabled = true;
    },
  },
  extraReducers(builder) {},
});

export const { setCurrentModules, setIsModuleDisabled } = modulesSlice.actions;

export default modulesSlice.reducer;
