import styled from 'styled-components';

export const TooltipImg = styled.div<{ $url?: string }>`
  display: flex;
  align-items: end;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  font-size: 6px;
  background-image: ${({ $url }) => `url(${$url})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 9px;
  padding: 4px;
  overflow: hidden;
`;
