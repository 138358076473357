import { FC } from 'react';
import { OrderAcceptanceStateIndicator, OrderAcceptanceStateWrapper } from './elements';

interface IOrderAcceptanceStateProps {
  state: 'enabled' | 'disabled';
}

export const OrderAcceptanceState: FC<IOrderAcceptanceStateProps> = ({ state }) => {
  return (
    <OrderAcceptanceStateWrapper>
      <OrderAcceptanceStateIndicator $state={state} />
    </OrderAcceptanceStateWrapper>
  );
};
