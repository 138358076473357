import { FC } from 'react';

import { CustomSelect, CustomSelectProps } from '@/shared/components/CustomSelect';

export const CustomSelectDwp: FC<CustomSelectProps> = ({ sx, ...props }) => {
  return (
    <CustomSelect
      selectSize="medium"
      {...props}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& .MuiSelect-select': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
          '& .MuiSvgIcon-root': {
            color: 'rgba(0, 0, 0, 0.56)',
          },
        },
        ...sx,
      }}
      sxSelectMenu={{
        bgcolor: 'white',
        color: 'rgba(0, 0, 0, 0.7)',
      }}
    />
  );
};
