import styled from 'styled-components';

export const Scenario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px 0px;
  background-color: ${({ theme }) => theme.colors.background.module.main};
  font-weight: 600;
  font-size: 14px;
  min-width: 367px;
  padding: 10px 20px;
  margin-bottom: 10px;
`;
