import { IPromotionCollectionItem } from '../types';

export const transformPromotionCollectionItemBeforeSave = (
  promotionCollection: IPromotionCollectionItem
) => {
  return {
    data: {
      collection: promotionCollection,
    },
  };
};
