import {
  StandardTextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from '@mui/material/TextField';
import { InputAdornment, TextField } from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler } from 'react';

import { Symbol } from './elements';

export type TTextFieldProps =
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps;

const Search: FC = () => <Symbol>search</Symbol>;

export const SearchInput: FC<TTextFieldProps> = ({
  label,
  sx,
  onChange,
  onKeyUp,
  value,
  size = 'small',
  ...props
}) => {
  return (
    <TextField
      sx={{ flexGrow: 1, flexBasis: 0, ...sx }}
      label={label}
      type="search"
      size={size}
      InputProps={{
        startAdornment: <InputAdornment position="start" component={Search} />,
      }}
      onChange={onChange}
      onKeyUp={onKeyUp}
      value={value}
      {...props}
    />
  );
};
