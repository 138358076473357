import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';
import {
  EUploadFileType,
  IFetchStoryResponse,
  IFetchStorySequenceResponse,
  ISaveStoryResponse,
  ISaveStorySequenceResponse,
  IScenario,
  IScenariosReponse,
  IStory,
  IStorySequenceData,
  IUploadFileResponse,
} from '../types';
import {
  tranformFetchScenariosData,
  tranformStoryBeforeSave,
  tranformStorySequenceBeforeSave,
} from '../utils';
import { TRootState } from '@/store';

export const fetchScenarios = createAsyncThunk<IScenario[], void, unknown>(
  'stories/fetchScenarios',
  async () => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IScenariosReponse>(
      QUERY_ENDPOINTS.scenarios.replace('[userId]', userId)
    );
    return tranformFetchScenariosData(response?.data?.data?.scenarios);
  }
);

export const fetchStorySequence = createAsyncThunk<IStorySequenceData, string, unknown>(
  'stories/fetchStorySequence',
  async (scenario, { signal }) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchStorySequenceResponse>(
      QUERY_ENDPOINTS.stories.replace('[storyScenario]', scenario).replace('[userId]', userId),
      { signal }
    );
    return response?.data?.data;
  }
);

export interface IFetchStory {
  story: string;
}

export const fetchStory = createAsyncThunk<IStory, IFetchStory, unknown>(
  'stories/fetchStory',
  async ({ story: externalStoryId }, { signal }) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchStoryResponse>(
      QUERY_ENDPOINTS.story
        .replace('[externalStoryId]', externalStoryId)
        .replace('[userId]', userId),
      { signal }
    );
    return response?.data?.data?.story;
  }
);

export const fetchStoryPreview = createAsyncThunk<IStory, string, unknown>(
  'stories/fetchStoryPreview',
  async (externalStoryId) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchStoryResponse>(
      QUERY_ENDPOINTS.story
        .replace('[externalStoryId]', externalStoryId)
        .replace('[userId]', userId)
    );
    return response?.data?.data?.story;
  }
);

export interface ISaveStoryResult {
  scenario: string;
  id: number;
}

export const saveStory = createAsyncThunk<ISaveStoryResult, string, unknown>(
  'stories/saveStory',
  async (scenario, { getState }) => {
    const state = getState() as TRootState;
    const userId = Cookies.get('userId') || '-1';
    const data = tranformStoryBeforeSave(state.stories.story);
    const response = await axiosInstance.post<ISaveStoryResponse>(
      MUTATION_ENDPOINTS.saveStory.replace('[userId]', userId),
      data
    );
    return { scenario, id: response?.data?.data?.story?.id };
  }
);

export const saveStorySequence = createAsyncThunk<string, string, unknown>(
  'stories/saveStorySequence',
  async (storyScenario, { getState }) => {
    const state = getState() as TRootState;
    const userId = Cookies.get('userId') || '-1';
    const data = tranformStorySequenceBeforeSave({
      storySequence: state.stories.storySequence,
      storySequenceVersion: state.stories.storySequenceVersion,
      newStories: state.stories.newStories,
      storyScenario,
    });
    const response = await axiosInstance.post<ISaveStorySequenceResponse>(
      MUTATION_ENDPOINTS.storySequence.replace('[userId]', userId),
      data
    );
    return storyScenario;
  }
);

export interface IUploadFileProps {
  fileType: EUploadFileType;
  file: File;
}

export const uploadFile = createAsyncThunk<string, IUploadFileProps, unknown>(
  'stories/uploadFile',
  async ({ fileType, file }) => {
    const userId = Cookies.get('userId') || '-1';

    const uploadFileEndpoint =
      fileType === EUploadFileType.image
        ? MUTATION_ENDPOINTS.uploadImage
        : MUTATION_ENDPOINTS.uploadVideo;

    const data = new FormData();
    data.append('file', file);

    const response = await axiosInstance.post<IUploadFileResponse>(
      uploadFileEndpoint.replace('[userId]', userId),
      data
    );
    return response?.data?.data?.url;
  }
);
