import { FC } from 'react';
import { Amount, AmountAndTimeWrapper, Time } from './elements';
import { IAmountAndTime } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';

interface IAmountAndTimeProps {
  amountAndTime: IAmountAndTime;
}

export const AmountAndTime: FC<IAmountAndTimeProps> = ({ amountAndTime }) => {
  const { amount, time, timer_state } = amountAndTime;
  return (
    <AmountAndTimeWrapper>
      <Amount>{amount}</Amount>
      <Time $timerState={timer_state}>{time}</Time>
    </AmountAndTimeWrapper>
  );
};
