import { Button } from '@mui/material';
import { styled } from 'styled-components';

export const UploadFileButton = styled(Button)`
  padding: 5px !important;
  min-width: 20px !important;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey} !important;
  width: 37px;
  height: 37px;
  margin-top: 2px !important;

  &:hover {
    border-color: #fff !important;
  }
`;
