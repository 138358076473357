import { Scrollbars } from 'react-custom-scrollbars';

import { SidebarWrapper } from '../shared';
import { EmptySpace, LogoLink, SmartPlatformLogo } from './elements';
import { SidebarContent } from './elements';
import { MenuButton } from '@/features/sidebars/MainMenu/components/MenuButton';
import { ROUTES } from '@/shared/lib/const';
import { StyledLink } from './elements';

export const MainMenu = () => (
  <SidebarWrapper $withGradient>
    <Scrollbars>
      <SidebarContent>
        <LogoLink to="/">
          <SmartPlatformLogo />
        </LogoLink>
        <div>
          <MenuButton symbol="apps" text="Все сервисы" />
          <MenuButton symbol="search" text="Поиск" />
        </div>
        <EmptySpace />

        <div>
          <MenuButton symbol="email" text="Написать нам" />
          <StyledLink to={ROUTES.settings}>
            <MenuButton symbol="settings" text="Настройки" />
          </StyledLink>
          <MenuButton symbol="star" text="Избранное" />
          <MenuButton symbol="notifications" text="Уведомления" />
          <MenuButton symbol="help" text="Помощь" />
          <MenuButton symbol="account_circle" text="Учетная запись" />
        </div>
      </SidebarContent>
    </Scrollbars>
  </SidebarWrapper>
);
