import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { QUERY_ENDPOINTS } from '@/shared/lib/const';
import {
  EFetchSalepointInfoCursor,
  ESlalepointInfoListTableColumnArrow,
  IFetchSalepointCollectorListResponse,
  IFetchSalepointInfoFilterListResponse,
  IFetchSalepointInfoListData,
  IFetchSalepointInfoListResponse,
  IFetchSalepointInfoShopListResponse,
  ISalepointInfoFilterItem,
  ISalepointInfoShopGroupListItem,
} from '../types';
import { TRootState } from '@/store';
import { getSalepointInfoListTableSortingDirection } from '../utils';

interface IFetchSalepointInfoListParams {
  user_id: string;
  cursor?: number;
  filter?: number;
  shop_number?: string;
  search?: string;
  sorting?: string;
  sorting_direction?: string;
}

interface IFetchSalepointInfoListDataProps {
  cursor?: EFetchSalepointInfoCursor;
  cursorId?: number;
}

interface IFetchSalepointInfoListDataResult {
  data: IFetchSalepointInfoListData;
  isLazyLoading: boolean;
}

export const fetchSalepointInfoListData = createAsyncThunk<
  IFetchSalepointInfoListDataResult,
  IFetchSalepointInfoListDataProps | void,
  unknown
>('salepointInfoListDashboard/fetchSalepointInfoListData', async (props, { getState }) => {
  const { cursor, cursorId } = props || {};
  const userId = Cookies.get('userId') || '-1';
  const state = getState() as TRootState;
  const salepointInfoListDashboardState = state.dispatchersWorkplace.salepointInfoListDashboard;
  const {
    salepointInfoListData,
    selectedFilter,
    shopIdFilter,
    searchByShopAddress,
    selectedSorting,
    tableColumnArrow,
  } = salepointInfoListDashboardState;
  const params: IFetchSalepointInfoListParams = {
    user_id: userId,
  };

  if (cursor === EFetchSalepointInfoCursor.nextCursor) {
    if (cursorId) {
      params.cursor = cursorId;
    } else if (salepointInfoListData[cursor] !== undefined) {
      params.cursor = salepointInfoListData[cursor];
    }
  }

  if (selectedFilter !== null) {
    params.filter = selectedFilter.id;
  }

  if (shopIdFilter) {
    params.shop_number = shopIdFilter;
  }

  if (searchByShopAddress !== '') {
    params.search = searchByShopAddress;
  }

  if (selectedSorting !== null && tableColumnArrow !== ESlalepointInfoListTableColumnArrow.none) {
    params.sorting_direction = getSalepointInfoListTableSortingDirection({
      selectedSorting,
      tableColumnArrow,
    });
    params.sorting = selectedSorting;
  }

  const queryString = new URLSearchParams(params as unknown as Record<string, string>).toString();

  const apiUrl = queryString
    ? `${QUERY_ENDPOINTS.dispatchersWorkplace.salepointInfoList}?${queryString}`
    : QUERY_ENDPOINTS.dispatchersWorkplace.salepointInfoList;

  const response = await axiosInstance.get<IFetchSalepointInfoListResponse>(apiUrl);

  return {
    data: response?.data?.data,
    isLazyLoading: cursor && cursor === EFetchSalepointInfoCursor.nextCursor,
  };
});

interface IRefetchSalepointInfoListDataWithCursorProps {
  cursor?: EFetchSalepointInfoCursor;
  cursorId?: number;
}

interface IRefetchSalepointInfoListDataWithCursorResult {
  data: IFetchSalepointInfoListData;
}

export const refetchSalepointInfoListDataWithCursor = createAsyncThunk<
  IRefetchSalepointInfoListDataWithCursorResult,
  IRefetchSalepointInfoListDataWithCursorProps | void,
  unknown
>(
  'salepointInfoListDashboard/refetchSalepointInfoListDataWithCursor',
  async (props, { getState }) => {
    const { cursor, cursorId } = props || {};
    const userId = Cookies.get('userId') || '-1';
    const state = getState() as TRootState;
    const salepointInfoListDashboardState = state.dispatchersWorkplace.salepointInfoListDashboard;
    const { selectedFilter, shopIdFilter, searchByShopAddress, selectedSorting, tableColumnArrow } =
      salepointInfoListDashboardState;
    const params: IFetchSalepointInfoListParams = {
      user_id: userId,
    };

    if (cursor && cursorId) {
      params.cursor = cursorId;
    }

    if (selectedFilter !== null) {
      params.filter = selectedFilter.id;
    }

    if (shopIdFilter) {
      params.shop_number = shopIdFilter;
    }

    if (searchByShopAddress !== '') {
      params.search = searchByShopAddress;
    }

    if (selectedSorting !== null && tableColumnArrow !== ESlalepointInfoListTableColumnArrow.none) {
      params.sorting_direction = getSalepointInfoListTableSortingDirection({
        selectedSorting,
        tableColumnArrow,
      });
      params.sorting = selectedSorting;
    }

    const queryString = new URLSearchParams(params as unknown as Record<string, string>).toString();

    const apiUrl = queryString
      ? `${QUERY_ENDPOINTS.dispatchersWorkplace.salepointInfoList}?${queryString}`
      : QUERY_ENDPOINTS.dispatchersWorkplace.salepointInfoList;

    const response = await axiosInstance.get<IFetchSalepointInfoListResponse>(apiUrl);

    return { data: response?.data?.data };
  }
);

export const fetchSalepointInfoFilterList = createAsyncThunk<
  ISalepointInfoFilterItem[],
  void,
  unknown
>('salepointInfoListDashboard/fetchSalepointInfoFilterList', async () => {
  const userId = Cookies.get('userId') || '-1';

  const response = await axiosInstance.get<IFetchSalepointInfoFilterListResponse>(
    QUERY_ENDPOINTS.dispatchersWorkplace.filters.replace('[userId]', userId)
  );
  return response?.data?.data?.filters;
});

export const fetchSalepointInfoShopList = createAsyncThunk<
  ISalepointInfoShopGroupListItem[],
  void,
  unknown
>('salepointInfoListDashboard/fetchSalepointInfoShopList', async () => {
  const userId = Cookies.get('userId') || '-1';

  const response = await axiosInstance.get<IFetchSalepointInfoShopListResponse>(
    QUERY_ENDPOINTS.dispatchersWorkplace.shopList.replace('[userId]', userId)
  );
  return response?.data?.data?.shop_group_list;
});

interface IFetchSalepointCollectorListProps {
  id: number;
}

export const fetchSalepointCollectorList = createAsyncThunk<
  IFetchSalepointCollectorListResponse['data']['salepoint_collector_list'],
  IFetchSalepointCollectorListProps,
  unknown
>('salepointInfoListDashboard/fetchSalepointCollectorList', async ({ id: salepointId }) => {
  const userId = Cookies.get('userId') || '-1';

  const response = await axiosInstance.get<IFetchSalepointCollectorListResponse>(
    QUERY_ENDPOINTS.dispatchersWorkplace.salepointCollectorList
      .replace('[userId]', userId)
      .replace('[salepointId]', salepointId.toString())
  );
  return response?.data?.data?.salepoint_collector_list;
});
