import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PromotionCollection } from './PromotionCollection';
import { PromotionCollectionList } from './PromotionCollectionList';
import { EPromotionCollectionTabPages } from '../types';
import { TRootState } from '@/store';

export const PromotionCollectionTab = () => {
  const activePage = useSelector(
    (state: TRootState) => state.promotions.promotionCollectionActivePage
  );

  const renderedContent = useMemo(() => {
    if (activePage === EPromotionCollectionTabPages.promotionCollectionList) {
      return <PromotionCollectionList />;
    } else if (
      activePage === EPromotionCollectionTabPages.newPromotionCollection ||
      activePage === EPromotionCollectionTabPages.existingPromotionCollection
    ) {
      return <PromotionCollection />;
    }
    return null;
  }, [activePage]);

  return <>{renderedContent}</>;
};
