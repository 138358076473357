import { FC } from 'react';
import { MenuButtonProps } from './MenuButtonProps';
import { Symbol, MenuButtonWrapper, Text } from './elements';

export const MenuButton: FC<MenuButtonProps> = ({ symbol, text, ...rest }) => {
  return (
    <MenuButtonWrapper {...rest}>
      <Symbol>{symbol}</Symbol>
      <Text>{text}</Text>
    </MenuButtonWrapper>
  );
};
