import { ECarouselIndicators } from '@/features/stories/types';
import styled from 'styled-components';

export const Close = styled.div.attrs<{ $type: ECarouselIndicators }>(() => ({
  className: 'material-symbols-rounded',
}))`
  position: absolute;
  top: 0;
  right: -3px;
  font-size: 21px;
  color: ${({ theme, $type }) =>
    $type === ECarouselIndicators.light ? 'white' : theme.colors.storyViewIndicators.dark};
`;
