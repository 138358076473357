import styled from 'styled-components';

export const CarouselIndicatorShown = styled.div<{
  $duration: number;
  $progressColor: string;
}>`
  position: absolute;
  width: 0;
  height: 100%;
  background-color: ${({ $progressColor }) => $progressColor};

  &.active {
    width: 100%;
    animation: ${({ $duration }) => `progress ${$duration}s normal linear`};
  }

  @keyframes progress {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
`;
