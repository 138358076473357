import { FC } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from '@/store';
import { useParams } from 'react-router-dom';

import { ISlide } from '@/features/stories/types';
import { Sortable } from '@/shared/components/Sortable';
import { useAppDispatch } from '@/store';
import { setSlides } from '@/features/stories/redux/stories.slice';
import { Slide } from './components/Slide';
import { SlidesContainer } from './elements';
import { AddSlide } from './components/AddSlide';

export interface ISlidesProps {
  isShowFormErrors: boolean;
}

const Slides: FC<ISlidesProps> = ({ isShowFormErrors }) => {
  const dispatch = useAppDispatch();
  const { scenario, story } = useParams();
  const slides = useSelector((state: TRootState) => state.stories.story.slides);

  const updateSlides = (nextSlides: ISlide[]) => {
    if (nextSlides.length !== 0) {
      dispatch(setSlides({ slides: nextSlides, scenario, externalStoryId: story }));
    }
  };

  return (
    <SlidesContainer>
      <Sortable
        updateCards={updateSlides}
        cards={slides}
        cardComponent={Slide}
        cardParams={{ isShowFormErrors }}
      />
      <AddSlide />
    </SlidesContainer>
  );
};

export default Slides;
