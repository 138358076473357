import React, { FC, useState, useEffect, useRef } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import {
  StandardTextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from '@mui/material/TextField';

import {
  ColorBtnWrapper,
  ColorButton,
  ColorPickerMenu,
  ColorTextField,
  ColorWrapper,
} from './elements';
import { getHexFromRgba } from '@/features/stories/utils';
import { BooleanValue } from '@divkitframework/divkit/typings/typings/common.js';

type TTextFieldProps = StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps;

type TColorPickerWithTextFieldProps = {
  handleColorChange?: (color: string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
} & TTextFieldProps;

const COLOR_LENGTH_WITH_OPACITY = 9;

export const ColorPickerWithTextField: FC<TColorPickerWithTextFieldProps> = ({
  label,
  type,
  size,
  InputLabelProps,
  value,
  name,
  onInput: onInputProps,
  onBlur,
  onKeyUp,
  sx,
  handleColorChange,
  disabled,
  error,
  helperText,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [colorPickerColor, setColorPickerColor] = useState<any>(value || '');
  const [btnBgColor, setBtnBgColor] = useState<string>((value as string) || '');
  const [caretPositionState, setCaretPositionState] = useState(0);
  const textFieldRef = useRef<HTMLInputElement>();

  const onColorChange = (color: ColorResult) => {
    const rgbaColor = color.rgb;
    if (rgbaColor.a === undefined || rgbaColor.a === null || rgbaColor.a === 1) {
      setBtnBgColor(color.hex);
      handleColorChange?.(color.hex.toUpperCase());
    } else {
      const hexWithOpacityColor = getHexFromRgba(rgbaColor);
      setBtnBgColor(hexWithOpacityColor);
      handleColorChange?.(hexWithOpacityColor.toUpperCase());
    }
    setColorPickerColor(rgbaColor);
  };

  const handleTextFieldInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const elem = event.target;
    if (elem.setSelectionRange) {
      let caretPosition = elem.selectionStart;
      if (caretPosition === 1 && elem.value[0] === ' ') {
        caretPosition = 0;
      }
      elem.value = elem.value.trim();
      elem.setSelectionRange(caretPosition, caretPosition);
    } else {
      elem.value = elem.value.trim();
    }
    setCaretPositionState(elem.selectionStart);

    onInputProps(event);
  };

  useEffect(() => {
    setColorPickerColor(value);
    setBtnBgColor(value as string);

    if (textFieldRef.current) {
      const elem = textFieldRef.current.querySelector('input');

      if (value && (value as string).trim() !== value) {
        const event = new Event('input', { bubbles: true });
        elem?.dispatchEvent(event);
      }

      elem.setSelectionRange(caretPositionState, caretPositionState);
    }
  }, [value, caretPositionState]);

  return (
    <ColorWrapper sx={{ mb: '10px', ...sx }}>
      <ColorTextField
        label={label}
        type={type}
        size={size}
        InputLabelProps={InputLabelProps}
        value={value}
        name={name}
        onInput={handleTextFieldInput}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        ref={textFieldRef}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
      <div>
        <ColorBtnWrapper>
          <ColorButton
            id="color-picker-button"
            aria-controls={open ? 'color-picker-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            // @ts-ignore: Unreachable code error
            color="lightGrey"
            variant="outlined"
            component="button"
            $bgColor={btnBgColor}
            disabled={disabled}
          ></ColorButton>
        </ColorBtnWrapper>
      </div>

      <ColorPickerMenu
        id="color-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'color-picker-button',
        }}
      >
        <SketchPicker color={colorPickerColor} onChange={onColorChange} />
      </ColorPickerMenu>
    </ColorWrapper>
  );
};
