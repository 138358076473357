import { styled } from '@mui/system';

import { SecondaryButton } from '@/shared/components/SecondaryButton';

export const NewPromotionButton = styled(SecondaryButton)`
  padding-left: 16px;
  padding-right: 20px;

  & .MuiButton-startIcon {
    margin-right: 5px;
  }
`;
