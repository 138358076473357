import { styled } from '@mui/system';
import { css } from 'styled-components';

export const Wrapper = styled('fieldset')<{
  $marginBottom?: string;
  $marginRight?: string;
  $paddingTop?: string;
  $width?: string;
}>`
  position: relative;
  width: ${({ $width = '100%' }) => $width};
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 5px;
  margin-top: 0;
  margin-left: 0;
  margin-right: ${({ $marginRight = 0 }) => $marginRight};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`;
