import { Box } from '@mui/material';
import styled from 'styled-components';

export const TableBodyRow = styled(Box)<{ $isVisble?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  visibility: ${({ $isVisble }) => ($isVisble === false ? 'hidden' : 'visible')};
  height: 90px;
  border-bottom: ${({ theme }) => {
    const borderBottomColor =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.table.row.borderBottom;
    return `1px solid ${borderBottomColor}`;
  }};
  margin: 0 32px;
`;
