import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TRootState } from '@/store';
import { Heading, Logo, MainContent } from './elements';
import { unauthorizedPagesLayout } from '@/layouts/unauthorizedPagesLayout';
import { ClientSelect } from './ClientSelect';
import { ROUTES } from '@/shared/lib/const';
import { IClientListRecord } from '@/shared/lib/types';

const ClientList = () => {
  const clients = useSelector((state: TRootState) => state.authentication.clients);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!clients && clients.length === 0) {
      navigate(ROUTES.login);
    }
  }, [clients]);

  const handleClientClick = (client: IClientListRecord) => {
    const dispatchersWorkplaceOnly = client.dwp_only;
    let url = client.client_url;

    if (dispatchersWorkplaceOnly) {
      url += 'service/internet-shop/module/dispatchers-workplace';
    }
    window.location.assign(url);
  };

  return (
    <MainContent>
      <Logo />
      <Heading>Выберите клиента</Heading>
      <Box component="form" noValidate autoComplete="off">
        <ClientSelect clientListData={clients} onListItemClick={handleClientClick} />
      </Box>
    </MainContent>
  );
};

export default () => unauthorizedPagesLayout(ClientList);
