import * as React from 'react';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';

import { styledTheme } from '@/app/themes/styled-theme';

export const StyledInputLabel = styled(InputLabel)<OutlinedInputProps>(({ theme }) => ({
  '&': {
    color: styledTheme.colors.login.input.border,
  },
  '&.Mui-error': {
    color: styledTheme.colors.error,
  },
}));
