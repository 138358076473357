import { FC } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { ruRU } from '@mui/x-date-pickers/locales';
import { styled } from 'styled-components';

interface CustomDatePickerProps {
  label: string;
  sx?: Record<string, string | number>;
  value?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const CustomDatePickerWrapper = styled.div`
  position: relative;
  top: -8px;
`;

export const CustomDateTimePicker: FC<CustomDatePickerProps> = ({
  label,
  sx,
  value,
  onChange,
  error,
  helperText,
  disabled,
}) => {
  const handleChangeWrapper = (date: Dayjs) => {
    if (onChange) {
      let dateString;
      if (!date || date.toString() === 'Invalid date') {
        dateString = null;
      } else {
        dateString = date.format();
      }
      onChange(dateString);
    }
  };
  return (
    <CustomDatePickerWrapper>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="ru"
        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DemoContainer sx={{ flexGrow: 1, flexBasis: 0, ...sx }} components={['DateTimePicker']}>
          <DateTimePicker
            sx={{ flexGrow: 1, flexBasis: 0 }}
            label={label}
            value={!value ? null : dayjs(value)}
            onChange={handleChangeWrapper}
            disabled={disabled}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                error,
                helperText,
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </CustomDatePickerWrapper>
  );
};
