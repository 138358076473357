import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';

import {
  EPromotionItemActionType,
  EPromotionMassActionActionType,
  IArchivePromotionCollectionItemResponse,
  IFetchPromotionCategoriesResponse,
  IFetchPromotionCollectionItemResponse,
  IFetchPromotionCollectionListItemsResponse,
  IFetchPromotionItemResponse,
  IFetchPromotionItemsResponse,
  IFetchPromotionTypesResponse,
  IFetchPromotionVisualizationDataResponse,
  IGetPromotionCatalogResponse,
  IPromotionCatalog,
  IPromotionCategory,
  IPromotionCollectionItem,
  IPromotionCollectionListItem,
  IPromotionItem,
  IPromotionType,
  IPromotionVisualizationDataFromServer,
  ISavePromotionCollectionItemResponse,
  ISavePromotionCollectionListItemsOrderResponse,
  ISavePromotionResponse,
  ISetPromotionCatalogUrlResponse,
  IUpdatePromotionMassStatusResponse,
} from '../types';
import { TRootState } from '@/store';
import {
  convertDateStringToAPIFormat,
  transformPromotionCatalogBeforeSave,
  transformPromotionCollectionIdsBeforeSave,
  transformPromotionCollectionItemBeforeSave,
  transformPromotionDataBeforeSave,
  transformPromotionIdsBeforeMassUpdate,
  transformPromotionVisualizationDataBeforeSave,
} from '../utils';

export const fetchPromotionTypes = createAsyncThunk<IPromotionType[], void, unknown>(
  'promotions/fetchPromotionTypes',
  async () => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchPromotionTypesResponse>(
      QUERY_ENDPOINTS.promotions.promotionTypes.replace('[userId]', userId)
    );
    return response?.data?.data?.types;
  }
);

export const fetchPromotionCategories = createAsyncThunk<IPromotionCategory[], void, unknown>(
  'promotions/fetchPromotionCategories',
  async () => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchPromotionCategoriesResponse>(
      QUERY_ENDPOINTS.promotions.promotionCategories.replace('[userId]', userId)
    );
    return response?.data?.data?.categories;
  }
);

export const fetchPromotionItems = createAsyncThunk<IPromotionItem[], void, unknown>(
  'promotions/fetchPromotionItems',
  async (_, { getState }) => {
    const state = getState() as TRootState;
    const promotionFilters = state.promotions.promotionFilters;
    const params = new URLSearchParams('');

    if (promotionFilters.startDate) {
      params.set('filter:date_start', convertDateStringToAPIFormat(promotionFilters.startDate));
    }

    if (promotionFilters.finishDate) {
      params.set('filter:date_finish', convertDateStringToAPIFormat(promotionFilters.finishDate));
    }

    if (promotionFilters.statusKey) {
      params.set('filter:status', promotionFilters.statusKey);
    }

    if (promotionFilters.typeId) {
      params.set('filter:type', promotionFilters.typeId.toString());
    }

    if (promotionFilters.categoryId) {
      params.set('filter:category', promotionFilters.categoryId.toString());
    }

    let filterParamsUrl = decodeURIComponent(params.toString());

    if (promotionFilters.name) {
      if (filterParamsUrl) {
        filterParamsUrl += '&';
      }
      filterParamsUrl += `filter:name=${encodeURIComponent(promotionFilters.name)}`;
    }

    const userId = Cookies.get('userId') || '-1';

    let resultUrl = QUERY_ENDPOINTS.promotions.promotionItems.replace('[userId]', userId);

    if (filterParamsUrl) {
      resultUrl += `&${filterParamsUrl}`;
    }

    const response = await axiosInstance.get<IFetchPromotionItemsResponse>(resultUrl);
    return response?.data?.data?.promotions;
  }
);

export const savePromotionItem = createAsyncThunk<
  ISavePromotionResponse,
  EPromotionItemActionType,
  unknown
>(
  'promotions/savePromotionItem',
  async (promotionActionType: EPromotionItemActionType, { getState }) => {
    const state = getState() as TRootState;
    const data = transformPromotionDataBeforeSave(state.promotions.promotion);
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.put<ISavePromotionResponse>(
      MUTATION_ENDPOINTS.promotions.savePromotion
        .replace('[userId]', userId)
        .replace('[action]', promotionActionType),
      data
    );
    return response?.data;
  }
);

export const updateCurrentPromotionItem = createAsyncThunk<
  ISavePromotionResponse,
  EPromotionItemActionType,
  unknown
>(
  'promotions/updateCurrentPromotionItem',
  async (promotionActionType: EPromotionItemActionType, { getState }) => {
    const state = getState() as TRootState;
    const data = transformPromotionDataBeforeSave(state.promotions.promotion);
    const promotionId = state.promotions.promotion.id;
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.put<ISavePromotionResponse>(
      MUTATION_ENDPOINTS.promotions.updatePromotion
        .replace('[userId]', userId)
        .replace('[action]', promotionActionType)
        .replace('[promotionId]', promotionId.toString()),
      data
    );
    return response?.data;
  }
);

export const fetchPromotionItem = createAsyncThunk<IPromotionItem, number, unknown>(
  'promotions/fetchPromotionItem',
  async (promotionId) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchPromotionItemResponse>(
      QUERY_ENDPOINTS.promotions.promotionItem
        .replace('[userId]', userId)
        .replace('[promotionId]', promotionId.toString())
    );
    return response?.data?.data?.promotion;
  }
);

export const fetchPromotionVisualizationData = createAsyncThunk<
  IPromotionVisualizationDataFromServer,
  void,
  unknown
>('promotions/fetchPromotionVisualizationData', async (_, { getState }) => {
  const state = getState() as TRootState;
  const data = transformPromotionVisualizationDataBeforeSave(state.promotions.promotion);
  const promotionId = state.promotions.promotion.id;
  const userId = Cookies.get('userId') || '-1';
  let endpoint;
  if (promotionId) {
    endpoint = QUERY_ENDPOINTS.promotions.promotionVisualizationWithId
      .replace('[userId]', userId)
      .replace('[promotionId]', promotionId.toString());
  } else {
    endpoint = QUERY_ENDPOINTS.promotions.promotionVisualization.replace('[userId]', userId);
  }

  const response = await axiosInstance.post<IFetchPromotionVisualizationDataResponse>(
    endpoint,
    data
  );
  return response?.data?.data;
});

export interface IUpdatePromotionMassStatusProps {
  promotionMassActionType: EPromotionMassActionActionType;
  promotionIds: number[];
}

export const updatePromotionMassStatus = createAsyncThunk<
  IUpdatePromotionMassStatusResponse,
  IUpdatePromotionMassStatusProps,
  unknown
>('promotions/updatePromotionMassStatus', async ({ promotionMassActionType, promotionIds }) => {
  const data = transformPromotionIdsBeforeMassUpdate(promotionIds);
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.post<IUpdatePromotionMassStatusResponse>(
    MUTATION_ENDPOINTS.promotions.massAction
      .replace('[userId]', userId)
      .replace('[action]', promotionMassActionType),
    data
  );
  return response?.data;
});

export const fetchPromotionCollectionListItems = createAsyncThunk<
  IPromotionCollectionListItem[],
  void,
  unknown
>('promotions/fetchPromotionCollectionListItems', async () => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchPromotionCollectionListItemsResponse>(
    QUERY_ENDPOINTS.promotions.promotionCollectionListItems.replace('[userId]', userId)
  );
  return response?.data?.data?.collections;
});

export const savePromotionCollectionListItemsOrder = createAsyncThunk<
  ISavePromotionCollectionListItemsOrderResponse,
  void,
  unknown
>('promotions/savePromotionCollectionListItemsOrder', async (_, { getState }) => {
  const state = getState() as TRootState;
  const promotionCollections = state.promotions.promotionCollections;
  const data = transformPromotionCollectionIdsBeforeSave(promotionCollections);
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.post<ISavePromotionCollectionListItemsOrderResponse>(
    MUTATION_ENDPOINTS.promotions.savePromotionCollectionListItemsOrder.replace('[userId]', userId),
    data
  );
  return response?.data;
});

export const fetchPromotionCollectionItem = createAsyncThunk<
  IPromotionCollectionItem,
  number,
  unknown
>('promotions/fetchPromotionCollectionItem', async (collectionId: number) => {
  const userId = Cookies.get('userId') || '-1';
  const response = await axiosInstance.get<IFetchPromotionCollectionItemResponse>(
    QUERY_ENDPOINTS.promotions.promotionCollectionItem
      .replace('[userId]', userId)
      .replace('[collectionId]', collectionId.toString())
  );
  return response?.data?.data?.collection;
});

export const savePromotionCollectionItem = createAsyncThunk<
  ISavePromotionCollectionItemResponse['data'],
  boolean,
  unknown
>(
  'promotions/savePromotionCollectionItem',
  async (isNewPromotionCollection, { getState, rejectWithValue }) => {
    try {
      const state = getState() as TRootState;
      const promotionCollection = state.promotions.promotionCollection;
      const data = transformPromotionCollectionItemBeforeSave(promotionCollection);
      const userId = Cookies.get('userId') || '-1';

      let endpointUrl;
      if (isNewPromotionCollection) {
        endpointUrl = MUTATION_ENDPOINTS.promotions.saveNewPromotionCollectionItem.replace(
          '[userId]',
          userId
        );
      } else {
        const collectionId = promotionCollection.id;
        const version = promotionCollection.version;
        endpointUrl = MUTATION_ENDPOINTS.promotions.saveExistingPromotionCollectionItem
          .replace('[userId]', userId)
          .replace('[collectionId]', collectionId.toString())
          .replace('[version]', version.toString());
      }

      const response = await axiosInstance.put<ISavePromotionCollectionItemResponse>(
        endpointUrl,
        data
      );
      return response?.data?.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const archivePromotionCollectionItem = createAsyncThunk<
  IArchivePromotionCollectionItemResponse['data'],
  void,
  unknown
>('promotions/archivePromotionCollectionItem', async (_, { getState }) => {
  const state = getState() as TRootState;
  const promotionCollection = state.promotions.promotionCollection;
  const userId = Cookies.get('userId') || '-1';

  const endpointUrl = MUTATION_ENDPOINTS.promotions.archivePromotionCollectionItem
    .replace('[userId]', userId)
    .replace('[collectionId]', promotionCollection.id?.toString());

  const response = await axiosInstance.post<IArchivePromotionCollectionItemResponse>(endpointUrl);
  return response?.data?.data;
});

export const fetchPromotionCatalogUrl = createAsyncThunk<IPromotionCatalog, void, unknown>(
  'promotions/getPromotionCatalogUrl',
  async (_) => {
    const userId = Cookies.get('userId') || '-1';

    const endpointUrl = QUERY_ENDPOINTS.promotions.promotionCatalog.replace('[userId]', userId);

    const response = await axiosInstance.get<IGetPromotionCatalogResponse>(endpointUrl);
    return response?.data?.data?.catalog;
  }
);

export const setPromotionCatalogUrl = createAsyncThunk<
  ISetPromotionCatalogUrlResponse['data'],
  void,
  unknown
>('promotions/setPromotionCatalogUrl', async (_, { getState }) => {
  const state = getState() as TRootState;
  const promotionCatalog = state.promotions.promotionCatalog;
  const userId = Cookies.get('userId') || '-1';
  const data = transformPromotionCatalogBeforeSave(promotionCatalog);

  const endpointUrl = MUTATION_ENDPOINTS.promotions.setPromotionCatalogUrl.replace(
    '[userId]',
    userId
  );

  const response = await axiosInstance.put<ISetPromotionCatalogUrlResponse>(endpointUrl, data);
  return response?.data?.data;
});
