import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  fetchPromotionItem,
  fetchPromotionVisualizationData,
} from '@/features/promotions/redux/promotions.actions';
import {
  setPromotionAtivePage,
  setSelectedPromotionIds,
} from '@/features/promotions/redux/promotions.slice';
import { EPromotionTabPages, IPromotionItem } from '@/features/promotions/types';
import { TRootState, useAppDispatch } from '@/store';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Название', width: 300 },
  { field: 'type', headerName: 'Тип акции', width: 170 },
  {
    field: 'status',
    headerName: 'Статус',
    width: 170,
  },
];

interface IPromotionTableProps {
  promotionItems: IPromotionItem[];
}

export const PromotionTable: FC<IPromotionTableProps> = ({ promotionItems }) => {
  const dispatch = useAppDispatch();
  const selectedPromotionIds = useSelector(
    (state: TRootState) => state.promotions.selectedPromotionIds
  );
  const rows = useMemo(() => {
    const promotionItemsForTable = promotionItems?.map((promotionItem) => {
      return {
        id: promotionItem.id,
        name: promotionItem.name,
        type: promotionItem.type?.name,
        status: promotionItem.status?.name,
      };
    });

    return promotionItemsForTable || [];
  }, [promotionItems]);

  const handleRowClick = (rowData: GridRowParams<any>) => {
    const promotionId = rowData.id as number;
    dispatch(fetchPromotionItem(promotionId));
    dispatch(setPromotionAtivePage(EPromotionTabPages.existingPromotion));
  };

  const handleRowSelectionModelChange = (selectedItemIds: GridRowSelectionModel) => {
    dispatch(setSelectedPromotionIds(selectedItemIds));
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        onRowClick={handleRowClick}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedPromotionIds}
        onRowSelectionModelChange={handleRowSelectionModelChange}
      />
    </div>
  );
};
