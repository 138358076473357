import { ISalepointInfoShopGroupListItem } from '../types';

export const salepointInfoShopListData: ISalepointInfoShopGroupListItem[] = [
  {
    city: 'Калининград',
    shops: [
      {
        id: 1,
        address: 'Калининград ул. Литовский Вал, 45',
      },
      {
        id: 2,
        address: 'Калининград ул. Марата Мирзалгуева, 196 литера Б',
      },
      {
        id: 3,
        address: 'Черняховск ул. Спортивная 25',
      },
      {
        id: 4,
        address: 'Калининград ул. Советский проспект 234',
      },
      {
        id: 5,
        address: 'Калининград ул. Ленинский проспект 2',
      },
    ],
  },
  {
    city: 'Светлогорск',
    shops: [
      {
        id: 6,
        address: 'Светлогорск ул. Литовский Вал, 45',
      },
      {
        id: 7,
        address: 'Светлогорск ул. Марата Мирзалгуева, 196 литера Б',
      },
      {
        id: 8,
        address: 'Светлогорск ул. Спортивная 25',
      },
      {
        id: 9,
        address: 'Светлогорск ул. Советский проспект 234',
      },
      {
        id: 10,
        address: 'Светлогорск ул. Ленинский проспект 2',
      },
    ],
  },
  {
    city: 'Балтийск',
    shops: [
      {
        id: 11,
        address: 'Балтийск ул. Литовский Вал, 45',
      },
      {
        id: 12,
        address: 'Балтийск ул. Марата Мирзалгуева, 196 литера Б',
      },
      {
        id: 13,
        address: 'Балтийск ул. Спортивная 25',
      },
      {
        id: 14,
        address: 'Балтийск ул. Советский проспект 234',
      },
      {
        id: 15,
        address: 'Балтийск ул. Ленинский проспект 2',
      },
    ],
  },
  {
    city: 'Зеленоградск',
    shops: [
      {
        id: 16,
        address: 'Зеленоградск ул. Литовский Вал, 45',
      },
      {
        id: 17,
        address: 'Зеленоградск ул. Марата Мирзалгуева, 196 литера Б',
      },
      {
        id: 18,
        address: 'Зеленоградск ул. Спортивная 25',
      },
      {
        id: 19,
        address: 'Зеленоградск ул. Советский проспект 234',
      },
      {
        id: 20,
        address: 'Зеленоградск ул. Ленинский проспект 2',
      },
    ],
  },
  {
    city: 'Черняховск',
    shops: [
      {
        id: 21,
        address: 'Черняховск ул. Литовский Вал, 45',
      },
      {
        id: 22,
        address: 'Черняховск ул. Марата Мирзалгуева, 196 литера Б',
      },
      {
        id: 23,
        address: 'Черняховск ул. Спортивная 25',
      },
      {
        id: 24,
        address: 'Черняховск ул. Советский проспект 234',
      },
      {
        id: 25,
        address: 'Черняховск ул. Ленинский проспект 2',
      },
    ],
  },
];
