import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { styledTheme } from '@/app/themes/styled-theme';

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: '100%',
  '& input.MuiOutlinedInput-input': {
    color: styledTheme.colors.login.input.textColor,
  },
  '& label': {
    color: styledTheme.colors.login.input.border,
  },
  '& label.Mui-error': {
    color: styledTheme.colors.error,
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: styledTheme.colors.login.input.border,
  },
  '& .MuiInputBase-formControl.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: styledTheme.colors.error,
  },
  '&:hover .MuiInputBase-formControl:not(.Mui-disabled):not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline':
    {
      borderColor: styledTheme.colors.login.input.borderHover,
    },
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
    {
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': styledTheme.colors.login.input.textColor,
      transition: 'background-color 5000s ease-in-out 0s',
      boxShadow: `inset 0 0 200px 200px ${styledTheme.colors.login.input.autocompleteBackground}`,
    },
}));
