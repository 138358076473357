import { TextField } from '@mui/material';
import { styled } from '@mui/system';

import { styledTheme } from '@/app/themes/styled-theme';

export const CustomTextField = styled(TextField)(
  ({ theme }) => `
  label.Mui-disabled {
    color: ${styledTheme.colors.input.disabled.labelText};
    background-color: ${styledTheme.colors.background.main};
    padding: 0px 5px;
    border-radius: 5px;
  }

  & div.Mui-disabled {
    background-color: ${styledTheme.colors.input.disabled.background};
  }

  & .Mui-disabled {
    input {
      color: ${styledTheme.colors.input.disabled.inputText};
    }
    fieldset {
      border-color: transparent !important;
    }
  }
`
);
