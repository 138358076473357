import styled from 'styled-components';

export const Button = styled.div<{ $butttonColor: string; $butttonBgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  padding: 10px;
  font-size: 11px;
  font-weight: 600;
  color: ${({ $butttonColor }) => $butttonColor};
  background-color: ${({ $butttonBgColor }) => $butttonBgColor};
  border-radius: 8px;
  overflow: hidden;
`;
