import { DivKitProps } from '@divkitframework/react';

export enum EPromotionType {
  bonuses,
  discount,
  bonusesPlusDiscount,
  chips,
  present,
  delivery,
  fromPartner,
}

export enum EPromotionStatusKey {
  draft = 'draft',
  activated = 'activated',
  reactivated = 'reactivated',
  pending = 'pending',
  published = 'published',
  finished = 'finished',
  suspended = 'suspended',
}

export enum EPromotionStatusName {
  draft = 'Черновик',
  activated = 'Активирована',
  reactivated = 'Реактивирована',
  pending = 'Ожидает публикации',
  published = 'Опубликована',
  finished = 'Остановлена',
  suspended = 'Завершена',
}

export enum EPromotionTabPages {
  promotionList,
  newPromotion,
  existingPromotion,
}

export enum EPromotionActionBtnType {
  deepLink = 'deep_link',
  webView = 'webview',
  browser = 'browser',
  share = 'share',
  close = 'close',
}

export interface IPromotionStatus {
  key: EPromotionStatusKey;
  name: EPromotionStatusName;
}

export interface IPromotionType {
  id: number;
  name: string;
}

export interface IFetchPromotionTypesResponse {
  data: {
    types: IPromotionType[];
  };
}

export interface IPromotionCategory {
  id: number;
  name: string;
}

export interface IFetchPromotionCategoriesResponse {
  data: {
    categories: IPromotionCategory[];
  };
}

export interface IFetchPromotionItemsResponse {
  data: {
    promotions: IPromotionItem[];
  };
}

export interface IFetchPromotionItemResponse {
  data: {
    promotion: IPromotionItem;
  };
}

export interface ISavePromotionResponse {
  status: string;
  promotion_id: number;
  version: number;
}

export enum EPromotionActionType {
  draft = 'draft',
  readyToPublish = 'ready_to_publish',
}

export enum EPromotionButtonParamsName {
  footer = 'footer',
}

export enum EPromotionButtonParamsType {
  button = 'button',
}

export interface IPromotionButton {
  params: {
    name?: EPromotionButtonParamsName;
    type?: EPromotionButtonParamsType;
    value?: string;
    background_color?: string;
    text_color?: string;
    on_click?: {
      action_type?: string;
      action_value?: string;
    };
  };
}

export interface IPromotionItem {
  id?: number;
  version?: number;
  name?: string;
  type?: IPromotionType | null;
  categories: IPromotionCategory[] | null;
  status?: IPromotionStatus;
  list_image?: string;
  full_image?: string;
  description?: string;
  condition?: string;
  requirements?: string;
  button?: IPromotionButton;
  public_datetime?: string;
  start_datetime?: string;
  finish_datetime?: string;
}

export enum EPromotionItemActionType {
  draft = 'draft',
  readyToPublish = 'ready_to_publish',
}

export interface IPromotionFilters {
  name: string;
  startDate: string;
  finishDate: string;
  statusKey: string;
  typeId: number;
  categoryId: number;
}

export enum EPromotionMassActionActionType {
  suspend = 'suspend',
  reactivate = 'reactivate',
}

export interface IUpdatePromotionMassStatusResponse {
  data: {
    status: 'ok';
  };
}

export interface IPromotionCollectionItemStatus {
  key: string;
  name: string;
}

export interface IPromotionCollectionListItem {
  id: number;
  version: number;
  name: string;
  status: IPromotionCollectionItemStatus;
  image_url: string;
  title_url: string;
}

export interface IFetchPromotionCollectionListItemsResponse {
  data: {
    collections: IPromotionCollectionListItem[];
  };
}

export interface IPromotionCollectionItemPromotion {
  id: number;
  name: string;
  type: string;
  status: string;
}

export interface IPromotionCollectionItem {
  id?: number;
  version?: number;
  name: string;
  status: IPromotionCollectionItemStatus;
  image_url: string;
  title_url: string;
  created_at?: string;
  promotions?: IPromotionItem[];
  is_published?: boolean;
}

export interface IFetchPromotionCollectionItemResponse {
  data: {
    collection: IPromotionCollectionItem;
  };
}

export interface ISavePromotionCollectionListItemsOrderResponse {
  data: {
    status: 'ok';
    version: number;
  };
}

export interface ISavePromotionCollectionItemResponse {
  data: {
    status: 'ok';
    version: number;
  };
}

export interface IArchivePromotionCollectionItemResponse {
  data: {
    status: 'ok';
    version: number;
  };
}

export enum EPromotionCollectionStatusKey {
  draft = 'draft',
  ready = 'ready',
  published = 'published',
  archived = 'archived',
}

export enum EPromotionCollectionStatusName {
  draft = 'Черновик',
  ready = 'Ожидает публикации',
  published = 'Опубликована',
  archived = 'В архиве',
}

export enum EPromotionCollectionTabPages {
  promotionCollectionList,
  newPromotionCollection,
  existingPromotionCollection,
}

export interface IPromotionCatalog {
  file_url: string | null;
  version?: number;
}

export interface IGetPromotionCatalogResponse {
  data: {
    catalog: IPromotionCatalog;
  };
}

export interface ISetPromotionCatalogUrlResponse {
  data: {
    status: 'ok';
    version: number;
  };
}

export interface IPromotionVisualizationData {
  short_promotion: DivKitProps['json'];
  full_promotion: DivKitProps['json'];
}

export interface IPromotionVisualizationItemFromServer {
  card: DivKitProps['json']['card'];
  template: DivKitProps['json']['templates'];
}

export interface IPromotionVisualizationDataFromServer {
  short_promotion: IPromotionVisualizationItemFromServer;
  full_promotion: IPromotionVisualizationItemFromServer;
}

export interface IFetchPromotionVisualizationDataResponse {
  data: IPromotionVisualizationDataFromServer;
}
