import Box from '@mui/material/Box';
import styled from 'styled-components';

export const TextFieldEmptyText = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  padding-left: 15px;
  color: rgba(0, 0, 0, 0.87);
`;
