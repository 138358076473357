import { FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MainWrapper } from './elements';

export const unauthorizedPagesLayout = (Content: FC) => {
  return (
    <Scrollbars style={{ width: '100vw', height: '100vh' }}>
      <MainWrapper>
        <Content />
      </MainWrapper>
    </Scrollbars>
  );
};
