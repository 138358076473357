import { FC } from 'react';

import { Scenario, StyledLink } from '@/features/stories/scenarios/components/Card/elements';
import { ROUTES } from '@/shared/lib/const';
import { IBannerScenarioItem } from '@/features/banners/types';
import { ScenarioName, SlashSymbol } from './elements';

interface ICardProps {
  scenario: IBannerScenarioItem;
}

export const ScenarioCard: FC<ICardProps> = (props) => {
  const { scenario } = props;
  return (
    <StyledLink to={ROUTES.bannerScenario.replace(':scenario', scenario.id.toString())}>
      <Scenario>
        {scenario.key}
        {scenario.name && (
          <>
            <SlashSymbol>/</SlashSymbol>
            {scenario.name}
          </>
        )}
      </Scenario>
    </StyledLink>
  );
};
