import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack, TextField } from '@mui/material';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';

import { TRootState, useAppDispatch } from '@/store';
import {
  addStaticCodesItem,
  removeStaticCodesEntryByIndex,
  setStaticCodesEntryKey,
  setStaticCodesEntryValue,
} from '../../redux/clientSettings.slice';
import { EClientSettingsFieldKeys } from '../../types';
import { AddStaticCodeButton, DeleteStaticCodeButton, Symbol } from './elements';

export interface IStaticCodesEntryChange {
  event: React.ChangeEvent<HTMLInputElement>;
  index: number;
}

export const StaticCodes = () => {
  const dispatch = useAppDispatch();
  const clientSettings = useSelector((state: TRootState) => state.clientSettings.clientSettings);
  const staticCodesItems = useSelector(
    (state: TRootState) => state.clientSettings.staticCodesItems
  );

  const staticCodesName = useMemo(() => {
    const staticCodes = clientSettings?.fields.find((field) => {
      return field.key === EClientSettingsFieldKeys.staticCodes;
    });

    if (staticCodes) {
      return staticCodes?.name || '';
    }

    return 'Статичные коды';
  }, [clientSettings]);

  const handleStaticCodesEntryKeyChange = ({ event, index }: IStaticCodesEntryChange) => {
    dispatch(setStaticCodesEntryKey({ value: event.target.value, index }));
  };

  const handleStaticCodesEntryValueChange = ({ event, index }: IStaticCodesEntryChange) => {
    dispatch(setStaticCodesEntryValue({ value: event.target.value, index }));
  };

  const handleRemoveStaticCodeBtnClick = (index: number) => {
    dispatch(removeStaticCodesEntryByIndex(index));
  };

  const handleAddStaticCodeBtnClick = () => {
    dispatch(addStaticCodesItem());
  };

  const renderStaticCodesExisting = () => {
    return (
      <>
        {staticCodesItems.map((staticCodeItem, index) => {
          return (
            <Stack
              key={index}
              direction="row"
              spacing="10px"
              sx={{ width: '100%', pb: 2, '&:last-child': { pb: 0 } }}
            >
              <TextField
                sx={{ width: '100%', mb: 2 }}
                label="Телефон"
                type="text"
                size="small"
                value={staticCodeItem.staticCodeName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleStaticCodesEntryKeyChange({ event, index });
                }}
              />
              <TextField
                sx={{ width: '100%' }}
                label="Код"
                type="text"
                size="small"
                value={staticCodeItem.staticCodeValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleStaticCodesEntryValueChange({ event, index });
                }}
              />
              <DeleteStaticCodeButton
                // @ts-expect-error: Unreachable code error
                color="lightGrey"
                variant="outlined"
                component="label"
                onClick={() => handleRemoveStaticCodeBtnClick(index)}
              >
                <Symbol>delete</Symbol>
              </DeleteStaticCodeButton>
            </Stack>
          );
        })}
      </>
    );
  };

  return (
    <TextFieldGroup sx={{ mb: 1, pt: 1 }} title={staticCodesName}>
      <Stack direction="column" sx={{ alignItems: 'center' }}>
        {renderStaticCodesExisting()}
        <AddStaticCodeButton
          // @ts-expect-error: Unreachable code error
          color="lightGrey"
          variant="outlined"
          component="label"
          onClick={() => handleAddStaticCodeBtnClick()}
        >
          <Symbol>add</Symbol>
        </AddStaticCodeButton>
      </Stack>
    </TextFieldGroup>
  );
};
