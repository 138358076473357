import React, { useMemo, useState, useEffect, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Form, useFormik, FormikProvider } from 'formik';

import { TRootState, useAppDispatch } from '@/store';
import { SidebarContainer, SidebarControlGroup, SlideTextField } from './elements';
import {
  setActiveSlideTitleValue,
  setActiveSlideTitleTextColor,
  setActiveSlideDescriptionValue,
  setActiveSlideDescriptionTextColor,
  setActiveSlideButtonValue,
  setActiveSlideButtonActionValue,
  setActiveSlideButtonActionType,
  setActiveSlideButtonTextColor,
  setActiveSlideButtonBackgroundColor,
  setActiveSlideBackgroundImageValue,
  setActiveSlideProgressColor,
  setActiveSlideProgressBackgroundColor,
  setActiveSlideCloseBackgroundColor,
  setActiveSlideExternalId,
  setActiveSlideName,
} from '@/features/stories/redux/stories.slice';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  ESlideActionType,
  ISlide,
  ISlideBackground,
  ISlideClose,
  ISlideDescription,
  ISlideFooter,
  ISlideProgress,
  ISlideTitle,
} from '@/features/stories/types';
import { ControlRow } from '@/shared/components/ControlRow';
import { UploadFileWithTextField } from '@/shared/components/UploadFileWithTextField';
import { ColorPickerWithTextField } from '@/shared/components/ColorPickerWithTextField';
import Yup from '@/shared/validations';

const validationSchema = Yup.object().shape({
  externalSlideIdField: Yup.string().slideExternalIdValidation(),
  slideName: Yup.string().itemNameInputValidation(),
  titleValue: Yup.string().slideTitleValueValidation(),
  titleTextColor: Yup.string().colorInputValidation(),
  descriptionValue: Yup.string().slideDescriptionValueValidation(),
  descriptionTextColor: Yup.string().colorInputValidation(),
  footerTextValue: Yup.string().itemButtonTextValueValidation(),
  buttonActionType: Yup.string().itemActionTypeValidation(),
  buttonActionValue: Yup.string().externalLinkInputValidation(),
  footerTextColor: Yup.string().colorInputValidation(),
  footerBackgroundColor: Yup.string().colorInputValidation(),
  backgroundImageValue: Yup.string().externalLinkInputValidation(),
  progressColor: Yup.string().colorInputValidation(),
  progressBackgroundColor: Yup.string().colorInputValidation(),
  closeBackgroundColor: Yup.string().colorInputValidation(),
});

export const ControlsBar = () => {
  const { scenario, story: externalStoryId, slide: externalSlideId } = useParams();
  const location = useLocation();
  const isShowFormErrors = location.state?.isShowStoryFormErrors;
  const dispatch = useAppDispatch();
  const slides = useSelector((state: TRootState) => state.stories.story.slides);
  const middleMenuActiveBtn = useSelector(
    (state: TRootState) => state.services.middleMenuActiveBtn
  );

  const currSlide = useMemo<ISlide>(() => {
    if (slides) {
      if (middleMenuActiveBtn === 'new_slide') {
        return slides.find((slide) => slide.isNewSlide);
      } else {
        return slides.find((slide) => slide.external_slide_id === middleMenuActiveBtn);
      }
    } else {
      return {} as ISlide;
    }
  }, [slides, middleMenuActiveBtn]);

  const formik = useFormik({
    initialValues: {
      externalSlideIdField: '',
      slideName: '',
      titleValue: '',
      titleTextColor: '',
      descriptionValue: '',
      descriptionTextColor: '',
      footerTextValue: '',
      buttonActionType: '',
      buttonActionValue: '',
      footerTextColor: '',
      footerBackgroundColor: '',
      backgroundImageValue: '',
      progressColor: '',
      progressBackgroundColor: '',
      closeBackgroundColor: '',
    },
    onSubmit: (values, actions) => {},
    validationSchema,
  });

  useEffect(() => {
    if (currSlide) {
      let title, description, footer, background, progress, close;
      currSlide?.slides?.params?.forEach((param) => {
        if (param.name === 'title') {
          title = param;
        } else if (param.name === 'description') {
          description = param;
        } else if (param.name === 'footer') {
          footer = param;
        } else if (param.name === 'background') {
          background = param;
        } else if (param.name === 'progress') {
          progress = param;
        } else if (param.name === 'close') {
          close = param;
        }
      });

      formik.setFieldValue('externalSlideIdField', currSlide.external_slide_id || '');
      formik.setFieldValue('slideName', currSlide.name || '');

      formik.setFieldValue('titleValue', (title as ISlideTitle)?.value || '');
      formik.setFieldValue('titleTextColor', (title as ISlideTitle)?.text_color || '');

      formik.setFieldValue('descriptionValue', (description as ISlideDescription)?.value || '');
      formik.setFieldValue(
        'descriptionTextColor',
        (description as ISlideDescription)?.text_color || ''
      );

      formik.setFieldValue('footerTextValue', (footer as ISlideFooter)?.value || '');
      formik.setFieldValue(
        'buttonActionType',
        (footer as ISlideFooter)?.on_click?.action_type || ''
      );
      formik.setFieldValue(
        'buttonActionValue',
        (footer as ISlideFooter)?.on_click?.action_value || ''
      );

      formik.setFieldValue('footerTextColor', (footer as ISlideFooter)?.text_color || '');
      formik.setFieldValue(
        'footerBackgroundColor',
        (footer as ISlideFooter)?.background_color || ''
      );

      formik.setFieldValue('backgroundImageValue', (background as ISlideBackground)?.value || '');

      formik.setFieldValue('progressColor', (progress as ISlideProgress)?.progress_color || '');
      formik.setFieldValue(
        'progressBackgroundColor',
        (progress as ISlideProgress)?.background_color || ''
      );

      formik.setFieldValue('closeBackgroundColor', (close as ISlideClose)?.background_color || '');

      if (isShowFormErrors) {
        setTimeout(() => {
          formik.setTouched({
            externalSlideIdField: true,
            slideName: true,
            titleValue: true,
            titleTextColor: true,
            descriptionValue: true,
            descriptionTextColor: true,
            footerTextValue: true,
            buttonActionType: true,
            buttonActionValue: true,
            footerTextColor: true,
            footerBackgroundColor: true,
            backgroundImageValue: true,
            progressColor: true,
            progressBackgroundColor: true,
            closeBackgroundColor: true,
          });
          formik.validateForm();
        });
      }
    }
  }, [currSlide, isShowFormErrors]);

  const handleExternalSlideIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currSlide?.isNewSlide) {
      formik.setFieldValue('externalSlideIdField', event.target.value);
    }
  };
  const handleExternalSlideIdBlur = () => {
    if (currSlide?.isNewSlide) {
      dispatch(
        setActiveSlideExternalId({
          value: formik.values.externalSlideIdField,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleExternalSlideIdKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && currSlide?.isNewSlide) {
      dispatch(
        setActiveSlideExternalId({
          value: formik.values.externalSlideIdField,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };

  const handleSlideNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('slideName', event.target.value);
  };
  const handleSlideNameBlur = () => {
    dispatch(
      setActiveSlideName({
        value: formik.values.slideName,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleSlideNameKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideName({
          value: formik.values.slideName,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };

  const handleTitleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('titleValue', event.target.value);
  };
  const handleTitleValueBlur = () => {
    dispatch(
      setActiveSlideTitleValue({
        value: formik.values.titleValue,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleTitleValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideTitleValue({
          value: formik.values.titleValue,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };

  const handleTitleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('titleTextColor', event.target.value);
  };
  const handleTitleTextColorBlur = () => {
    dispatch(
      setActiveSlideTitleTextColor({
        value: formik.values.titleTextColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleTitleTextColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideTitleTextColor({
          value: formik.values.titleTextColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleTitleTextColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideTitleTextColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleDescriptionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('descriptionValue', event.target.value);
  };
  const handleDescriptionValueBlur = () => {
    dispatch(
      setActiveSlideDescriptionValue({
        value: formik.values.descriptionValue,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleDescriptionValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideDescriptionValue({
          value: formik.values.descriptionValue,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };

  const handleDescriptionTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('descriptionTextColor', event.target.value);
  };
  const handleDescriptionTextColorBlur = () => {
    dispatch(
      setActiveSlideDescriptionTextColor({
        value: formik.values.descriptionTextColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleDescriptionTextColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideDescriptionTextColor({
          value: formik.values.descriptionTextColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleDescriptionTextColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideDescriptionTextColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleButtonValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('footerTextValue', event.target.value);
  };
  const handleButtonValueBlur = () => {
    dispatch(
      setActiveSlideButtonValue({
        value: formik.values.footerTextValue,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleButtonValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideButtonValue({
          value: formik.values.footerTextValue,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };

  const handleButtonActionTypeChange = (event: SelectChangeEvent) => {
    dispatch(
      setActiveSlideButtonActionType({
        value: event.target.value,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleButtonActionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('buttonActionValue', event.target.value);
  };
  const handleButtonActionValueBlur = () => {
    dispatch(
      setActiveSlideButtonActionValue({
        value: formik.values.buttonActionValue,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleButtonActionValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideButtonActionValue({
          value: formik.values.buttonActionValue,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };

  const handleButtonTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('footerTextColor', event.target.value);
  };
  const handleButtonTextColorBlur = () => {
    dispatch(
      setActiveSlideButtonTextColor({
        value: formik.values.footerTextColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleButtonTextColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideButtonTextColor({
          value: formik.values.footerTextColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleButtonTextColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideButtonTextColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleButtonBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('footerBackgroundColor', event.target.value);
  };
  const handleButtonBackgroundColorBlur = () => {
    dispatch(
      setActiveSlideButtonBackgroundColor({
        value: formik.values.footerBackgroundColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleButtonBackgroundColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideButtonBackgroundColor({
          value: formik.values.footerBackgroundColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleButtonBackgroundColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideButtonBackgroundColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleBackgroundImageValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('backgroundImageValue', event.target.value);
  };
  const handleBackgroundImageValueBlur = () => {
    dispatch(
      setActiveSlideBackgroundImageValue({
        value: formik.values.backgroundImageValue,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleBackgroundImageValueKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideBackgroundImageValue({
          value: formik.values.backgroundImageValue,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleBackgroundImageUpload = (url: string) => {
    dispatch(
      setActiveSlideBackgroundImageValue({
        value: url,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleProgressColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('progressColor', event.target.value);
  };
  const handleProgressColorBlur = () => {
    dispatch(
      setActiveSlideProgressColor({
        value: formik.values.progressColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleProgressColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideProgressColor({
          value: formik.values.progressColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleProgressColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideProgressColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleProgressBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('progressBackgroundColor', event.target.value);
  };
  const handleProgressBackgroundColorBlur = () => {
    dispatch(
      setActiveSlideProgressBackgroundColor({
        value: formik.values.progressBackgroundColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleProgressBackgroundColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideProgressBackgroundColor({
          value: formik.values.progressBackgroundColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleProgressBackgroundColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideProgressBackgroundColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  const handleCloseBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('closeBackgroundColor', event.target.value);
  };
  const handleCloseBackgroundColorBlur = () => {
    dispatch(
      setActiveSlideCloseBackgroundColor({
        value: formik.values.closeBackgroundColor,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };
  const handleCloseBackgroundColorKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        setActiveSlideCloseBackgroundColor({
          value: formik.values.closeBackgroundColor,
          scenario,
          externalStoryId,
          externalSlideId,
        })
      );
    }
  };
  const handleCloseBackgroundColorFromColorPickerChange = (color: string) => {
    dispatch(
      setActiveSlideCloseBackgroundColor({
        value: color,
        scenario,
        externalStoryId,
        externalSlideId,
      })
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <SidebarContainer>
          <TextFieldGroup marginBottom="10px" title="Слайд">
            <ControlRow>
              <SlideTextField
                label="Внешний идентификатор"
                type="text"
                size="small"
                value={formik.values.externalSlideIdField}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: !currSlide?.isNewSlide,
                }}
                onChange={handleExternalSlideIdChange}
                onBlur={handleExternalSlideIdBlur}
                onKeyUp={handleExternalSlideIdKeyUp}
                sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                error={formik.touched.externalSlideIdField && !!formik.errors.externalSlideIdField}
                helperText={
                  formik.touched.externalSlideIdField && formik.errors.externalSlideIdField
                }
              />
              <SlideTextField
                label="Имя"
                type="text"
                size="small"
                value={formik.values.slideName}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleSlideNameChange}
                onBlur={handleSlideNameBlur}
                onKeyUp={handleSlideNameKeyUp}
                sx={{ marginBottom: '0 !important' }}
                error={formik.touched.slideName && !!formik.errors.slideName}
                helperText={formik.touched.slideName && formik.errors.slideName}
              />
            </ControlRow>
          </TextFieldGroup>
          <TextFieldGroup marginBottom="10px" title="Заглавие">
            <ControlRow>
              <SlideTextField
                label="Значение"
                type="text"
                size="small"
                value={formik.values.titleValue}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleTitleValueChange}
                onBlur={handleTitleValueBlur}
                onKeyUp={handleTitleValueKeyUp}
                sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                error={formik.touched.titleValue && !!formik.errors.titleValue}
                helperText={formik.touched.titleValue && formik.errors.titleValue}
              />
              <ColorPickerWithTextField
                label="Цвет текста"
                type="text"
                size="small"
                value={formik.values.titleTextColor}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={handleTitleTextColorChange}
                onBlur={handleTitleTextColorBlur}
                onKeyUp={handleTitleTextColorKeyUp}
                handleColorChange={handleTitleTextColorFromColorPickerChange}
                sx={{ marginBottom: '0 !important' }}
                error={formik.touched.titleTextColor && !!formik.errors.titleTextColor}
                helperText={formik.touched.titleTextColor && formik.errors.titleTextColor}
              />
            </ControlRow>
          </TextFieldGroup>
          <TextFieldGroup marginBottom="10px" title="Описание">
            <ControlRow>
              <SlideTextField
                label="Значение"
                type="text"
                size="small"
                value={formik.values.descriptionValue}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDescriptionValueChange}
                onBlur={handleDescriptionValueBlur}
                onKeyUp={handleDescriptionValueKeyUp}
                sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                error={formik.touched.descriptionValue && !!formik.errors.descriptionValue}
                helperText={formik.touched.descriptionValue && formik.errors.descriptionValue}
              />
              <ColorPickerWithTextField
                label="Цвет текста"
                type="text"
                size="small"
                value={formik.values.descriptionTextColor}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={handleDescriptionTextColorChange}
                onBlur={handleDescriptionTextColorBlur}
                onKeyUp={handleDescriptionTextColorKeyUp}
                handleColorChange={handleDescriptionTextColorFromColorPickerChange}
                sx={{ marginBottom: '0 !important' }}
                error={formik.touched.descriptionTextColor && !!formik.errors.descriptionTextColor}
                helperText={
                  formik.touched.descriptionTextColor && formik.errors.descriptionTextColor
                }
              />
            </ControlRow>
          </TextFieldGroup>
          <TextFieldGroup marginBottom="20px" title="Подвал (кнопка)">
            <SlideTextField
              label="Значение"
              type="text"
              size="small"
              value={formik.values.footerTextValue}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleButtonValueChange}
              onBlur={handleButtonValueBlur}
              onKeyUp={handleButtonValueKeyUp}
              sx={{ marginRight: '10px' }}
              error={formik.touched.footerTextValue && !!formik.errors.footerTextValue}
              helperText={formik.touched.footerTextValue && formik.errors.footerTextValue}
            />
            <SidebarControlGroup>
              <FormControl
                fullWidth
                sx={{ marginBottom: '10px', marginRight: '10px', flexGrow: 1, flexBasis: 0 }}
              >
                <InputLabel id="action-type-label" size="small">
                  Тип действия
                </InputLabel>
                <Select
                  labelId="action-type-label"
                  id="action-type-select"
                  value={formik.values.buttonActionType}
                  label="Тип действия"
                  onChange={handleButtonActionTypeChange}
                  size="small"
                  error={formik.touched.buttonActionType && !!formik.errors.buttonActionType}
                >
                  <MenuItem value={ESlideActionType.deepLink}>deep_link</MenuItem>
                  <MenuItem value={ESlideActionType.externalLink}>external_link</MenuItem>
                  <MenuItem value={ESlideActionType.share}>share</MenuItem>
                  <MenuItem value={ESlideActionType.webview}>webview</MenuItem>
                </Select>
                {formik.touched.buttonActionType && formik.errors.buttonActionType ? (
                  <FormHelperText error>{formik.errors.buttonActionType}</FormHelperText>
                ) : null}
              </FormControl>
              <ColorPickerWithTextField
                label="Цвет текста"
                type="text"
                size="small"
                value={formik.values.footerTextColor}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={handleButtonTextColorChange}
                onBlur={handleButtonTextColorBlur}
                onKeyUp={handleButtonTextColorKeyUp}
                handleColorChange={handleButtonTextColorFromColorPickerChange}
                error={formik.touched.footerTextColor && !!formik.errors.footerTextColor}
                helperText={formik.touched.footerTextColor && formik.errors.footerTextColor}
              />
            </SidebarControlGroup>
            <SidebarControlGroup>
              <SlideTextField
                label="Значение действия"
                type="text"
                size="small"
                value={formik.values.buttonActionValue}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleButtonActionValueChange}
                onBlur={handleButtonActionValueBlur}
                onKeyUp={handleButtonActionValueKeyUp}
                sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                error={formik.touched.buttonActionValue && !!formik.errors.buttonActionValue}
                helperText={formik.touched.buttonActionValue && formik.errors.buttonActionValue}
              />
              <ColorPickerWithTextField
                label="Цвет фона"
                type="text"
                size="small"
                value={formik.values.footerBackgroundColor}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={handleButtonBackgroundColorChange}
                onBlur={handleButtonBackgroundColorBlur}
                onKeyUp={handleButtonBackgroundColorKeyUp}
                handleColorChange={handleButtonBackgroundColorFromColorPickerChange}
                sx={{ marginBottom: '0 !important' }}
                error={
                  formik.touched.footerBackgroundColor && !!formik.errors.footerBackgroundColor
                }
                helperText={
                  formik.touched.footerBackgroundColor && formik.errors.footerBackgroundColor
                }
              />
            </SidebarControlGroup>
          </TextFieldGroup>
          <UploadFileWithTextField
            label="Ссылка на картинку фона"
            type="text"
            size="small"
            value={formik.values.backgroundImageValue}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleBackgroundImageValueChange}
            onBlur={handleBackgroundImageValueBlur}
            onKeyUp={handleBackgroundImageValueKeyUp}
            handleFileUpload={handleBackgroundImageUpload}
            error={formik.touched.backgroundImageValue && !!formik.errors.backgroundImageValue}
            helperText={formik.touched.backgroundImageValue && formik.errors.backgroundImageValue}
          />
          <TextFieldGroup marginBottom="10px" title="Полоса прогресса">
            <ControlRow>
              <ColorPickerWithTextField
                label="Цвет"
                type="text"
                size="small"
                value={formik.values.progressColor}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={handleProgressColorChange}
                onBlur={handleProgressColorBlur}
                onKeyUp={handleProgressColorKeyUp}
                sx={{ marginRight: '10px', marginBottom: '0 !important' }}
                handleColorChange={handleProgressColorFromColorPickerChange}
                error={formik.touched.progressColor && !!formik.errors.progressColor}
                helperText={formik.touched.progressColor && formik.errors.progressColor}
              />
              <ColorPickerWithTextField
                label="Цвет фона"
                type="text"
                size="small"
                value={formik.values.progressBackgroundColor}
                InputLabelProps={{
                  shrink: true,
                }}
                onInput={handleProgressBackgroundColorChange}
                onBlur={handleProgressBackgroundColorBlur}
                onKeyUp={handleProgressBackgroundColorKeyUp}
                handleColorChange={handleProgressBackgroundColorFromColorPickerChange}
                sx={{ marginBottom: '0 !important' }}
                error={
                  formik.touched.progressBackgroundColor && !!formik.errors.progressBackgroundColor
                }
                helperText={
                  formik.touched.progressBackgroundColor && formik.errors.progressBackgroundColor
                }
              />
            </ControlRow>
          </TextFieldGroup>
          <ColorPickerWithTextField
            label="Цвет фона кнопки Закрыть"
            type="text"
            size="small"
            value={formik.values.closeBackgroundColor}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={handleCloseBackgroundColorChange}
            onBlur={handleCloseBackgroundColorBlur}
            onKeyUp={handleCloseBackgroundColorKeyUp}
            handleColorChange={handleCloseBackgroundColorFromColorPickerChange}
            error={formik.touched.closeBackgroundColor && !!formik.errors.closeBackgroundColor}
            helperText={formik.touched.closeBackgroundColor && formik.errors.closeBackgroundColor}
          />
        </SidebarContainer>
      </Form>
    </FormikProvider>
  );
};
