import styled from 'styled-components';

export const CatalogImage = styled.div<{ $src: string }>`
  background-image: ${({ $src }) => `url(${$src})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 800px;
`;
