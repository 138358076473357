import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import cloneDeep from 'lodash.clonedeep';

import { IPromotionFilters, IPromotionItem } from '../types';

dayjs.extend(isSameOrAfter);

export interface IFilterPromotionsProps {
  filters: IPromotionFilters;
  items: IPromotionItem[];
}

export const filterPromotions = ({ filters, items }: IFilterPromotionsProps) => {
  let resultItems = cloneDeep(items);

  if (!!filters.name) {
    resultItems = resultItems.filter((promotionItem) => {
      return promotionItem.name?.includes(filters.name);
    });
  }

  if (!!filters.startDate) {
    resultItems = resultItems.filter((promotionItem) => {
      if (!!promotionItem.start_datetime) {
        return dayjs(promotionItem.start_datetime).isSameOrAfter(dayjs(filters.startDate));
      }
      return false;
    });
  }

  if (!!filters.finishDate) {
    resultItems = resultItems.filter((promotionItem) => {
      if (!!promotionItem.finish_datetime) {
        return dayjs(promotionItem.finish_datetime).isBefore(dayjs(filters.finishDate));
      }
      return false;
    });
  }

  if (!!filters.categoryId) {
    resultItems = resultItems.filter((promotionItem) => {
      if (!!promotionItem.categories && promotionItem.categories.length > 0) {
        const matchedCategory = promotionItem.categories.find((category) => {
          return category.id === filters.categoryId;
        });

        return !!matchedCategory;
      }
      return false;
    });
  }

  if (!!filters.statusKey) {
    resultItems = resultItems.filter((promotionItem) => {
      if (!!promotionItem.status) {
        return promotionItem.status.key === filters.statusKey;
      }
      return false;
    });
  }

  if (!!filters.typeId) {
    resultItems = resultItems.filter((promotionItem) => {
      if (!!promotionItem.type) {
        return promotionItem.type.id === filters.typeId;
      }
      return false;
    });
  }

  return resultItems;
};
