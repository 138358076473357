import cloneDeep from 'lodash.clonedeep';

import { INewStory, ISaveStorySequenceData, IStorySequenceItem } from '../types';

export interface TranformStorySequenceBeforeSaveProps {
  storySequence: IStorySequenceItem[];
  storySequenceVersion: number;
  newStories: { [key: string]: INewStory };
  storyScenario: string;
}

export const tranformStorySequenceBeforeSave = ({
  storySequence: storySequenceProp,
  storySequenceVersion,
  storyScenario,
}: TranformStorySequenceBeforeSaveProps): ISaveStorySequenceData => {
  const storySequence = storySequenceProp.map((story) => {
    if (story.isNewStory) {
      const { isNewStory, isSavedStory, ...restStory } = story;
      return restStory;
    } else {
      return story;
    }
  });

  const result: ISaveStorySequenceData = {
    data: {
      story_scenario: storyScenario,
      story_sequence: {
        params: storySequence,
      },
    },
  };

  if (storySequenceVersion) {
    result.data.version = storySequenceVersion;
  }

  return result;
};
