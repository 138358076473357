import { FC, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { DispatchersWorplaceSidebarMenu } from '@/features/dispatchers-workplace/sidebar';
import { topMenuItems } from '../sidebar/const';
import { MainContentWrapper } from './elements';
import { getServices } from '@/features/sidebars/redux/services.actions';
import { useAppDispatch } from '@/store';
import { setMuiThemeMode } from '@/app/redux/appGeneral.slice';
import { EMuiThemeMode } from '@/app/types';

export const DispatchersWorkplace: FC = () => {
  const navigate = useNavigate();
  const match = useMatch('/service/internet-shop/module/dispatchers-workplace');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    if (match) {
      navigate(`/service/internet-shop/module/dispatchers-workplace/${topMenuItems[0].link}`);
    }
  }, [navigate, match]);

  useEffect(() => {
    dispatch(setMuiThemeMode(EMuiThemeMode.light));
  }, [dispatch]);

  return (
    <>
      <DispatchersWorplaceSidebarMenu></DispatchersWorplaceSidebarMenu>
      <MainContentWrapper>
        <Scrollbars>
          <Outlet />
        </Scrollbars>
      </MainContentWrapper>
    </>
  );
};
