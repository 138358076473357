import { createSlice } from '@reduxjs/toolkit';

import { EMuiThemeMode } from '../types';

interface IAppGeneralState {
  muiThemeMode: EMuiThemeMode;
  dwpOnly: boolean;
}

const initialState: IAppGeneralState = {
  muiThemeMode: EMuiThemeMode.dark,
  dwpOnly: false,
};

const appGeneral = createSlice({
  name: 'appGeneral',
  initialState,
  reducers: {
    setMuiThemeMode: (state, action) => {
      state.muiThemeMode = action.payload;
    },
    setDwpOnly: (state, action) => {
      state.dwpOnly = action.payload;
    },
  },
  extraReducers() {},
});

export const { setMuiThemeMode, setDwpOnly } = appGeneral.actions;

export default appGeneral.reducer;
