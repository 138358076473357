import {
  ENotificationActionButtonType,
  ENotificationProcessType,
  ENotificationSendingTimeType,
  ENotificationTaskStatus,
  ENotificationTaskStatusName,
  INotificationTask,
} from '../types';

export const notificationTaskStatusNames: Record<
  ENotificationTaskStatus,
  ENotificationTaskStatusName
> = {
  [ENotificationTaskStatus.draft]: ENotificationTaskStatusName.draft,
  [ENotificationTaskStatus.waiting]: ENotificationTaskStatusName.waiting,
  [ENotificationTaskStatus.in_process]: ENotificationTaskStatusName.in_process,
  [ENotificationTaskStatus.finished]: ENotificationTaskStatusName.finished,
};

export const notificationSendingTimeRadioGroupOptionValues = [
  ENotificationProcessType.now,
  ENotificationProcessType.schedule,
];

export const notificationSendingTimeRadioGroupOptionTexts = ['Сразу', 'Запланировано'];

export const emptyNotification: INotificationTask = {
  title: '',
  description: '',
  details_title: '',
  details_description: '',
  contacts_data_url: '',
  send_push: true,
  button: {
    title: '',
    color: '',
    value: {
      type: null,
      value: '',
    },
  },
  notification_process: {
    type: ENotificationProcessType.now,
    schedule_datetime: '',
  },
};
