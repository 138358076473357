import { FC } from 'react';
import { TimeWrapper, Time, ClockIcon } from './elements';
import { ITime } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';

interface ITimeWithIconProps {
  time: ITime;
}

export const TimeWithIcon: FC<ITimeWithIconProps> = ({ time: timeProps }) => {
  const { time, timer_state } = timeProps;
  return (
    <TimeWrapper $timerState={timer_state}>
      <Time $timerState={timer_state}>{time}</Time>
      <ClockIcon $timerState={timer_state} />
    </TimeWrapper>
  );
};
