import { FC, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MainMenu } from '@/features/sidebars/MainMenu';
import { MiddleMenu } from '@/features/sidebars/MiddleMenu';
import { MainContentWrapper } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { EMuiThemeMode } from '@/app/types';
import { setMuiThemeMode } from '@/app/redux/appGeneral.slice';
import { ROUTES } from '@/shared/lib/const';

interface IAuthorizedPagesLayoutProps {
  Content: FC;
  isShowMiddleMenu?: boolean;
}

export const AuthorizedPagesLayoutBasic: FC<IAuthorizedPagesLayoutProps> = ({
  Content,
  isShowMiddleMenu = true,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dwpOnly = useSelector((state: TRootState) => state.appGeneral.dwpOnly);

  useEffect(() => {
    dispatch(setMuiThemeMode(EMuiThemeMode.dark));
  }, [dispatch]);

  useEffect(() => {
    if (dwpOnly && !window.location.pathname.includes('dispatchers-workplace')) {
      navigate(ROUTES.dispatchersWorkplace);
    }
  }, [navigate, dwpOnly]);

  return (
    <>
      <MainMenu></MainMenu>
      {isShowMiddleMenu && <MiddleMenu></MiddleMenu>}
      <MainContentWrapper>
        <Scrollbars>
          <Content />
        </Scrollbars>
      </MainContentWrapper>
    </>
  );
};
