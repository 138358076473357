import { FC, useEffect, useState, useRef, SyntheticEvent, KeyboardEvent } from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { CircularProgress, SxProps, Theme } from '@mui/material';

import {
  IOrderCollectors,
  ISalepointInfoShopGroupListItem,
} from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';
import {
  CircularProgressWrapper,
  CollectorListContainer,
  Collectors,
  MenuListItem,
  NoCollectors,
  OrderCollectorsAmountWrapper,
} from './elements';
import { OrderCollectors } from '../OrderCollectors';
import { useSelector } from 'react-redux';
import { TRootState } from '@/store';
import { ERequestStatus } from '@/shared/lib/types';

const sxPopperDefault: SxProps = {
  zIndex: '10',
  height: '224px',
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  backgroundColor: '#ffffff',
  borderRadius: '13px',
  border: '2px solid #F1F1F1',
  padding: '0',
  width: '255px',
};

const sxPaperDefault: SxProps = {
  height: '224px',
  overflow: 'hidden',
  boxShadow: 'none',
  padding: '20px',
};

const sxMenuListDefault: SxProps = {
  paddingTop: '0px',
  paddingBottom: '0px',
};

interface IShopAddressSelectProps {
  orderCollectors: IOrderCollectors;
  onClick?: (id: number) => void;
  salepointId: number;
  sxWrapper?: SxProps;
  sxPopper?: SxProps;
  sxPaper?: SxProps<Theme>;
  sxMenuList?: SxProps;
  shopGroupList?: ISalepointInfoShopGroupListItem[];
}

export const OrderCollectorsAmountWithShopCollectorListPopover: FC<IShopAddressSelectProps> = ({
  orderCollectors,
  onClick: onComponentClick,
  salepointId,
  sxPopper = sxPopperDefault,
  sxPaper = sxPaperDefault,
  sxMenuList = sxMenuListDefault,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  useState<ISalepointInfoShopGroupListItem[]>();

  const salepointCollectorList = useSelector(
    (state: TRootState) =>
      state.dispatchersWorkplace.salepointInfoListDashboard.salepointCollectorList
  );
  const fetchSalepointCollectorListStatus = useSelector(
    (state: TRootState) =>
      state.dispatchersWorkplace.salepointInfoListDashboard.fetchSalepointCollectorListStatus
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    setOpen(false);
  };

  const handleListKeyDown = (event: Event | KeyboardEvent<HTMLElement>) => {
    const key = (event as KeyboardEvent<HTMLElement>).key;
    if (key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (key === 'Escape') {
      setOpen(false);
    }
  };

  const handleOrderCollectorsClick = () => {
    onComponentClick(salepointId);
    handleToggle();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleListKeyDown);

    return () => window.removeEventListener('keydown', handleListKeyDown);
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <OrderCollectorsAmountWrapper>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOrderCollectorsClick}
        sx={{
          borderRadius: '15px',
          '& .MuiTouchRipple-childPulsate': {
            display: 'none !important',
          },
        }}
      >
        <OrderCollectors orderCollectors={orderCollectors} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-end"
        transition
        disablePortal
        sx={{ ...sxPopper }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper sx={sxPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {fetchSalepointCollectorListStatus === ERequestStatus.pending ? (
                    <CircularProgressWrapper>
                      <CircularProgress />
                    </CircularProgressWrapper>
                  ) : (
                    <>
                      {salepointCollectorList && salepointCollectorList.length > 0 && (
                        <Collectors>Сборщики</Collectors>
                      )}
                      <CollectorListContainer>
                        {salepointCollectorList && salepointCollectorList.length > 0 ? (
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            sx={sxMenuList}
                          >
                            {salepointCollectorList?.map((collector, index) => {
                              return <MenuListItem key={index}>{collector}</MenuListItem>;
                            })}
                          </MenuList>
                        ) : (
                          <NoCollectors>Нет сборщиков</NoCollectors>
                        )}
                      </CollectorListContainer>
                    </>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </OrderCollectorsAmountWrapper>
  );
};
