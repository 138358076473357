import { styled } from '@mui/system';

import { styledTheme } from '@/app/themes/styled-theme';

export const MainContent = styled('div')(({ theme }) => ({
  color: styledTheme.colors.text.auth,
  fontWeight: '600',
  margin: '0 auto',
  backgroundColor: '#121217',
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 5px 0px',
  padding: '15px 15px 5px 15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minHeight: '100svh',
  },
  [theme.breakpoints.up('sm')]: {
    width: '400px',
    borderRadius: '8px',
  },
}));
