import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { HeaderContainer } from './elements';

export const Header = () => {
  return (
    <HeaderContainer>
      <SecondaryButton>История</SecondaryButton>
    </HeaderContainer>
  );
};
