import { FC, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { MainButton } from '../MainButton';
import { SecondaryButton } from '../SecondaryButton';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface IBasicModalProps {
  open: boolean;
  heading: string;
  content: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const BasicConfirmationModal: FC<IBasicModalProps> = ({
  open: openProp,
  heading,
  content,
  onConfirm,
  onCancel,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (openProp) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openProp]);

  const handleConfirmBtnClick = () => {
    handleClose();
    onConfirm?.();
  };

  const handleCancelBtnClick = () => {
    handleClose();
    onCancel?.();
  };

  const handleBackdropClick = () => {
    handleClose();
    onCancel?.();
  };

  return (
    <Modal
      aria-labelledby="basic-modal-title"
      aria-describedby="basic-modal-description"
      open={open}
      onClose={handleBackdropClick}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="basic-modal-title" variant="h6" component="h2">
            {heading}
          </Typography>
          <Typography id="basic-modal-description" sx={{ mt: 2 }}>
            {content}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <MainButton sx={{ mr: 2 }} onClick={handleConfirmBtnClick}>
              Подтверждаю
            </MainButton>
            <SecondaryButton onClick={handleCancelBtnClick}>Отмена</SecondaryButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
