import { ChangeEvent } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export interface ICustomRadioGroup {
  label: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  radioGroupValues?: string[];
  radioGroupTexts?: string[];
  value: string;
  disabled?: boolean;
}

export default function CustomRadioGroup({
  label,
  onChange,
  radioGroupValues = [],
  radioGroupTexts = [],
  value,
  disabled,
}: ICustomRadioGroup) {
  return (
    <FormControl disabled={disabled}>
      <FormLabel id="radio-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="radio-group-label"
        name="radio-group"
        onChange={onChange}
        value={value}
      >
        {radioGroupValues.map((value, index) => {
          return (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={radioGroupTexts[index]}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
