import styled from 'styled-components';

export const OrderAcceptanceStateIndicator = styled.div<{ $state: 'enabled' | 'disabled' }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme, $state }) => {
    const enabledColor =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.orderAcceptanceStateIndicator
        .enabled;
    const disabledColor =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.orderAcceptanceStateIndicator
        .disabled;
    return $state === 'enabled' ? enabledColor : disabledColor;
  }};
`;
