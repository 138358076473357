import { IServiceRecord } from '../types';
import androidRobotGooglePlay from '@/assets/img/android-robot-google-play.svg';
import appleLogo from '@/assets/img/apple-logo.svg';
import huaweiAppGallery from '@/assets/img/huawei-app-gallery.svg';

export const servicesData: IServiceRecord[] = [
  {
    id: 1,
    logo_url: 'https://placehold.it/128x128',
    name: 'Мобильные приложения',
    external_service_id: 'Мобильные приложения',
    description: 'Описание: мобильные приложения',
    disabled: false,
    modules: [
      { id: 7, name: 'Сценарии', external_module_id: 'Сценарии' },
      // { id: 8, name: 'Авторизация и регистрация', external_module_id: 'Авторизация и регистрация' },
      { id: 9, name: 'Акции', external_module_id: 'Акции' },
      // { id: 10, name: 'Персональные акции', external_module_id: 'Персональные акции' },
      // { id: 11, name: 'Отправка пушей', external_module_id: 'Отправка пушей' },
      { id: 28, name: 'Баннеры', external_module_id: 'Баннеры' },
      { id: 29, name: 'Отправка пушей', external_module_id: 'Отправка пушей' },
    ],
    children: [
      {
        id: 2,
        logo_url: appleLogo,
        name: 'iOS',
        external_service_id: 'iOS',
        description: 'Описание: iOS',
        disabled: false,
        modules: [
          { id: 12, name: 'Модуль 11', external_module_id: 'Модуль 11' },
          { id: 13, name: 'Модуль 12', external_module_id: 'Модуль 12' },
          { id: 14, name: 'Модуль 13', external_module_id: 'Модуль 13' },
          { id: 15, name: 'Модуль 14', external_module_id: 'Модуль 14' },
        ],
      },
      {
        id: 3,
        logo_url: androidRobotGooglePlay,
        name: 'Android (Play Market)',
        external_service_id: 'Android (Play Market)',
        description: 'Описание: Android (Play Market)',
        disabled: false,
        modules: [
          { id: 16, name: 'Модуль 21', external_module_id: 'Модуль 21' },
          { id: 17, name: 'Модуль 22', external_module_id: 'Модуль 22' },
          { id: 18, name: 'Модуль 23', external_module_id: 'Модуль 23' },
          { id: 19, name: 'Модуль 24', external_module_id: 'Модуль 24' },
        ],
      },
      {
        id: 4,
        logo_url: huaweiAppGallery,
        name: 'Android (App Gallery)',
        external_service_id: 'Android (App Gallery)',
        description: 'Описание: Android (App Gallery)',
        disabled: false,
        modules: [
          { id: 20, name: 'Модуль 31', external_module_id: 'Модуль 31' },
          { id: 21, name: 'Модуль 32', external_module_id: 'Модуль 32' },
          { id: 22, name: 'Модуль 33', external_module_id: 'Модуль 33' },
          { id: 23, name: 'Модуль 34', external_module_id: 'Модуль 34' },
        ],
      },
    ],
  },
  {
    id: 5,
    logo_url: 'https://placehold.it/128x128',
    name: 'Интернет-магазин',
    external_service_id: 'internet-shop',
    description: 'Описание: Интернет-магазин',
    disabled: false,
    modules: [
      { id: 24, name: 'Рабочее место диспетчера', external_module_id: 'dispatchers-workplace' },
    ],
  },
  {
    id: 6,
    logo_url: 'https://placehold.it/128x128',
    name: 'Smart Pay',
    external_service_id: 'Smart Pay',
    description: 'Описание: Smart Pay',
    disabled: true,
  },
];
