import { styled } from '@mui/system';

import { SecondaryButton } from '@/shared/components/SecondaryButton';

export const StartButton = styled(SecondaryButton)`
  padding-left: 14px;
  padding-top: 2px;
  padding-bottom: 2px;

  & .MuiButton-startIcon {
    margin-right: 3px;
  }
`;
