import Axios, { AxiosResponse } from 'axios';

import { IApiErrorRespose, TJSONEntity } from './types';

const API_PATH = `${window.location.origin}/api/v1`;
// Заметка: раскоментировать для локальной работы
// const API_PATH = 'http://localhost:8010/proxy/v1';
// Заметка: раскоментировать для локальной работы из вирткальной машины в VMWare
// const API_PATH = 'http://192.168.0.12:8010/proxy/v1';

export const axiosInstance = Axios.create({
  baseURL: `${API_PATH}`,
  withCredentials: true,
  transformResponse: (data) => {
    try {
      const parsedResponse = JSON.parse(data) as AxiosResponse<TJSONEntity> | IApiErrorRespose;

      return parsedResponse;
    } catch (e) {
      return data;
    }
  },
});

// Заметка: раскоментировать для локальной работы
// axiosInstance.interceptors.request.use((config) => {
//   config.params = { client_slug: 'anotherapp' };
//   return config;
// });
