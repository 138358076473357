import Box from '@mui/material/Box';
import styled from 'styled-components';

export const NoCollectors = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  color: black;
  margin-bottom: 16px;
`;
