import { IPromotionCollectionItemPromotion, IPromotionItem } from '../types';

export const transformPromotionsToPromotionCollectionItemPromotions = (
  promotions: IPromotionItem[]
): IPromotionCollectionItemPromotion[] => {
  return promotions.map((promotion) => {
    return {
      id: promotion.id,
      name: promotion.name,
      status: promotion.status?.name,
      type: promotion.type?.name,
    };
  });
};
