import { ReactElement, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import { axiosInstance } from '@/shared/lib/axios';
import { MUTATION_ENDPOINTS, ROUTES } from '@/shared/lib/const';

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders;
}

type TAxiosErrorInterceptorProviderProps = {
  children: ReactElement;
};

export function AxiosRequestInterceptorProvider({ children }: TAxiosErrorInterceptorProviderProps) {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const reqInterceptor = (config: AdaptAxiosRequestConfig) => {
      const controller = new AbortController();

      const userId = Cookies.get('userId');
      const sessionId = Cookies.get('session_id');

      if ((!userId || !sessionId) && config.url !== MUTATION_ENDPOINTS.login) {
        controller.abort();
        navigate(ROUTES.login);
      }

      return {
        ...config,
        signal: controller.signal,
      };
    };

    const interceptor = axiosInstance.interceptors.request.use(reqInterceptor);

    return () => axiosInstance.interceptors.request.eject(interceptor);
  }, []);

  return children;
}
