import styled from 'styled-components';

export const TooltipImage = styled.div<{ $url?: string }>`
  display: flex;
  align-items: end;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  font-weight: 500;
  font-size: 12px;
  padding: 10px;
  margin-top: 6px;
  border-radius: 16px;
  background-image: ${({ $url = '' }) => `url(${$url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
`;
