import { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { IClientListRecord } from '@/shared/lib/types';

interface IClientListProps {
  clientListData: IClientListRecord[];
  onListItemClick: (client: IClientListRecord) => void;
}

export const ClientSelect: FC<IClientListProps> = ({ clientListData, onListItemClick }) => {
  return (
    <List>
      {clientListData?.map((client) => (
        <ListItem key={client.id} onClick={() => onListItemClick(client)}>
          <ListItemButton>
            <ListItemAvatar>
              <Avatar alt="Логотип клиента" src={client.logo_url} />
            </ListItemAvatar>
            <ListItemText primary={client.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
