import styled from 'styled-components';

export const CarouselIndicatorWrapper = styled.div<{
  $slidesNumber: number;
  $isShown: boolean;
  $progressBackgroundColor: string;
  $progressColor: string;
}>`
  position: relative;
  width: ${({ $slidesNumber }) =>
    `calc((100% - (2px * (${$slidesNumber - 1})))/ ${$slidesNumber})`};
  height: 100%;
  background-color: ${({ $isShown, $progressBackgroundColor, $progressColor }) => {
    return $isShown ? $progressColor || $progressBackgroundColor : $progressBackgroundColor;
  }};
  border-radius: 1px;
  margin-right: 3px;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }
`;
