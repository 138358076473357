import { createSlice } from '@reduxjs/toolkit';
import { IModuleRecord, IOptionRecord, IServiceRecord } from '../types';
import { ERequestStatus } from '@/shared/lib/types';
import { getServices } from './services.actions';
import { servicesData } from '../stub/servicesData';
import { IScenario, ISlide, IStory, IStorySequenceItem } from '@/features/stories/types';
import { IBannerScenarioItem } from '@/features/banners/types';

interface IServicesState {
  services: IServiceRecord[];
  modules: IModuleRecord[];
  middleMenuOptions: (
    | IServiceRecord
    | IModuleRecord
    | IScenario
    | IStorySequenceItem
    | ISlide
    | IBannerScenarioItem
  )[];
  servicesStatus: ERequestStatus;
  middleMenuActiveBtn: string | null;
}

const initialState: IServicesState = {
  services: servicesData,
  modules: servicesData[0].modules,
  middleMenuOptions: [],
  servicesStatus: ERequestStatus.idle,
  middleMenuActiveBtn: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setMiddleMenuOptions: (state, action) => {
      state.middleMenuOptions = action.payload;
    },
    setMiddleMenuActiveBtn: (state, action) => {
      state.middleMenuActiveBtn = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getServices.pending, (state) => {
        state.servicesStatus = ERequestStatus.pending;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        // state.services = action.payload.data.services;
        state.servicesStatus = ERequestStatus.success;
      })
      .addCase(getServices.rejected, (state) => {
        state.servicesStatus = ERequestStatus.error;
      });
  },
});

export const { setModules, setServices, setMiddleMenuOptions, setMiddleMenuActiveBtn } =
  servicesSlice.actions;

export default servicesSlice.reducer;
