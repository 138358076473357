export interface IListItem {
  id: number | string;
}

export interface IIsListItemsOrderChangedProps {
  originalItemList: IListItem[];
  nextItemList: IListItem[];
}

export const isListItemsOrderChanged = ({
  originalItemList,
  nextItemList,
}: IIsListItemsOrderChangedProps) => {
  if (!originalItemList || !nextItemList) {
    return false;
  }

  if (originalItemList.length === 0 || nextItemList.length === 0) {
    return false;
  }

  let isOrderChanged = false;
  originalItemList.find((originalListItem, index) => {
    if (originalListItem.id !== nextItemList[index].id) {
      isOrderChanged = true;
      return true;
    }
    return false;
  });

  return isOrderChanged;
};
