import { TTimerState } from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';
import styled from 'styled-components';

import ClockIconSvg from '@/assets/icons/dispatchers-workplace/clock.svg?react';

export const ClockIcon = styled(ClockIconSvg)<{ $timerState: TTimerState }>`
  width: 16px;
  height: 16px;
  color: ${({ theme, $timerState }) => {
    const color =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.time.content[$timerState];

    return color;
  }};
  margin-left: 4px;
`;
