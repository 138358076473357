import { ReactElement, useLayoutEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '@/shared/lib/axios';
import { ERequestStatus, IApiErrorRespose } from '@/shared/lib/types';
import { ROUTES } from '@/shared/lib/const';
import { setClients, setLoginStatus } from '@/features/auth/redux/authentication.slice';
import { useAppDispatch } from '@/store';

type TAxiosErrorInterceptorProviderProps = {
  children: ReactElement;
};

interface IShowErrorNotificationProps {
  message: string;
  enqueueSnackbar: any;
}

const showErrorNotification = ({ message, enqueueSnackbar }: IShowErrorNotificationProps) => {
  enqueueSnackbar(
    <div>
      <div>Ошибка ответа от сервера:</div>
      <div>{message}</div>
    </div>,
    {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    }
  );
};

export function AxiosErrorInterceptorProvider({ children }: TAxiosErrorInterceptorProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const resInterceptor = (response: AxiosResponse) => response;

    const errInterceptor = (error: AxiosError<IApiErrorRespose>) => {
      const status = error.response?.status;
      const errorData = error?.response?.data?.error;
      let message = errorData?.user_message || errorData?.description;
      message = message ? message : JSON.stringify(error?.response?.data);

      if (status === 403) {
        navigate(ROUTES.login);
        return Promise.reject(error);
      }

      if (status === 401) {
        navigate(ROUTES.login);
      }

      if (status === 404) {
        const clients = error.response?.data?.data?.clients;
        if (!!clients && status === 404) {
          if (clients.length > 1) {
            dispatch(setClients(clients));
            navigate(ROUTES.selectClient);
          } else {
            window.location.assign(clients[0].client_url);
          }
        }
      }

      if (
        error.code !== 'ERR_CANCELED' &&
        !(status === 400 && errorData?.code === 'StorySequenceDoesNotExists')
      ) {
        showErrorNotification({ enqueueSnackbar, message: message as string });
      }

      return Promise.reject(error);
    };

    const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, []);

  return children;
}
