import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LogoLink = styled(Link)`
  display: block;
  width: 167px;
  padding: 10px;
  text-decoration: none;
  user-select: none;
`;
