import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const ColorWrapper = styled(Box)<{ $marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  align-items: top;
  flex-grow: 1;
  flex-basis: 0;
`;
