import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TRootState } from '@/store';

export const usePromotionCategoriesSelectData = () => {
  const promotionCategories = useSelector(
    (state: TRootState) => state.promotions.promotionCategories
  );

  const promotionCategoriesOptionTexts = useMemo(() => {
    return (
      promotionCategories?.map((categoryItem) => {
        return categoryItem.name;
      }) || []
    );
  }, [promotionCategories]);
  const promotionCategoriesOptionValues = useMemo(() => {
    return (
      promotionCategories?.map((categoryItem) => {
        return categoryItem.id;
      }) || []
    );
  }, [promotionCategories]);

  return { promotionCategoriesOptionTexts, promotionCategoriesOptionValues };
};
