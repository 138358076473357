import { FC } from 'react';
import { MenuButtonProps } from './MenuButtonProps';
import { BtnIcon, BtnIconWrapper, MenuButtonWrapper, Text } from './elements';

export const MenuButton: FC<MenuButtonProps> = ({ icon, text, isDisabled, isActive, ...rest }) => {
  return (
    <MenuButtonWrapper {...rest} $isActive={isActive}>
      {icon && (
        <BtnIconWrapper>
          <BtnIcon $icon={icon} />
        </BtnIconWrapper>
      )}
      <Text $isDisabled={isDisabled}>{text}</Text>
    </MenuButtonWrapper>
  );
};
