import styled from 'styled-components';

export const BtnIcon = styled.div<{ $icon: string }>`
  height: 22px;
  width: 22px;
  background: url(${({ $icon }) => $icon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;
