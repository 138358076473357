import Box from '@mui/material/Box';
import styled from 'styled-components';

export const EmptyListWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 17px;
  padding: 50px 20px 20px 20px;
`;
