import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TRootState, useAppDispatch } from '@/store';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebars/redux/services.slice';
import { fetchBannerScenarios } from '../redux/scenarios.actions';
import { IModuleRecord } from '@/features/sidebars/types';
import { ScenarioCard } from './components/ScenarioCard';
import { ScenariosContainer } from './elements';

const Scenarios = () => {
  const dispatch = useAppDispatch();
  const modules = useSelector((state: TRootState) => state.services.modules);
  const bannerScenarios = useSelector((state: TRootState) => state.bannerScenarios.bannerScenarios);
  const middleMenuOptions = useSelector((state: TRootState) => state.services.middleMenuOptions);

  useEffect(() => {
    if (bannerScenarios?.length === 0) {
      dispatch(fetchBannerScenarios());
    }
  }, [dispatch, bannerScenarios]);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(modules));
  }, [dispatch, modules]);

  useEffect(() => {
    if (!!middleMenuOptions && middleMenuOptions.length > 0) {
      dispatch(setMiddleMenuActiveBtn((middleMenuOptions[2] as IModuleRecord)?.external_module_id));
    }
  }, [dispatch, middleMenuOptions]);

  return (
    <ScenariosContainer>
      {bannerScenarios?.map((scenario) => {
        return <ScenarioCard key={scenario.id} scenario={scenario} />;
      })}
    </ScenariosContainer>
  );
};

export default Scenarios;
