import { RGBColor } from 'react-color';

const hexFFToDecimal = 255;

const addLeadingZero = (str: string) => {
  if (str.length === 1) {
    return `0${str}`;
  }
  return str;
};

export const getHexFromRgba = (rgbaColor: RGBColor) => {
  const opacity = Math.round(rgbaColor.a * hexFFToDecimal);
  const hexR = addLeadingZero(rgbaColor.r.toString(16));
  const hexG = addLeadingZero(rgbaColor.g.toString(16));
  const hexB = addLeadingZero(rgbaColor.b.toString(16));
  const hexOpacity = addLeadingZero(opacity.toString(16));
  return `#${hexR}${hexG}${hexB}${hexOpacity}`;
};
