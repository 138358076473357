/// <reference types="vite-plugin-svgr/client" />

import { FC } from 'react';
import { MenuButtonProps } from './MenuButtonProps';
import { MenuButtonWrapper, Text } from './elements';

export const MenuButton: FC<MenuButtonProps> = ({ Icon, text, ...rest }) => {
  return (
    <MenuButtonWrapper {...rest}>
      <Icon />
      <Text>{text}</Text>
    </MenuButtonWrapper>
  );
};
