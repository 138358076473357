import { FC, useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import cloneDeep from 'lodash.clonedeep';
import './index.css';

export type TCard = { id: number; name: string } & any;
export interface ISortableProps {
  idField?: string | number;
  cardComponent: FC<any>;
  updateCards: (cards: TCard[]) => void;
  cards: TCard[];
  cardParams?: Record<string, any>;
  onClick?: (card: TCard) => void;
  onDragChange?: (isDragging: boolean) => void;
}

export const Sortable: FC<ISortableProps> = ({
  idField = 'id',
  cards,
  cardParams,
  updateCards,
  cardComponent: CardComponent,
  onClick,
  onDragChange,
}) => {
  const [list, setListItems] = useState<TCard[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setListItems(cloneDeep(cards));
  }, [cards]);

  const setList = (list: TCard[]) => {
    setListItems(list);
    const resultList = list.map((listItem) => {
      return cards.find((card) => {
        return card[idField] === listItem[idField];
      });
    });
    updateCards(resultList);
  };

  const handleEnd = () => {
    onDragChange?.(false);
    setIsDragging(false);
  };

  const handleStart = () => {
    onDragChange?.(true);
    setIsDragging(true);
  };

  return (
    <ReactSortable
      list={list}
      setList={setList}
      onStart={handleStart}
      onEnd={handleEnd}
      className={isDragging ? 'dragging' : ''}
    >
      {list?.map((card: any, index) => (
        <CardComponent
          key={card?.id || card.name}
          index={index}
          {...card}
          {...cardParams}
          onClick={() => onClick?.(card)}
        />
      ))}
    </ReactSortable>
  );
};
