import { useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Footer, Header, StoriesContainer } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import { Sortable } from '@/shared/components/Sortable';
import { setStorySequence, setStorySequenceFromModified } from '../redux/stories.slice';
import { Card } from './components/Card';
import { IStorySequenceItem } from '../types';
import {
  setMiddleMenuOptions,
  setMiddleMenuActiveBtn,
} from '@/features/sidebars/redux/services.slice';
import { useParams } from 'react-router-dom';
import { SecondaryButton } from '@/shared/components/SecondaryButton';
import { AddStory } from './components/AddStory';
import {
  fetchScenarios,
  fetchStoryPreview,
  fetchStorySequence,
  saveStorySequence,
} from '../redux/stories.actions';
import { ERequestStatus } from '@/shared/lib/types';
import { MainButton } from '@/shared/components/MainButton';
import { isStorySequenceTouched } from '../utils';

const Scenario = () => {
  const { scenario: scenarioName } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const storySequence = useSelector((state: TRootState) => state.stories.storySequence);
  const storySequenceInitial = useSelector(
    (state: TRootState) => state.stories.storySequenceInitial
  );
  const storySequencesModified = useSelector(
    (state: TRootState) => state.stories.storySequencesModified
  );
  const saveStorySequenceStatus = useSelector(
    (state: TRootState) => state.stories.saveStorySequenceStatus
  );
  const fetchStorySequenceStatus = useSelector(
    (state: TRootState) => state.stories.fetchStorySequenceStatus
  );
  const scenarios = useSelector((state: TRootState) => state.stories.scenarios);
  const dispatch = useAppDispatch();
  const isSaveBtnDisabled = useMemo(() => {
    const newStory = storySequence.find((story) => {
      return story.isNewStory;
    });
    if (newStory) {
      return (
        !newStory.isSavedStory ||
        !isStorySequenceTouched({ storySequence, storySequenceInitial }) ||
        saveStorySequenceStatus === ERequestStatus.pending ||
        fetchStorySequenceStatus === ERequestStatus.pending
      );
    } else {
      return (
        !isStorySequenceTouched({ storySequence, storySequenceInitial }) ||
        saveStorySequenceStatus === ERequestStatus.pending ||
        fetchStorySequenceStatus === ERequestStatus.pending
      );
    }
  }, [storySequence, storySequenceInitial, saveStorySequenceStatus, fetchStorySequenceStatus]);

  const updateStories = (nextStories: IStorySequenceItem[]) => {
    if (nextStories.length !== 0) {
      dispatch(setStorySequence({ storySequence: nextStories, scenario: scenarioName }));
    }
  };

  useEffect(() => {
    dispatch(fetchScenarios());
  }, []);

  useEffect(() => {
    if (!!storySequence && storySequence.length > 0) {
      storySequence.forEach((story) => {
        if (story.external_story_id) {
          dispatch(fetchStoryPreview(story.external_story_id));
        }
      });
    }
  }, [storySequence]);

  useEffect(() => {
    let fetchStorySequencePromise: any;

    if (scenarioName) {
      if (storySequencesModified[scenarioName]) {
        dispatch(fetchStorySequence(scenarioName))
          .unwrap()
          .then(() => {
            dispatch(setStorySequenceFromModified(scenarioName));
          });
      } else {
        fetchStorySequencePromise = dispatch(fetchStorySequence(scenarioName));
      }
    }

    return () => {
      fetchStorySequencePromise?.abort();
    };
  }, [scenarioName, storySequencesModified, dispatch]);

  useEffect(() => {
    if (scenarios) {
      dispatch(setMiddleMenuOptions(scenarios));
    }
  }, [scenarios]);

  useEffect(() => {
    if (scenarioName) {
      dispatch(setMiddleMenuActiveBtn(scenarioName));
    }
  }, [scenarios, scenarioName]);

  const handleSaveBtnClick = useCallback(() => {
    if (!isSaveBtnDisabled) {
      dispatch(saveStorySequence(scenarioName))
        .unwrap()
        .then(() => {
          enqueueSnackbar(<div>Сценарий был успешно сохранен!</div>, {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          });
          dispatch(fetchStorySequence(scenarioName));
        });
    }
  }, [scenarioName, isSaveBtnDisabled]);

  return (
    <StoriesContainer>
      <Header>
        <MainButton
          onClick={handleSaveBtnClick}
          disabled={isSaveBtnDisabled}
          sx={{ marginRight: '20px' }}
        >
          Сохранить
        </MainButton>
        <SecondaryButton>История</SecondaryButton>
      </Header>
      <Sortable updateCards={updateStories} cards={storySequence} cardComponent={Card}></Sortable>
      <Footer>
        <AddStory />
      </Footer>
    </StoriesContainer>
  );
};

export default Scenario;
