import { useState, useRef } from 'react';

import { MS_IN_ONE_SECOND } from '@/shared/lib/const';

export const useIntervalForSlideIndicators = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [keyForSlideProgress, setKeyForSlideProgress] = useState(Date.now());
  const durations = useRef([]);
  const timerId = useRef<null | ReturnType<typeof setTimeout>>();

  const timerCallback = () => {
    setActiveSlideIndex((prevActiveSlideIndex) => {
      const slideAmount = durations.current.length;
      setKeyForSlideProgress(Date.now());
      let nextActiveSlideIndex;

      if (slideAmount === 1) {
        nextActiveSlideIndex = 0;
      } else {
        nextActiveSlideIndex = (prevActiveSlideIndex + 1) % slideAmount;
      }

      timerId.current = setTimeout(
        timerCallback,
        durations.current[nextActiveSlideIndex] * MS_IN_ONE_SECOND
      );

      return nextActiveSlideIndex;
    });
  };

  const startInterval = ({ durations: durationsProps }: { durations: number[] }) => {
    clearIntervalForSlides();
    durations.current = durationsProps;
    timerId.current = setTimeout(
      timerCallback,
      durations.current[activeSlideIndex] * MS_IN_ONE_SECOND
    );
  };

  const clearIntervalForSlides = () => {
    window.clearTimeout(timerId.current);
  };

  return [keyForSlideProgress, activeSlideIndex, startInterval, clearIntervalForSlides] as const;
};
