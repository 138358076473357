import isEqual from 'lodash.isequal';

import { IStorySequenceItem } from '../types';

export interface IIsStorySequenceTouchedProps {
  storySequence: IStorySequenceItem[];
  storySequenceInitial: IStorySequenceItem[];
}

export const isStorySequenceTouched = ({
  storySequence,
  storySequenceInitial,
}: IIsStorySequenceTouchedProps) => {
  return !isEqual(storySequence, storySequenceInitial);
};
