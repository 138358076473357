export enum ENotificationTaskStatus {
  draft = 'draft',
  waiting = 'waiting',
  in_process = 'in_process',
  finished = 'finished',
}

export enum ENotificationTaskStatusName {
  draft = 'Черновик',
  waiting = 'В ожидании',
  in_process = 'Отправляется',
  finished = 'Завершено',
}

export interface INotificationTaskListItem {
  id: number;
  created_at: string;
  version: number;
  sending_date: string;
  with_push: boolean;
  status: ENotificationTaskStatus;
  name?: string;
}

export interface IFetchNotificationTaskListResponse {
  data: {
    tasks: INotificationTaskListItem[];
  };
}

export enum ENotificationActionButtonType {
  deepLink = 'deep_link',
  browser = 'browser',
  webview = 'webview',
  share = 'share',
  close = 'close',
}

export interface INotificationTaskButtonValue {
  type: ENotificationActionButtonType;
  value: string;
}

export interface INotificationTaskButton {
  title: string;
  color: string;
  value: INotificationTaskButtonValue;
}

export enum ENotificationProcessType {
  now = 'now',
  schedule = 'schedule',
}

export interface INotificationProcess {
  type: ENotificationProcessType;
  schedule_datetime: string;
}

export interface INotificationTask {
  title: string;
  description: string;
  details_title: string;
  details_description: string;
  contacts_data_url: string;
  send_push: boolean;
  button: INotificationTaskButton;
  notification_process: INotificationProcess;
}

export interface IFetchNotificationTaskResponse {
  data: {
    task: INotificationTask;
  };
}

export enum ENotificationActionType {
  draft = 'draft',
  commit = 'commit',
}

export enum ESaveNotificationTaskResponseStatus {
  ok = 'ok',
  error = 'error',
}

export interface ISaveNotificationTaskResponse {
  data: {
    status: ESaveNotificationTaskResponseStatus;
    task_id: number;
  };
}
