import { IStoryPreview, IStoryPreviewParamsTitle } from '../types';

export const getStoryTooltipData = (preview: IStoryPreview) => {
  const titleParamIndex = preview?.params?.findIndex((param) => param.name === 'title');
  const backgroundParamIndex = preview?.params?.findIndex((param) => param.name === 'background');

  const backgroundValue =
    backgroundParamIndex === -1 ? '' : preview?.params?.[backgroundParamIndex].value;
  const titleValue = titleParamIndex === -1 ? '' : preview?.params?.[titleParamIndex]?.value;
  const titleTextColor =
    titleParamIndex === -1
      ? ''
      : (preview?.params?.[titleParamIndex] as IStoryPreviewParamsTitle)?.text_color;

  return { backgroundValue, titleValue, titleTextColor };
};
