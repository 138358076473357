import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { MUTATION_ENDPOINTS, QUERY_ENDPOINTS } from '@/shared/lib/const';
import {
  IBanner,
  IBannerListItem,
  IBannerScenarioItem,
  IFetchBannerListResponse,
  IFetchBannerResponse,
  IFetchBannerScenariosResponse,
  ISaveBannerListResponse,
  ISaveBannerResponse,
} from '../types';
import { axiosInstance } from '@/shared/lib/axios';
import { tranformBannerBeforeSave, tranformBannerListBeforeSave } from '../utils';
import { TRootState } from '@/store';

export const fetchBannerScenarios = createAsyncThunk<IBannerScenarioItem[], void, unknown>(
  'bannerScenarios/fetchBannerScenarios',
  async () => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchBannerScenariosResponse>(
      QUERY_ENDPOINTS.banners.bannerScenarios.replace('[userId]', userId)
    );
    return response?.data?.data?.scenarios;
  }
);

export const fetchBannerList = createAsyncThunk<IBannerListItem[], string, unknown>(
  'bannerScenarios/fetchBannerList',
  async (scenarioId) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchBannerListResponse>(
      QUERY_ENDPOINTS.banners.bannerList
        .replace('[userId]', userId)
        .replace('[scenarioId]', scenarioId)
    );
    return response?.data?.data?.banners;
  }
);

export const createBanner = createAsyncThunk<ISaveBannerResponse['data'], string, unknown>(
  'bannerScenarios/createBanner',
  async (scenarioId, { getState }) => {
    const userId = Cookies.get('userId') || '-1';
    const externalId = uuidv4();
    const state = getState() as TRootState;
    const bannerData = state.bannerScenarios.newBanner;
    const data = tranformBannerBeforeSave({ ...bannerData, external_id: externalId });

    const response = await axiosInstance.post<ISaveBannerResponse>(
      MUTATION_ENDPOINTS.banners.createBanner
        .replace('[userId]', userId)
        .replace('[scenarioId]', scenarioId),
      data
    );
    return response?.data?.data;
  }
);

export interface ISaveBannerParams {
  bannerId: string;
  scenarioId: string;
}

export const saveBanner = createAsyncThunk<ISaveBannerResponse['data'], ISaveBannerParams, unknown>(
  'bannerScenarios/saveBanner',
  async ({ bannerId, scenarioId }, { getState }) => {
    const userId = Cookies.get('userId') || '-1';
    const state = getState() as TRootState;
    const bannerData = state.bannerScenarios.banner;
    const data = tranformBannerBeforeSave(bannerData);
    const response = await axiosInstance.post<ISaveBannerResponse>(
      MUTATION_ENDPOINTS.banners.saveBanner
        .replace('[userId]', userId)
        .replace('[bannerId]', bannerId)
        .replace('[scenarioId]', scenarioId),
      data
    );
    return response?.data?.data;
  }
);

export interface ISaveBannerListParams {
  scenarioId: string;
  bannerListData: IBannerListItem[];
}

export const saveBannerList = createAsyncThunk<
  ISaveBannerListResponse,
  ISaveBannerListParams,
  unknown
>('bannerScenarios/saveBannerList', async ({ scenarioId, bannerListData }) => {
  const userId = Cookies.get('userId') || '-1';
  const data = tranformBannerListBeforeSave(bannerListData);
  const response = await axiosInstance.post<ISaveBannerListResponse>(
    MUTATION_ENDPOINTS.banners.saveBannerList
      .replace('[userId]', userId)
      .replace('[scenarioId]', scenarioId),
    data
  );
  return response?.data;
});

export interface IFetchBannerParams {
  bannerId: string;
  scenarioId: string;
}

export const fetchBanner = createAsyncThunk<IBanner, IFetchBannerParams, unknown>(
  'bannerScenarios/fetchBanner',
  async ({ bannerId, scenarioId }, { signal }) => {
    const userId = Cookies.get('userId') || '-1';
    const response = await axiosInstance.get<IFetchBannerResponse>(
      QUERY_ENDPOINTS.banners.banner
        .replace('[userId]', userId)
        .replace('[bannerId]', bannerId)
        .replace('[scenarioId]', scenarioId),
      { signal }
    );
    return response?.data?.data?.banner;
  }
);
