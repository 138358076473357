import { EClientSettingsFieldKeys, IClientSettings, INewStaticCodesItem } from '../types';

export const transformClientSettingsBeforeSetToRedux = (clientSettings: IClientSettings) => {
  const staticCodesTransformed: INewStaticCodesItem[] = [];
  if (clientSettings?.fields) {
    clientSettings.fields = clientSettings?.fields.map((field) => {
      if (field.key === EClientSettingsFieldKeys.staticCodes) {
        const staticCodes = field.value;
        const staticCodeKeys = Object.keys(staticCodes);
        staticCodeKeys.forEach((staticCodeKey) => {
          staticCodesTransformed.push({
            staticCodeName: staticCodeKey,
            staticCodeValue: staticCodes[staticCodeKey],
          });
        });
        delete field.value;
      }
      return field;
    });
  }
  return { clientSettingsModified: clientSettings, staticCodesTransformed };
};
