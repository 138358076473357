import cloneDeep from 'lodash.clonedeep';

import { IPromotionItem } from '../types';
import { convertDateStringToAPIFormat } from './convertDateStringToAPIFormat';
import { colorRegExp } from '@/shared/validations';

export const transformPromotionVisualizationDataBeforeSave = (promotion: IPromotionItem) => {
  const resultPromotion = cloneDeep(promotion);
  if (resultPromotion.start_datetime) {
    resultPromotion.start_datetime = convertDateStringToAPIFormat(resultPromotion.start_datetime);
  }
  if (resultPromotion.finish_datetime) {
    resultPromotion.finish_datetime = convertDateStringToAPIFormat(resultPromotion.finish_datetime);
  }
  if (resultPromotion.public_datetime) {
    resultPromotion.public_datetime = convertDateStringToAPIFormat(resultPromotion.public_datetime);
  }

  for (const key in resultPromotion) {
    if (resultPromotion[key as keyof typeof resultPromotion] === null) {
      delete resultPromotion[key as keyof typeof resultPromotion];
    }
  }

  if (resultPromotion?.button?.params) {
    if (!colorRegExp.test(resultPromotion.button.params.background_color)) {
      resultPromotion.button.params.background_color = null;
    }

    if (!colorRegExp.test(resultPromotion.button.params.text_color)) {
      resultPromotion.button.params.text_color = null;
    }
  }

  return {
    data: {
      promotion: resultPromotion,
    },
  };
};
