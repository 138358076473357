import { IStory } from '../types';

export const trimStoryColorFields = (story: IStory) => {
  story.preview.params.find((param) => {
    if (param.type === 'text' && param.text_color) {
      param.text_color = param.text_color.trim();
      return true;
    }
    return false;
  });

  story.slides = story.slides.map((slide) => {
    if (slide?.slides?.params) {
      slide.slides.params = slide.slides.params.map((param) => {
        if (param.type === 'header' && param.text_color) {
          param.text_color = param.text_color.trim();
        }
        if (param.type === 'button' && param.text_color) {
          param.text_color = param.text_color.trim();
        }
        if (param.type === 'button' && param.background_color) {
          param.background_color = param.background_color.trim();
        }
        if (param.type === 'bar' && param.progress_color) {
          param.progress_color = param.progress_color.trim();
        }
        if (param.type === 'bar' && param.background_color) {
          param.background_color = param.background_color.trim();
        }
        if (param.type === 'cross_button' && param.background_color) {
          param.background_color = param.background_color.trim();
        }
        return param;
      });
    }
    return slide;
  });

  return story;
};
