import { FC, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Description,
  Footer,
  SlideViewContainer,
  SlideViewWrapper,
  Title,
} from './elements';
import { TRootState } from '@/store';
import {
  ISlide,
  ISlideBackground,
  ISlideDescription,
  ISlideFooter,
  ISlideTitle,
} from '@/features/stories/types';

export interface ISlideViewProps {}

const SlideView: FC<ISlideViewProps> = () => {
  const slides = useSelector((state: TRootState) => state.stories.story.slides);
  const middleMenuActiveBtn = useSelector(
    (state: TRootState) => state.services.middleMenuActiveBtn
  );
  const [titleValue, setTitleValue] = useState('');
  const [titleTextColor, setTitleTextColor] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [descriptionTextColor, setDescriptionTextColor] = useState('');
  const [buttonValue, setButtonValue] = useState('');
  const [buttonTextColor, setButtonTextColor] = useState('');
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('');
  const [backgroundImageValue, setBackgroundImageValue] = useState('');

  const currSlide = useMemo<ISlide>(() => {
    if (slides) {
      if (middleMenuActiveBtn === 'new_slide') {
        return slides.find((slide) => slide.isNewSlide);
      } else {
        return slides.find((slide) => slide.external_slide_id === middleMenuActiveBtn);
      }
    } else {
      return {} as ISlide;
    }
  }, [slides, middleMenuActiveBtn]);

  useEffect(() => {
    if (currSlide) {
      let title, description, footer, background;
      currSlide?.slides?.params?.forEach((param) => {
        if (param.name === 'title') {
          title = param;
        } else if (param.name === 'description') {
          description = param;
        } else if (param.name === 'footer') {
          footer = param;
        } else if (param.name === 'background') {
          background = param;
        }
      });

      setTitleValue((title as ISlideTitle)?.value || '');
      setTitleTextColor((title as ISlideTitle)?.text_color || '');

      setDescriptionValue((description as ISlideDescription)?.value || '');
      setDescriptionTextColor((description as ISlideDescription)?.text_color || '');

      setButtonValue((footer as ISlideFooter)?.value || '');
      setButtonTextColor((footer as ISlideFooter)?.text_color || '');
      setButtonBackgroundColor((footer as ISlideFooter)?.background_color || '');

      setBackgroundImageValue((background as ISlideBackground)?.value || '');
    }
  }, [currSlide]);

  const storyViewDimensions = useSelector((state: TRootState) => state.stories.storyViewDimensions);

  return (
    <SlideViewWrapper>
      <SlideViewContainer $img={backgroundImageValue} $dimensions={storyViewDimensions}>
        <Footer>
          {titleValue && <Title $color={titleTextColor}>{titleValue}</Title>}
          {descriptionValue && (
            <Description $color={descriptionTextColor}>{descriptionValue}</Description>
          )}
          <Button $butttonColor={buttonTextColor} $butttonBgColor={buttonBackgroundColor}>
            {buttonValue}
          </Button>
        </Footer>
      </SlideViewContainer>
    </SlideViewWrapper>
  );
};

export default SlideView;
