import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Controls, NotificationContainer, NotificationHedaing } from './elements';
import { TRootState, useAppDispatch } from '@/store';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebars/redux/services.slice';
import { IModuleRecord } from '@/features/sidebars/types';
import { NotificationsTable } from './components/NotificationsTable';
import { MainButton } from '@/shared/components/MainButton';
import { fetchNotificationTaskList } from '../redux/notificationTasks.actions';
import { ROUTES } from '@/shared/lib/const';
import { deleteNewNotification } from '../redux/notificationTasks.slice';

const NotificationTasks = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { service, module } = useParams();
  const modules = useSelector((state: TRootState) => state.services.modules);
  const notificationTasks = useSelector((state: TRootState) => state.notificationTasks.tasks);

  useEffect(() => {
    dispatch(fetchNotificationTaskList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(modules));
  }, [dispatch, modules]);

  useEffect(() => {
    if (!!modules && modules.length > 0) {
      dispatch(setMiddleMenuActiveBtn((modules[3] as IModuleRecord).external_module_id));
    }
  }, [dispatch, modules]);

  useEffect(() => {
    dispatch(deleteNewNotification());
  }, []);

  const handleCreateNotificationBtnClick = () => {
    navigate(
      ROUTES.notificationTask
        .replace(':service', service)
        .replace(':module', module)
        .replace(':notification', 'new-notification')
    );
  };

  return (
    <NotificationContainer>
      <NotificationHedaing>Отправка пушей в центр уведомлений</NotificationHedaing>
      <Controls>
        <MainButton onClick={handleCreateNotificationBtnClick}>Создать рассылку</MainButton>
      </Controls>
      <NotificationsTable notificationListItems={notificationTasks} />
    </NotificationContainer>
  );
};

export default NotificationTasks;
