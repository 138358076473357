import {
  AskYourManagerText,
  ServiceNotAavailableText,
  MainContainer,
  ServiceNotAvailableEmoji,
} from './elements';

export const ServiceNotAvailable = () => (
  <MainContainer>
    <ServiceNotAavailableText>Этот сервис пока вам недоступен</ServiceNotAavailableText>
    <AskYourManagerText>Обратитесь к вашему менеджеру для подключения</AskYourManagerText>
    <ServiceNotAvailableEmoji></ServiceNotAvailableEmoji>
  </MainContainer>
);
